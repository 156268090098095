import React, {useEffect, useState} from "react";
import '../../assets/css/AnalysisPieChartCard.css';
import Loader from "../popup/loader";
import {useTranslation} from "react-i18next";
import AnalysisLineChartCard from "./AnalysisLineChartCard";
import {AnalysisApi} from "../fragments/AnalysisApi";


const AnalysisNetOrderLineChartCard = ({
                                          locale,
                                          brandUuid,
                                          dateRange
                           }) => {

    const {t} = useTranslation("analysis");

    const [showLoader, setShowLoader] = useState(true);
    const [data, setData] = useState({});

    function shouldLoadWeeklyGraph() {
        return dateRange.differenceInDays() > 59;
    }

    function getGroupInterval() {
        return shouldLoadWeeklyGraph() ? "week" : "day";
    }

    useEffect(() => {
        setShowLoader(true);

        AnalysisApi.orderAmount(brandUuid, dateRange, getGroupInterval())
            .then(setData)
            .finally(() => {
                setShowLoader(false);
            });
    }, [brandUuid, dateRange]);

    const legend = [
        "Tarih",
        "TOPLAM SIPARIS",
        "IADE",
        "NET SIPARIS",
    ]

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <AnalysisLineChartCard
                    locale={locale}
                    cardTitle={"NET ALISVERIS TUTARI"}
                    data={data}
                    legend={legend}
                />
            )}
        </>
    );
};

export default AnalysisNetOrderLineChartCard;

