import { useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import qs from "qs"
import {DateRange} from "./dateRange";

export const useDateRangeQueryState = (query_param, default_value) => {
    const location = useLocation()
    const navigate = useNavigate()

    function updateQueryString(value) {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        })

        const queryString = qs.stringify(
            { ...existingQueries, [query_param]: value.getFormatted() },
            { skipNulls: true }
        )

        navigate(`${location.pathname}?${queryString}`)
    }

    function getCurrentState() {
        const fromParam = qs.parse(location.search, { ignoreQueryPrefix: true })[query_param];
        if(fromParam) {
            return DateRange.fromFormatted(fromParam);
        } else {
            updateQueryString(default_value);
            return default_value;
        }
    }

    const setQuery = useCallback(updateQueryString,[navigate, location, query_param])

    return [
        getCurrentState(),
        setQuery,
    ]
}