import React, { useState, useEffect } from "react";
import Loader from "../../../popup/loader";
import NavigationBar from "../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import "../../../../assets/css/ApprovalList.css";
import DynamicTableWithCustomInners from "../../../tables/DynamicTableWithCustomInners";
import CallAPI from "../../../api/callApi";
import { apiUrl } from "../../../../config";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../../utils/userRoles";
const ApprovalList = () => {
  const { t } = useTranslation("approval");
  const [loaderState, setLoaderState] = useState(false);
  const locationArray = [[t("approval"), "/brand/approval"]];
  const [requests, setRequests] = useState([]);
  const navigate = useNavigate();

  const investigateItem = async (item) => {
    if (item.publishRequestType === "CAMPAIGN") {
      const url = `${apiUrl}/api/v1/brand-admin/brand/campaign/${item.sourceId}`;
      setLoaderState(true);
      try {
        const response = await CallAPI({ method: "get", endPoint: url });

        navigate("/brand/srm/campaign/preview", {
          state: { returnedApiData: response.data, requestId: item.id },
        });
      } catch {
      } finally {
        setLoaderState(false);
      }
    } else if (item.publishRequestType === "GIFT_COIN") {
      const url = `${apiUrl}/api/v1/brand-admin/sotier/gift/${item.sourceId}`;

      setLoaderState(true);
      try {
        const response = await CallAPI({ method: "get", endPoint: url });
        console.log(response);
        navigate(
          `/brand/srm/sotier/profile/${response.data.responseData.sotiers[0].id}/gift`,
          {
            state: {
              returnedApiData: response.data.responseData,
              requestId: item.id,
            },
          }
        );
      } catch {
      } finally {
        setLoaderState(false);
      }
    } else if (item.publishRequestType === "CHALLENGE") {
      const urls = [
        `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${item.sourceId}/detail`,
        `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${item.sourceId}/detail`,
        `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${item.sourceId}/detail`,
      ];

      setLoaderState(true);

      // Hata almadıkça her URL'yi sırayla dene
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        try {
          const response = await CallAPI({
            method: "get",
            endPoint: url,
            dontShowError: true,
          });
          console.log("challenge", response);
          navigate("/brand/challenges/preview", {
            state: { returnedApiData: response.data, requestId: item.id },
          });
          setLoaderState(false);
          return; // Başarılı bir sonuç aldığında diğer URL'lere geçme
        } catch (error) {
          // Hata olursa sıradaki URL'yi dene
          console.log(`Error with URL: ${url}`, error);
          if (i === urls.length - 1) {
            // Son URL'de hata alırsan
            console.log("All attempts failed");
          }
        }
      }
      setLoaderState(false);
    }
  };

  const getRequests = async () => {
    setLoaderState(true);
    try {
      const url = `${apiUrl}/api/v1/brand-admin/publish/request`;
      const response = await CallAPI({
        method: "get",
        endPoint: url,
      });
      console.log(response.data.responseData);
      const updatedRequests = response.data.responseData.map((item) => ({
        ...item,
        publishRequestStatus:
          item.publishRequestStatus === "REQUESTED" &&
          userRoles()?.includes("APPROVER") ? (
            <div
              className="investigate-button"
              onClick={() => {
                investigateItem(item);
              }}
            >
              {t("investigate")}
            </div>
          ) : (
            t(item.publishRequestStatus)
          ),
        reviewer:
          item.publishRequestStatus === "REQUESTED" ? "" : item.reviewer,
      }));
      setRequests(updatedRequests);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoaderState(false);
    }
  };

  useEffect(() => {
    getRequests();
  }, []);

  const columns = [
    { title: t("ID"), field: "id", className: "column-id" },
    { title: t("SourceID"), field: "sourceId", className: "column-id" },

    {
      title: t("RequestType"),
      field: "publishRequestType",
      className: "column-type",
      render: (item) => t(item.publishRequestType), // Çevirme işlemi
    },
    {
      title: t("CreatedAt"),
      field: "createdAt",
      className: "column-date",
      render: (item) => new Date(item.createdAt).toLocaleString(),
    },
    {
      title: t("Creator"),
      field: "creator",
      className: "column-name",
      render: (item) => t(item.creator),
    },

    {
      title: t("Reviewer"),
      field: "reviewer",
      className: "column-name",
      render: (item) => t(item.reviewer),
    },

    {
      title: t("Notes"),
      field: "notes",
      className: "column-notes",
      render: (item) =>
        item.notes.map((note) => t(note.note) || note.note).join(", "), // Notes alanında da çeviri
    },

    {
      title: t("Status"),
      field: "publishRequestStatus",
      className: "column-status",
    },
  ];

  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />
      {loaderState && <Loader />}
      <DynamicTableWithCustomInners
        columns={columns}
        data={requests}
        paginationDisabled={true}
        customButtonDisabled={true}
        sortDisabled={true}
        tableName={t("Approval List")}
      />
    </div>
  );
};

export default ApprovalList;
