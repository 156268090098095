import React from "react";
import PropTypes from "prop-types";

const BrandAdminInput = ({
  id,
  value,
  onChange,
  readOnly = false,
  imageSrc,
  altText = "icon",
  error = false,
  warning = false,
}) => {
  // Dinamik className ayarı
  const inputAreaClass = `brand-admin-input-area ${
    error ? "error" : warning ? "warning" : ""
  }`;

  return (
    <div className={inputAreaClass}>
      <input
        id={id}
        className="brand-admin-input"
        type="text"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      <img
        src={imageSrc}
        alt={altText}
        className={`brand-admin-input-img ${value !== "" ? "slide-in" : ""}`}
      />
    </div>
  );
};

BrandAdminInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  imageSrc: PropTypes.string.isRequired,
  altText: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
};

export default BrandAdminInput;
