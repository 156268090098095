import React, { useState, useEffect } from "react";
import downArrow from "../../../../../assets/media/down-arrow.png";
import axios from "axios";
import { toast } from "react-toastify";
import arrow from "../../../../../assets/media/arrow.png";
import "../../../../../assets/css/ChallengePreview.css";
import "../../../../../assets/css/PhoneView.css";
import SurveyType from "./challenge_types/SurveyType";
import InteractionType from "./challenge_types/InteractionType";
import ContentType from "./challenge_types/ContentType";
import Approved from "../../popup_page/PopupPage.js";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imagesUrl } from "../../../../../config";
import { getCookie, hasApproverRole } from "../../../auth/tokenUtils.js";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import { apiUrl } from "../../../../../config";
import PhoneViewJoin from "../../../../phone/phoneViewJoin.js";
import PhoneViewInteraction from "../../../../phone/phoneViewInteraction.js";
import PhoneViewContent from "../../../../phone/phoneViewContent.js";
import PhoneViewSurvey from "../../../../phone/phoneViewSurvey.js";
import { useTranslation } from "react-i18next";
import Loader from "../../../../popup/loader.js";
import CallAPI from "../../../../api/callApi.js";

const ChallengePreview = ({ challengeType }) => {
  const { t, i18n } = useTranslation("challengeCreation");
  const navigate = useNavigate();
  const location = useLocation();
  const [loaderState, setLoaderState] = useState(false);

  const [requestId, setRequestId] = useState();
  const [isApproverApproves, setIsApproverApproves] = useState(false);
  const { returnedApiData } = location.state || {};

  const [isChallengePublished, setIsChallengePublished] = useState(false);

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      setRequestId(location.state.requestId);
      if (
        location.state.returnedApiData.responseData.status ===
        "PUBLISH_REQUESTED"
      ) {
        setIsApproverApproves(hasApproverRole());
      }
    }
  }, [location.state]);

  if (!returnedApiData) {
    return (
      <div className="dashboard-container">
        <p>Data not available. Please check your API response.</p>
      </div>
    );
  }

  const returnToEdit = async () => {
    setLoaderState(true);

    const challenge = returnedApiData.responseData;
    const challengeId = returnedApiData.responseData.id;
    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      setLoaderState(false);

      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"));
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      setLoaderState(false);

      navigate("/sign-in-brand");
    }

    let endpoint;
    if (challenge.challengeType === "SURVEY") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
    } else if (challenge.challengeType === "SOCIAL_MEDIA") {
      if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
      }
    }

    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      if (data.responseData.socialChallengeType === "CONTENT_CREATION") {
        setLoaderState(false);

        navigate("/brand/challenges/content-creation", {
          state: { returnedChallenge: data },
        });
      } else if (data.responseData.socialChallengeType === "INTERACTION") {
        setLoaderState(false);

        navigate("/brand/challenges/interaction-creation", {
          state: { returnedChallenge: data },
        });
      } else if (data.responseData.challengeType === "SURVEY") {
        setLoaderState(false);

        navigate("/brand/challenges/survey-creation", {
          state: { returnedChallenge: data },
        });
      }
      setLoaderState(false);

      // Do something with the data
    } catch (error) {
      setLoaderState(false);

      console.error("Error fetching data:", error);
      toast.error(t("error.fetchingData") + " (" + error.message + ")");
    }
  };

  const handlePublishChallenge = async ({
    textareaValue = "",
    actionType = "",
  }) => {
    setLoaderState(true);

    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      setLoaderState(false);

      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"));
      return;
    }
    if (isMyTokenExpired(token)) {
      setLoaderState(false);

      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }
    let endPoint = "";
    if (returnedApiData.responseData.socialChallengeType === "CONTENT_CREATION")
      endPoint =
        apiUrl +
        `/api/v1/brand-admin/brand/challenge/social/content/${returnedApiData.responseData.id}/publish/request`;
    else if (returnedApiData.responseData.socialChallengeType === "INTERACTION")
      endPoint =
        apiUrl +
        `/api/v1/brand-admin/brand/challenge/social/interaction/${returnedApiData.responseData.id}/publish/request`;
    else if (returnedApiData.responseData.challengeType === "SURVEY")
      endPoint =
        apiUrl +
        `/api/v1/brand-admin/brand/challenge/survey/${returnedApiData.responseData.id}/publish/request`;

    // Axios configuration for the request

    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      setLoaderState(false);

      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"));
      return;
    }
    // Send the PUT request
    if (isApproverApproves) {
      try {
        setLoaderState(true); // Start loader to indicate the process is ongoing

        // Determine success or error messages based on the action type (APPROVE/REJECT)
        const successMessage =
          actionType === "APPROVE"
            ? t("ApprovedSuccessfully")
            : t("RejectedSuccessfully");

        const errorMessage =
          actionType === "APPROVE"
            ? t("anErrorAcurredWhileApproving")
            : t("anErrorAcurredWhileRejecting");

        // Call API with the appropriate method, endpoint, body, and messages
        await CallAPI({
          method: "put",
          endPoint: `${apiUrl}/api/v1/brand-admin/publish/request/${requestId}`,
          body: { publishRequestAction: actionType, note: textareaValue },
          successMessage: successMessage,
          errorMessage: errorMessage,
        });
        navigate("/brand/approval");
      } catch (error) {
        // If an error occurs, stop the loader and log the error
        console.error("Error during API call:", error);

        // Optionally, show an error message to the user (you can display this in the UI)
        // Example: setErrorMessage(t("Something went wrong, please try again."));
      } finally {
        // Ensure that loader is stopped regardless of success or failure
        setLoaderState(false);
      }
    } else {
      axios
        .put(
          endPoint,
          {}, // Empty object as the second argument
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          setIsChallengePublished(true);
          setLoaderState(false);
        })
        .catch((error) => {
          setLoaderState(false);

          console.error("Request Details:", error.config);
          // Handle the response status codes
          console.error(`Error: ${error.response.data || error.message}`);
          toast.error(
            t("error.ChallengeCreation") + " (" + error.message + ")"
          );
        });
    }
    setLoaderState(false);
  };

  const renderChallengeType = () => {
    switch (returnedApiData.responseData.socialChallengeType) {
      case "CONTENT_CREATION":
        return (
          <ContentType
            apiData={returnedApiData}
            onClick={({ textareaValue = "", actionType = "" }) => {
              handlePublishChallenge({
                textareaValue,
                actionType,
              });
            }}
            onEditChallenge={returnToEdit}
            isApproverApproves={isApproverApproves}
          />
        );
      default:
        break;
    }

    switch (returnedApiData.responseData.challengeType) {
      case "SURVEY":
        return (
          <SurveyType
            apiData={returnedApiData}
            onClick={({ textareaValue = "", actionType = "" }) => {
              handlePublishChallenge({
                textareaValue,
                actionType,
              });
            }}
            onEditChallenge={returnToEdit}
            isApproverApproves={isApproverApproves}
          />
        );
      case "SOCIAL_MEDIA":
        return (
          <InteractionType
            apiData={returnedApiData}
            onClick={({ textareaValue = "", actionType = "" }) => {
              handlePublishChallenge({
                textareaValue,
                actionType,
              });
            }}
            onEditChallenge={returnToEdit}
            isApproverApproves={isApproverApproves}
          />
        );
      case "creation":
        return (
          <ContentType
            apiData={returnedApiData}
            onClick={({ textareaValue = "", actionType = "" }) => {
              handlePublishChallenge({
                textareaValue,
                actionType,
              });
            }}
            onEditChallenge={returnToEdit}
            isApproverApproves={isApproverApproves}
          />
        );
      default:
        return null;
    }
  };

  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("tr-TR", options);
  };

  return (
    <>
      {loaderState && <Loader />}

      {isChallengePublished ? (
        <Approved
          challengeImg={returnedApiData.responseData.logo}
          challengeName={returnedApiData.responseData.name}
          redirectPage={"/brand/challenges"}
        />
      ) : (
        <div className="dashboard-container">
          <div className="content-location-container">
            <a className="content-location-container-href" href="#">
              <img src={arrow} alt="arrow" />
            </a>
            <a className="content-location-container-link-text" href="#">
              <div className="older">{t("challenges")}</div>
            </a>
            <img src={downArrow} className="backArrow" alt="down-arrow" />
            <div className="current">{t("createChallenge")}</div>
            <img src={downArrow} className="backArrow" alt="down-arrow" />
            <div className="current">{t("preview")}</div>
          </div>
          <hr />

          <div className="preview-container">
            {renderChallengeType(challengeType)}
            <div className="phone-view-container">
              <PhoneViewJoin
                returnedApiData={returnedApiData}
                imagesUrl={imagesUrl}
                formatDateString={formatDateString}
                sticky={false}
              />

              <div className="phone-view">
                {returnedApiData.responseData.challengeType === "SURVEY" && (
                  <PhoneViewSurvey
                    returnedApiData={returnedApiData}
                    imagesUrl={imagesUrl}
                    formatDateString={formatDateString}
                  />
                )}
                {returnedApiData.responseData.socialChallengeType ===
                  "INTERACTION" && (
                  <PhoneViewInteraction
                    returnedApiData={returnedApiData}
                    imagesUrl={imagesUrl}
                    formatDateString={formatDateString}
                  />
                )}
                {returnedApiData.responseData.socialChallengeType ===
                  "CONTENT_CREATION" && (
                  <PhoneViewContent
                    returnedApiData={returnedApiData}
                    imagesUrl={imagesUrl}
                    formatDateString={formatDateString}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChallengePreview;
