import React, { useState, useEffect, useRef } from "react";
import DownArrow from '../../assets/media/down-arrow.png';
import '../../assets/css/SortFilterSearch.css';
import DateRangePickerNew from "../dialogs/dateRangePickerNew";
import { useTranslation } from "react-i18next";
import {format} from "date-fns";

const DatePickerButtonNew = ({ locale, dateRange, maxDate, onDateRangeChange }) => {
    const { t } = useTranslation("challengeDashboard");

    const [isPickerVisible, setPickerVisibility] = useState(false);
    const [resetButton, setResetButton] = useState(false);
    const datePickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setPickerVisibility(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datePickerRef]);

    const handleDateRangeChange = (newDateRange) => {
        onDateRangeChange(newDateRange);
    };

    const togglePickerVisibility = () => {
        setPickerVisibility(!isPickerVisible);
    };

    const handleResetClick = (state) => {
        setResetButton(state);
    }

    function formatDateRange() {
        const formattedStart = format(dateRange.start, 'P', {locale: locale});
        const formattedEnd = format(dateRange.end, 'P', {locale: locale});
        return formattedStart + "  -  " + formattedEnd;
    }

    return (
        <div className="date-picker-button-container" ref={datePickerRef}>
            <div className="date-picker-button-clickable" onClick={togglePickerVisibility}>
                <div className="date-picker-button-left">
                    <div className="date-picker-button-title">
                        {t("date")}
                    </div>
                    <div className="date-picker-button-date">
                        {formatDateRange()}
                    </div>
                </div>
                <div className="date-picker-button-right">
                    <img src={DownArrow} alt="V" />
                </div>
            </div>
            {isPickerVisible &&
                <DateRangePickerNew
                    locale={locale}
                    dateRange={dateRange}
                    onDateRangeChange={handleDateRangeChange}
                    maxDate={maxDate}
                    onResetClick={handleResetClick}
                />
            }
        </div>
    );
};

export default DatePickerButtonNew;
