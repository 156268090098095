import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg";
import DoubleSidedArrow from "../../../../../assets/media/double-horizontal-arrow.png";

const BuyXPayY = ({
  buyXPayYOptions,
  buyCount,
  payCount,
  setBuyCount,
  setPayCount,
  returnedCampaign,
  t,
}) => {
  return (
    <>
      <div className="coin-folding-container">
        <div className="coin-folding-input-container campaign-option-select-container">
          <div className="challenge-creation-input-container-header-subtitle">
            {t("popularChoices")}
          </div>
          <div className="coin-folding-input-wrapper">
            {buyXPayYOptions.map((choice, index) => (
              <div
                key={index}
                className={`percentage-coupon-input ${
                  buyCount === choice.buy && payCount === choice.pay
                    ? "percentage-coupon-input-active"
                    : ""
                }`}
                onClick={() => {
                  setBuyCount(choice.buy);
                  setPayCount(choice.pay);
                }}
              >
                {localStorage.getItem("language") === "tr-TR"
                  ? `${choice.buy} ${t("buy")} ${choice.pay} ${t("pay")}`
                  : `${t("buy")} ${choice.buy} ${t("pay")} ${choice.pay}`}
                {buyCount === choice.buy && payCount === choice.pay && (
                  <img src={tick} alt="tick" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="coin-folding-input-wrapper x-price-input-container justify-content-center">
        <input
          type="number"
          min="0"
          max="5"
          className="challenge-creation-soty-coin-input wide"
          placeholder={t("ItemPurchaseQuantityError")}
          value={returnedCampaign?.responseData.buyCount || buyCount}
          onChange={(e) => {
            const value = Math.min(e.target.value, 5);
            setBuyCount(value);
          }}
        />
        {t("buy")}

        <img className="X-Price-To-Y-Icon" src={DoubleSidedArrow} alt={"<->"} />

        <input
          type="number"
          min="0"
          max="4"
          className="challenge-creation-soty-coin-input wide"
          placeholder={t("PaymentItemCountError")}
          value={returnedCampaign?.responseData.payCount || payCount}
          onChange={(e) => {
            const value = Math.min(e.target.value, 4);
            setPayCount(value);
          }}
        />
        {t("pay")}
      </div>
    </>
  );
};

export default BuyXPayY;
