import React from "react";
import ChallengeImageDropzone from "../../../../dropzone/ChallengeImageDropzone.js";

const NewSubBrandForm = ({
  checkImage,
  tick,
  t,
  newSubBrandName,
  setNewSubBrandName,
  handleSubBrandImageUpload,
  handleSubBrandSubmit,
  readOnly,
}) => {
  return (
    <div className="brand-admin-info-forced-row">
      <div className="brand-admin-info-container">
        <div className="brand-admin-info-container-title-container">
          {newSubBrandName && (
            <img
              src={checkImage}
              alt="ico"
              className="brand-admin-info-container-title-img"
            />
          )}

          <div className="brand-admin-info-container-title">
            {t("addNewSubBrand")}
          </div>
        </div>
        <div className="brand-admin-info-container-wrapper">
          <div className="brand-admin-info-container-row">
            <div className="brand-admin-input-container">
              <div className="brand-admin-input-title">{t("brandLogo")}:</div>

              <ChallengeImageDropzone
                brandLogo={true}
                onImageUpload={(file) => handleSubBrandImageUpload(file)}
              />
            </div>
            <div className="brand-admin-input-container">
              <div className="brand-admin-input-title">{t("brandName")}:</div>
              <div className="brand-admin-input-area">
                <input
                  id="brandName"
                  className="brand-admin-input"
                  type="text"
                  value={newSubBrandName}
                  onChange={(e) => setNewSubBrandName(e.target.value)}
                />
                {newSubBrandName && (
                  <img
                    src={tick}
                    alt="ico"
                    className={`brand-admin-input-img ${
                      newSubBrandName !== "" ? "slide-in" : ""
                    }`}
                  />
                )}
              </div>
            </div>

            <div className="brand-admin-input-container">
              <div className="brand-admin-input-title hidden">
                {t("brandLogo")}:
              </div>
              <button
                className="button save-sub-brand-button"
                onClick={() => handleSubBrandSubmit()}
                disabled={readOnly}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSubBrandForm;
