import React, {useEffect, useState} from "react";
import '../../assets/css/AnalysisLineChartCard.css';
import Chart from "react-google-charts";


const AnalysisLineChartCard = ({
                                   locale,
                                   cardTitle,
                                   legend,
                                   data,
                               }) => {

    const chartData = [legend, ...data];

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        let resizeTo;
        const handleResize = () => {
            if (resizeTo) {
                clearTimeout(resizeTo);
            }
            resizeTo = setTimeout(() => {
                setWindowSize(window.innerWidth);
            }, 500);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="analysis-line-chart-card">
            <h1 className="analysis-line-chart-card-title">{cardTitle}</h1>
            <div className="analysis-line-chart-card-chart">
                <Chart
                    chartType="LineChart"
                    chartLanguage={locale.code}
                    data={chartData}
                    options={{
                        vAxis: {
                            minValue: 0,
                            maxValue: 1000,
                        },
                        curveType: "none",
                        colors: ["#1E85FF", "#E63535", "#1CB454", "#E2A907"],
                        legend: {
                            position: "bottom",
                        },
                        series: {
                            2: {},
                            0: {lineDashStyle: [4, 4]},
                            1: {lineDashStyle: [4, 4]},
                        },
                        //lineDashStyle: [5,1,3],
                        //enableInteractivity: false,
                    }}
                    key={windowSize}
                />
            </div>
        </div>
    );
};

export default AnalysisLineChartCard;
