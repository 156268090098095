import React, {useEffect, useState} from 'react';
import arrow from '../../../../../assets/media/arrow.png';
import downArrow from '../../../../../assets/media/down-arrow.png';
import analysisIcon from '../../../../../assets/media/analysis.svg';
import '../../../../../assets/css/Analysis.css';
import {useNavigate, useLocation} from 'react-router-dom';
import SrmSotier from './srm/sotier';
import {useTranslation} from "react-i18next";
import DatePickerButtonNew from '../../../../button/datePickerButtonNew';
import RecursiveMenu from '../../../../collapsible/recursiveMenu';
import {tr, enUS} from 'date-fns/locale';
import DetailIcon from '../../../../../assets/media/Icon feather-arrow-right-circle.svg';
import {
    metabaseUrl,
    metabaseRealmAdminTotalChallengeCompletionDaily,
    metabaseRealmAdminTotalChallengeCompletionWeekly,
    metabaseRealmAdminSotierEarningDaily,
    metabaseRealmAdminSotierEarningWeekly,
    metabaseRealmAdminSotierSpendingDaily,
    metabaseRealmAdminSotierSpendingWeekly,
    metabaseRealmAdminTotalEarningDaily,
    metabaseRealmAdminTotalEarningDetails,
    metabaseRealmAdminTotalEarningWeekly,
    metabaseRealmAdminTotalSpendingDaily,
    metabaseRealmAdminTotalSpendingWeekly,
    metabaseRealmAdminTotalContentCreationWeekly,
    metabaseRealmAdminTotalContentCreationByProvider,
    metabaseRealmAdminTotalContentCreationTop5Tag,
    metabaseRealmAdminTotalContentCreationTop5HashTag,
    metabaseRealmAdminTotalContentCreationDaily,
    metabaseRealmAdminTotalOrderWeekly,
    metabaseRealmAdminCountOrderWeekly,
    metabaseRealmAdminTotalOrderDaily,
    metabaseRealmAdminCountOrderDaily,
    metabaseRealmAdminTotalRefundDaily,
    metabaseRealmAdminTotalRefundWeekly,
    metabaseRealmAdminCountRefundWeekly,
    metabaseRealmAdminCountRefundDaily,
    metabaseRealmAdminTotalSpendingDetails,
    metabaseRealmAdminTotalRefundDetails,
    metabaseRealmAdminCountRefundDetails,
    metabaseRealmAdminCountOrderDetails,
    metabaseRealmAdminTotalOrderDetails,
    metabaseRealmAdminTotalContentCreationList,
    metabaseRealmAdminTotalContentCreationByDaysOfTheWeek,
    metabaseRealmAdminTotalInteractionWeekly,
    metabaseRealmAdminTotalInteractionDaily,
    metabaseRealmAdminTotalInteractionByProvider,
    metabaseRealmAdminTotalInteractionByDaysOfTheWeek,
    metabaseRealmAdminTotalInteractionList,
    metabaseRealmAdminTotalChallengeCompletionByProvider,
    metabaseRealmAdminTotalChallengeCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalChallengeCompletionTop5Tag,
    metabaseRealmAdminTotalChallengeCompletionTop5HashTag,
    metabaseRealmAdminTotalChallengeCompletionList,
    metabaseRealmAdminTotalSurveyCompletionWeekly,
    metabaseRealmAdminTotalSurveyCompletionDaily,
    metabaseRealmAdminTotalSurveyCompletionByType,
    metabaseRealmAdminTotalSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalSurveyCompletionList,
    metabaseRealmAdminTotalSingleChoiceSurveyCompletionWeekly,
    metabaseRealmAdminTotalSingleChoiceSurveyCompletionDaily,
    metabaseRealmAdminTotalSingleChoiceSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalSingleChoiceSurveyCompletionList,
    metabaseRealmAdminTotalMultipleChoiceSurveyCompletionWeekly,
    metabaseRealmAdminTotalMultipleChoiceSurveyCompletionDaily,
    metabaseRealmAdminTotalMultipleChoiceSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalMultipleChoiceSurveyCompletionList,
    metabaseRealmAdminTotalRatingScaleSurveyCompletionWeekly,
    metabaseRealmAdminTotalRatingScaleSurveyCompletionDaily,
    metabaseRealmAdminTotalRatingScaleSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalRatingScaleSurveyCompletionList,
    metabaseRealmAdminTotalScoreSurveyCompletionWeekly,
    metabaseRealmAdminTotalScoreSurveyCompletionDaily,
    metabaseRealmAdminTotalScoreSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalScoreSurveyCompletionList,
    metabaseRealmAdminTotalLikertSurveyCompletionWeekly,
    metabaseRealmAdminTotalLikertSurveyCompletionDaily,
    metabaseRealmAdminTotalLikertSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalLikertSurveyCompletionList,
    metabaseRealmAdminTotalLikertAgreementSurveyCompletionWeekly,
    metabaseRealmAdminTotalLikertAgreementSurveyCompletionDaily,
    metabaseRealmAdminTotalLikertAgreementSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalLikertAgreementSurveyCompletionList,
    metabaseRealmAdminTotalLikertFrequencySurveyCompletionWeekly,
    metabaseRealmAdminTotalLikertFrequencySurveyCompletionDaily,
    metabaseRealmAdminTotalLikertFrequencySurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalLikertFrequencySurveyCompletionList,
    metabaseRealmAdminTotalLikertImportanceSurveyCompletionWeekly,
    metabaseRealmAdminTotalLikertImportanceSurveyCompletionDaily,
    metabaseRealmAdminTotalLikertImportanceSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalLikertImportanceSurveyCompletionList,
    metabaseRealmAdminTotalLikertInterestSurveyCompletionWeekly,
    metabaseRealmAdminTotalLikertInterestSurveyCompletionDaily,
    metabaseRealmAdminTotalLikertInterestSurveyCompletionByDaysOfTheWeek,
    metabaseRealmAdminTotalLikertInterestSurveyCompletionList
} from '../../../../../config';
import {useQueryState} from "../../../../utils/useQueryState";
import {useDateRangeQueryState} from "../../../../utils/useDateRangeQueryState";
import {DateRange} from "../../../../utils/dateRange";
import StaticDateRange from "../../../../fragments/staticDateRange";
import {endOfToday, format, formatISO, fromUnixTime} from "date-fns";

const Analysis = () => {
    const {t, i18n} = useTranslation("analysis");
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedChannel, setSelectedChannel] = useQueryState('parameter', "/general");
    const [pageLabel, setPageLabel] = useState(localStorage.getItem('pageLabel') || undefined);
    const [dateRange, setDateRange] = useDateRangeQueryState('date_range', DateRange.defaultDateRange())
    const [language, setLanguage] = useState();

    const renderChildComponentIfParameterMatches = (parameterValue, childComponent) => {
        return (selectedChannel === parameterValue) && childComponent;
    };

    const onSotierClick = (sotier) => {
        const searchParams = new URLSearchParams();
        searchParams.set('sotier', sotier.sotierId);
        window.location.search = searchParams.toString();
    };

    useEffect(() => {
        if (localStorage.getItem('language') === 'tr-TR') {
            setLanguage(tr);
        } else if (localStorage.getItem('language') === 'en-US') {
            setLanguage(enUS);
        }
    }, []);

    const srmOptions = [
        {value: "/general", label: t("general")},
        {
            value: "/challenge", label: t("challenge"), items: [
                {value: "/challenge/content", label: t("contentCreation")},
                {value: "/challenge/interaction", label: t("interaction")}
            ]
        },
        {
            value: "/survey", label: t("survey"), items: [
                {value: "/survey/surveySingle", label: t("singleChoice")},
                {value: "/survey/surveyMultiple", label: t("multipleChoice")},
                {
                    value: "/survey/ratingScale", label: t("ratingScale"), items: [
                        {value: "/survey/ratingScale/linearNumeric", label: t("linearScale")},
                        {
                            value: "/survey/ratingScale/likertScale", label: t("likertScale"), items: [
                                {value: "/survey/ratingScale/likertScale/agreement", label: t("agreement")},
                                {value: "/survey/ratingScale/likertScale/frequency", label: t("frequency")},
                                {value: "/survey/ratingScale/likertScale/importance", label: t("importance")},
                                {value: "/survey/ratingScale/likertScale/interest", label: t("interest")},
                            ]
                        },
                    ]
                },
            ]
        },
        {
            value: "/srm", label: t("srm"), items: [/*
                {
                    value: "/srm/campaigns", label: t("campaigns"), items: [
                        { value: "/srm/campaigns/sotyCoefficient", label: t("sotyCoefficient") },
                        { value: "/srm/campaigns/percentage", label: t("percentage") },
                        { value: "/srm/campaigns/xShopping", label: t("xShopping") },
                        { value: "/srm/campaigns/freeShipping", label: t("freeShipping") },
                        { value: "/srm/campaigns/xProduct", label: t("xProduct") },
                        { value: "/srm/campaigns/BuyxPayy", label: t("buyxPayy") }
                    ]
                },*/
                {value: "/srm/sotier", label: t("sotier")},/*
                { value: "/srm/customDay", label: t("customDay") },
                { value: "/srm/corporate", label: t("corporate") },
                { value: "/srm/segment", label: t("segment") },
                { value: "/srm/contents", label: t("contents") },*/
            ]
        }
    ];

    const goBack = () => {
        localStorage.removeItem('pageLabel');
        setSelectedChannel();
        setPageLabel();
        navigate('/brand/analysis');
    }

    function shouldLoadWeeklyGraph() {
        return dateRange.differenceInDays() > 59;
    }

    const MetabaseFrame = ({question, parameterName, detail, detailDestination, height = '600px'}) => {
        const urlParams = new URLSearchParams(location.search);
        const sotierParamValue = urlParams.get(parameterName);

        let iframeSrc;
        if (urlParams.has('sotier')) {
            iframeSrc = `${metabaseUrl}/public/question/${question}?sotier_uuid=${sotierParamValue}&start_date=${dateRange.getFormattedStart()}&end_date=${dateRange.getFormattedEnd()}#hide_parameters=sotier_uuid,start_date,end_date&titled=false`;
        } else {
            iframeSrc = `${metabaseUrl}/public/question/${question}?start_date=${dateRange.getFormattedStart()}&end_date=${dateRange.getFormattedEnd()}#hide_parameters=sotier_uuid,start_date,end_date&titled=false`;
        }
        return (
            <div className='metabase-frame-wrapper'>
                <iframe
                    id="myIframe"
                    src={iframeSrc}
                    frameBorder="0"
                    width="100%"
                    height={height}
                ></iframe>
                {detail &&
                    <div className='metabase-frame-details-button' onClick={() => {
                        setSelectedChannel(detailDestination)
                    }}>
                        {t("detail")}
                        <img className='metabase-frame-details-button-img' src={DetailIcon} alt={">"}/>
                    </div>
                }
            </div>
        )
    }

    console.log("BURADA ===========> " + formatISO(fromUnixTime(1716843600)));

    return (
        <div className="dashboard-container challenge-analysis-margin">
            <div className="content-location-container analysis-location-container">
                <a
                    className="content-location-container-href"
                    onClick={goBack}
                    href="/brand/analysis"
                >
                    <img src={arrow} alt="arrow"/>
                </a>
                <a
                    className="content-location-container-link-text"
                    href="/brand/analysis"
                >
                    <div className="older">{t("analysis")}</div>
                </a>
                {pageLabel && (
                    <>
                        <img src={downArrow} className="backArrow" alt="down-arrow"/>
                        <div className="current">{pageLabel}</div>
                    </>
                )}
            </div>
            <div className="analysis-top-divider"/>
            <div className="challenge-analysis-top-controls-container">
                <div className="challenge-analysis-top-buttons-container">
                    <DatePickerButtonNew
                        locale={language}
                        dateRange={dateRange}
                        onDateRangeChange={setDateRange}
                        maxDate={endOfToday()}
                    />
                </div>
                <div className="challenge-analysis-title-container">
                    <div className="challenge-analysis-title">
                        {t("#analysisDashboard")}
                    </div>
                    <div className="challenge-analysis-description">
                        {t("analysisDashboardDescription")}
                    </div>
                    <div className="challenge-analysis-description-logo-wrapper">
                        <img
                            className="challenge-analysis-description-logo"
                            src={analysisIcon}
                            alt="anlys"
                        />
                    </div>
                </div>
            </div>
            <StaticDateRange
                locale={language}
                dateRange={dateRange}
                onDateRangeChange={setDateRange}
            />
            <div className="challenge-analysis-container">
                <div className="challenge-analysis-channels-container">
                    <div className="challenge-analysis-channels-title">
                        {t("analysisChannels")}
                    </div>
                    <RecursiveMenu
                        items={srmOptions}
                        setClickedItem={(item) => {
                            setSelectedChannel(item.value)
                        }}
                    />
                </div>
                <div className="analysis-container-analysis-wrapper">
                    {renderChildComponentIfParameterMatches("/srm/sotier",
                        <SrmSotier startDate={dateRange.start} endDate={dateRange.end} onSotierClick={onSotierClick}/>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "sotier",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <>
                                    <MetabaseFrame
                                        question={metabaseRealmAdminSotierEarningWeekly}
                                        parameterName={"sotier"}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminSotierSpendingWeekly}
                                        parameterName={"sotier"}
                                    />
                                </>
                            ) : (
                                <>
                                    <MetabaseFrame
                                        question={metabaseRealmAdminSotierEarningDaily}
                                        parameterName={"sotier"}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminSotierSpendingDaily}
                                        parameterName={"sotier"}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/general",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <>
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalEarningWeekly}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalEarningDetails",
                                            label: t("totalEarningDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalSpendingWeekly}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalSpendingDetails",
                                            label: t("totalSpendingDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalOrderWeekly}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalOrderDetails",
                                            label: t("totalOrderDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminCountOrderWeekly}
                                        detail={true}
                                        detailDestination={{
                                            value: "/countOrderDetails",
                                            label: t("countOrderDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalRefundWeekly}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalRefundDetails",
                                            label: t("totalRefundDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminCountRefundWeekly}
                                        detail={true}
                                        detailDestination={{
                                            value: "/countRefundDetails",
                                            label: t("countRefundDetails"),
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalEarningDaily}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalEarningDetails",
                                            label: t("totalEarningDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalSpendingDaily}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalSpendingDetails",
                                            label: t("totalSpendingDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalOrderDaily}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalOrderDetails",
                                            label: t("totalOrderDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminCountOrderDaily}
                                        detail={true}
                                        detailDestination={{
                                            value: "/countOrderDetails",
                                            label: t("countOrderDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalRefundDaily}
                                        detail={true}
                                        detailDestination={{
                                            value: "/totalRefundDetails",
                                            label: t("totalRefundDetails"),
                                        }}
                                    />
                                    <MetabaseFrame
                                        question={metabaseRealmAdminCountRefundDaily}
                                        detail={true}
                                        detailDestination={{
                                            value: "/countRefundDetails",
                                            label: t("countRefundDetails"),
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/totalEarningDetails",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalEarningWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalEarningDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminTotalEarningDetails}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/totalSpendingDetails",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalSpendingWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalSpendingDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminTotalSpendingDetails}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/totalOrderDetails",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalOrderWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalOrderDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminTotalOrderDetails}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/countOrderDetails",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminCountOrderWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminCountOrderDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminCountOrderDetails}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/totalRefundDetails",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalRefundWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalRefundDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminTotalRefundDetails}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/countRefundDetails",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminCountRefundWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminCountRefundDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminCountRefundDetails}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/challenge",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalChallengeCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalChallengeCompletionDaily}/>
                            )}
                            <div className="analysis-graph-container-row">
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalChallengeCompletionByProvider}/>{" "}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalChallengeCompletionByDaysOfTheWeek}/>{" "}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame question={metabaseRealmAdminTotalChallengeCompletionTop5Tag}/>{" "}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalChallengeCompletionTop5HashTag}/>{" "}
                                </div>
                            </div>
                            <MetabaseFrame question={metabaseRealmAdminTotalChallengeCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/challenge/content",
                        <>
                            <div className="analysis-graph-container-row">
                                <div className="analysis-graph-flex-3">
                                    {shouldLoadWeeklyGraph() ? (
                                        <MetabaseFrame question={metabaseRealmAdminTotalContentCreationWeekly}/>
                                    ) : (
                                        <MetabaseFrame question={metabaseRealmAdminTotalContentCreationDaily}/>
                                    )}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame question={metabaseRealmAdminTotalContentCreationByProvider}
                                                   height='300px'/>{" "}
                                    <MetabaseFrame question={metabaseRealmAdminTotalContentCreationByDaysOfTheWeek}
                                                   height='300px'/>{" "}
                                </div>
                            </div>
                            <div className="analysis-graph-container-row">
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame question={metabaseRealmAdminTotalContentCreationTop5Tag}/>{" "}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame question={metabaseRealmAdminTotalContentCreationTop5HashTag}/>{" "}
                                </div>
                            </div>
                            <MetabaseFrame question={metabaseRealmAdminTotalContentCreationList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/challenge/interaction",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalInteractionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalInteractionDaily}/>
                            )}
                            <div className="analysis-graph-container-row">
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalInteractionByProvider}/>{" "}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalInteractionByDaysOfTheWeek}/>{" "}
                                </div>
                            </div>
                            <MetabaseFrame question={metabaseRealmAdminTotalInteractionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalSurveyCompletionDaily}/>
                            )}
                            <div className="analysis-graph-container-row">
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalSurveyCompletionByType}/>{" "}
                                </div>
                                <div className="analysis-graph-flex-1">
                                    <MetabaseFrame
                                        question={metabaseRealmAdminTotalSurveyCompletionByDaysOfTheWeek}/>{" "}
                                </div>
                            </div>
                            <MetabaseFrame question={metabaseRealmAdminTotalSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/surveySingle",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalSingleChoiceSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalSingleChoiceSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalSingleChoiceSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalSingleChoiceSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/surveyMultiple",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalMultipleChoiceSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalMultipleChoiceSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalMultipleChoiceSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalMultipleChoiceSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalRatingScaleSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalRatingScaleSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalRatingScaleSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalRatingScaleSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale/linearNumeric",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalScoreSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalScoreSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminTotalScoreSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalScoreSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale/likertScale",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame question={metabaseRealmAdminTotalLikertSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalLikertSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale/likertScale/agreement",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertAgreementSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertAgreementSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalLikertAgreementSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalLikertAgreementSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale/likertScale/frequency",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertFrequencySurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertFrequencySurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalLikertFrequencySurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalLikertFrequencySurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale/likertScale/importance",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame
                                    question={metabaseRealmAdminTotalLikertImportanceSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertImportanceSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalLikertImportanceSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalLikertImportanceSurveyCompletionList}/>
                        </>
                    )}
                    {renderChildComponentIfParameterMatches(
                        "/survey/ratingScale/likertScale/interest",
                        <>
                            {shouldLoadWeeklyGraph() ? (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertInterestSurveyCompletionWeekly}/>
                            ) : (
                                <MetabaseFrame question={metabaseRealmAdminTotalLikertInterestSurveyCompletionDaily}/>
                            )}
                            <MetabaseFrame
                                question={metabaseRealmAdminTotalLikertInterestSurveyCompletionByDaysOfTheWeek}/>
                            <MetabaseFrame question={metabaseRealmAdminTotalLikertInterestSurveyCompletionList}/>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Analysis;
