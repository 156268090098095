import React from "react";
import infoIcon from "../../assets/media/AnalysisTotalCardInfo.svg";
import '../../assets/css/AnalysisTotalCard.css';


const AnalysisTotalCard = ({
                               cardTitle,
                               mainTitle,
                               mainSubtitle,
                               mainValue,
                               detailsTitle,
                               detailsTopTitle,
                               detailsTopValue,
                               detailsBottomTitle,
                               detailsBottomValue,
                           }) => {
    const showDetails = detailsTitle || detailsTopTitle || detailsTopValue || detailsBottomTitle || detailsBottomValue;

    return (
        <div className="analysis-total-card">
            <div className="analysis-total-card-main">
                <h1 className="analysis-total-card-title">{cardTitle}</h1>
                <h2 className="analysis-total-card-main-title">{mainTitle}</h2>
                {mainSubtitle && (
                    <h3 className="analysis-total-card-main-subtitle">{mainSubtitle}</h3>
                )}
                <p className="analysis-total-card-main-value">{mainValue}</p>
            </div>
            {showDetails && (
                <>
                    <div className="analysis-total-card-divider"/>
                    <div className="analysis-total-card-details">
                        <h1 className="analysis-total-card-details-title">{detailsTitle}</h1>
                        <h2 className="analysis-total-card-details-top-title">{detailsTopTitle}</h2>
                        <p className="analysis-total-card-details-top-value">{detailsTopValue}</p>
                        <h2 className="analysis-total-card-details-bottom-title">{detailsBottomTitle}</h2>
                        <p className="analysis-total-card-details-bottom-value">{detailsBottomValue}</p>
                    </div>
                </>
            )}
            <div className="analysis-total-card-info">
                <img
                    className="analysis-total-card-info-icon"
                    src={infoIcon}
                    alt="info"
                />
            </div>
        </div>
    );
};

export default AnalysisTotalCard;
