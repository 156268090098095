import React from "react";
import {useOutletContext} from "react-router-dom";
import SurveyLikertAgreementKPIs from "../../../../../fragments/challenge/kpis/surveyLikertAgreementKPIs";
import SurveyLikertAgreementDetails from "../../../../../fragments/challenge/details/surveyLikertAgreementDetails";

const LikertAgreementAnalysis = () => {

    const { language,dateRange } = useOutletContext();

    return (
        <div className='challenge-analysis-charts-container'>
            <SurveyLikertAgreementKPIs
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                dateRange={dateRange}
            />
            <hr/>
            <SurveyLikertAgreementDetails
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                dateRange={dateRange}
            />
        </div>
    )
};

export default LikertAgreementAnalysis;