import React, { useState, useEffect } from "react";
import Loader from "../../../popup/loader";
import {AnalysisApi} from "../../AnalysisApi";
import ChallengeDetails from "./challengeDetails";

const ContentCreationDetails = ({
                                    locale,
                                    brandUuid,
                                    dateRange
}) => {
    const [loaderState, setLoaderState] = useState(false);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setLoaderState(true);

        AnalysisApi.contentCreationDetails(brandUuid, dateRange, currentPage, itemPerPage)
            .then((data) => {
                setTotalPage(data.pageMetadata.totalPage);
                setTableData(data.responseData);
            })
            .finally(() => {
                setLoaderState(false);
            });
    }, [dateRange, itemPerPage, currentPage]);

    return (
        <>
            {loaderState ? (
                <Loader />
            ) :
            (
                <ChallengeDetails
                    locale={locale}
                    tableData={tableData}
                    currentPage={currentPage}
                    totalPage={totalPage}
                    setCurrentPage={setCurrentPage}
                    setItemPerPage={setItemPerPage}
                />
            )}
        </>
    )
}

export default ContentCreationDetails;