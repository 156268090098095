import React, { useState } from "react";
import arrow from '../../assets/media/down-arrow.png';
import '../../assets/css/RecursiveMenu.css';
import {useLocation, useNavigate} from "react-router-dom";

const RecursiveMenu = ({ items, collapse }) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [collapseStates, setCollapseStates] = useState(new Array(items.length).fill(false));

    const handleToggleCollapse = (idx) => {
        setCollapseStates((prev) => {
            const newState = new Array(prev);
            newState[idx] = !prev[idx];
            return newState;
        });
    };

    const handleLinkClick = (item) => {
        item.value && navigate(item.value + search);
    };

    const isClicked = (item) => {
        if (pathname.endsWith(item.value)) {
            return true;
        }
        if (item.items) {
            return item.items.some(isClicked);
        }
        return false;
    }

    return (
        <div className={`recursive-menu ${collapse ? 'recursive-menu-collapsed' : ''}`}>
            {items.map((item, idx) => (
                <div key={item.value} className="recursive-menu-item">
                    {item.items ? (
                        <div
                            className={`recursive-menu-title-row ${isClicked(item) ? 'recursive-menu-clicked' : ''}`}
                            onClick={() => {
                                handleToggleCollapse(idx);
                                handleLinkClick(item);
                            }}
                        >
                            <div className='recursive-menu-title'>{item.label}</div>
                            <img className={`recursive-menu-img ${collapseStates[idx] ? 'deg180' : ''}`} src={arrow} alt='V' />
                        </div>
                    ) : (
                        <div
                            className={`recursive-menu-item-link ${isClicked(item) ? 'recursive-menu-clicked' : ''}`}
                            onClick={() => {
                                handleLinkClick(item);
                            }}
                        >
                            {item.label}
                        </div>
                    )}
                    {item.items && (
                        <RecursiveMenu
                            items={item.items}
                            collapse={collapseStates[idx]}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default RecursiveMenu;
