import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { hasApproverRole } from "../pages/auth/tokenUtils";
import "../../assets/css/CustomModal.css";

const ApproveNeededActionButton = ({ onClick, title, isApproverApproves }) => {
  const { t } = useTranslation("userList");

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control the popup visibility
  const [textareaValue, setTextareaValue] = useState(""); // State to store the value of the textarea
  const [actionType, setActionType] = useState(null); // State to store the action type (approve or reject)

  const getButtonTitle = () => {
    if (hasApproverRole()) {
      console.log(isApproverApproves);
      return isApproverApproves ? t("approve") : title;
    } else {
      return t("sendForApproval");
    }
  };

  // Function to handle opening the popup
  const openPopup = (actionType) => {
    setIsPopupOpen(true);
    setActionType(actionType); // Set the action type (approve or reject)
  };

  // Function to handle closing the popup
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // Function to handle the value change in the textarea
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  // Function for the "Send" button in the popup
  const handleSendClick = () => {
    const currentActionType = actionType || ""; // Default to empty string if undefined
    const currentTextareaValue = textareaValue || ""; // Default to empty string if undefined

    // Call the passed onClick function with textareaValue and actionType
    if (onClick) {
      onClick({
        textareaValue: currentTextareaValue,
        actionType: currentActionType,
      });
    }

    closePopup(); // Close the popup after sending
  };

  return (
    <>
      {/* Main action button */}
      {isApproverApproves && (
        <button
          className="interaction-type-preview-challenge-release-button reject"
          onClick={() => openPopup("REJECT")} // Open popup for reject
        >
          {t("reject")}
        </button>
      )}
      <button
        className="interaction-type-preview-challenge-release-button"
        onClick={() => {
          if (isApproverApproves) {
            openPopup("APPROVE"); // Open popup for approve if isApproverApproves is true
          } else {
            onClick({
              textareaValue: "",
              actionType: "",
            }); // Call the onClick passed as a prop if not an approver
          }
        }}
      >
        {getButtonTitle()}
      </button>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="custom-modal-overlay">
          <div className="custom-modal-content">
            {/* Dynamic header based on action */}
            <h3>
              {actionType === "REJECT"
                ? t("pleaseTypeYourRejectionMessage")
                : t("pleaseTypeYourApprovalMessage")}
            </h3>

            {/* Textarea */}
            <textarea
              className="modal-textarea"
              placeholder={t("Add your comment...")}
              value={textareaValue}
              onChange={handleTextareaChange}
            />

            <div className="custom-modal-buttons">
              <button className="modal-button cancel" onClick={closePopup}>
                {t("cancel")}
              </button>
              <button
                className="modal-button confirm"
                onClick={handleSendClick}
              >
                {t("send")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApproveNeededActionButton;
