import React, {useEffect, useState} from 'react';
import arrow from '../../../../../assets/media/arrow.png';
import downArrow from '../../../../../assets/media/down-arrow.png';
import analysisIcon from '../../../../../assets/media/analysis.svg';
import '../../../../../assets/css/Analysis.css';
import {useNavigate, Outlet, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import DatePickerButtonNew from '../../../../button/datePickerButtonNew';
import RecursiveMenu from '../../../../collapsible/recursiveMenu';
import {tr, enUS} from 'date-fns/locale';
import {useDateRangeQueryState} from "../../../../utils/useDateRangeQueryState";
import {DateRange} from "../../../../utils/dateRange";
import StaticDateRange from "../../../../fragments/staticDateRange";
import {endOfToday} from "date-fns";
import SrmSotier from "../../../realm_admin/srm/analysis/srm/sotier";

const BrandAnalysis = () => {
    const {t, i18n} = useTranslation("analysis");
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useDateRangeQueryState('date_range', DateRange.defaultDateRange())
    const [language, setLanguage] = useState();

    useEffect(() => {
        switch (i18n.language) {
            case 'tr-TR':
            case 'tr':
                setLanguage(tr);
                break;
            case 'en-US':
            case 'en':
                setLanguage(enUS);
                break;
            default:
                setLanguage(tr);
        }
    }, []);

    const srmOptions = [
        {value: "general", label: t("general")},
        {label: t("challenge"), items: [
                {value: "challenge/content", label: t("contentCreation")},
                {value: "challenge/interaction", label: t("interaction")}
            ]
        },
        {label: t("survey"), items: [
                {value: "survey/single-choice", label: t("singleChoice")},
                {value: "survey/multiple-choice", label: t("multipleChoice")},
                {value: "survey/score", label: t("linearScale")},
                {value: "survey/likert/agreement", label: t("agreement")},
                {value: "survey/likert/frequency", label: t("frequency")},
                {value: "survey/likert/importance", label: t("importance")},
                {value: "survey/likert/interest", label: t("interest")},
            ]
        },
        {label: t("srm"), items: [/*
                {
                    value: "srm/campaigns", label: t("campaigns"), items: [
                        { value: "srm/campaigns/sotyCoefficient", label: t("sotyCoefficient") },
                        { value: "srm/campaigns/percentage", label: t("percentage") },
                        { value: "srm/campaigns/xShopping", label: t("xShopping") },
                        { value: "srm/campaigns/freeShipping", label: t("freeShipping") },
                        { value: "srm/campaigns/xProduct", label: t("xProduct") },
                        { value: "srm/campaigns/BuyxPayy", label: t("buyxPayy") }
                    ]
                },*/
                {value: "srm/sotier", label: t("sotier")},/*
                { value: "srm/customDay", label: t("customDay") },
                { value: "srm/corporate", label: t("corporate") },
                { value: "srm/segment", label: t("segment") },
                { value: "srm/contents", label: t("contents") },*/
            ]
        }
    ];

    const goBack = () => {
        navigate('/brand/analysis');
    }

    return (
        <div className='dashboard-container challenge-analysis-margin'>
            <div className="content-location-container analysis-location-container">
                <a
                    className="content-location-container-href"
                    onClick={goBack}
                    href="/brand/analysis"
                >
                    <img src={arrow} alt="arrow"/>
                </a>
                <a
                    className="content-location-container-link-text"
                    href="/brand/analysis"
                >
                    <div className="older">{t("analysis")}</div>
                </a>
                {/*{pageLabel && (*/}
                {/*    <>*/}
                {/*        <img src={downArrow} className="backArrow" alt="down-arrow"/>*/}
                {/*        <div className="current">{pageLabel}</div>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
            <div className='analysis-top-divider'/>
            <div className='challenge-analysis-top-controls-container'>
                <div className='challenge-analysis-top-buttons-container'>
                    <DatePickerButtonNew
                        locale={language}
                        dateRange={dateRange}
                        onDateRangeChange={setDateRange}
                        maxDate={endOfToday()}
                    />
                </div>
                <div className='challenge-analysis-title-container'>
                    <div className='challenge-analysis-title'>
                        {t("#analysisDashboard")}
                    </div>
                    <div className='challenge-analysis-description'>
                        {t("analysisDashboardDescription")}
                    </div>
                    <div className='challenge-analysis-description-logo-wrapper'>
                        <img
                            className='challenge-analysis-description-logo'
                            src={analysisIcon}
                            alt='anlys'
                        />
                    </div>
                </div>
            </div>
            <StaticDateRange
                locale={language}
                dateRange={dateRange}
                onDateRangeChange={setDateRange}
            />
            <div className='challenge-analysis-container'>
                <div className='challenge-analysis-channels-container'>
                    <div className='challenge-analysis-channels-title'>
                        {t("analysisChannels")}
                    </div>
                    <RecursiveMenu
                        items={srmOptions}
                    />
                </div>
                <Outlet context={{ language: language, dateRange: dateRange }}/>
            </div>
        </div>
    );
};

export default BrandAnalysis;
