import React from "react";

const SubBrandInfo = ({
  subBrands,
  checkImage,
  tick,
  imagesUrl,
  t,
  setSubBrandToDelete,
}) => {
  return (
    <div className="brand-admin-info-forced-row">
      {subBrands &&
        Array.isArray(subBrands) &&
        subBrands.map((brand, index) => (
          <div key={index} className="brand-admin-info-container">
            <div className="brand-admin-info-container-title-container">
              <img
                src={checkImage}
                alt="ico"
                className="brand-admin-info-container-title-img"
              />

              <div className="brand-admin-info-container-title">
                {t("subBrandInfo")}
              </div>
            </div>
            <div className="brand-admin-info-container-wrapper">
              <div className="brand-admin-info-container-row">
                <div className="brand-admin-input-container">
                  <div className="brand-admin-input-title">
                    {t("brandName")}:
                  </div>
                  <div className="brand-admin-input-area">
                    <input
                      id="brandName"
                      className="brand-admin-input"
                      type="text"
                      value={brand.name}
                      readOnly={true}
                    />
                    <img
                      src={tick}
                      alt="ico"
                      className={`brand-admin-input-img ${
                        brand.name !== "" ? "slide-in" : ""
                      }`}
                    />
                  </div>
                </div>

                <div className="brand-admin-input-container">
                  <div className="brand-admin-input-title">
                    {t("brandLogo")}:
                  </div>
                  <img
                    className="sub-brand-image-container"
                    src={imagesUrl + "/" + brand.logo}
                    alt="brandLogo"
                  />
                </div>
              </div>
              <button
                className="delete-sub-brand-button"
                onClick={() => {
                  setSubBrandToDelete(brand);
                }}
              >
                {t("delete")}
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SubBrandInfo;
