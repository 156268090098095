import React from "react";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg";

export const LoyaltyLevelInput = React.memo(
  ({ title, adornment, value, onChange, error, readOnly }) => {
    return (
      <div className="loyalty-level-input-container">
        {title && (
          <div className="loyalty-level-input-title">
            {title}
            <span className="red">*</span>{" "}
          </div>
        )}
        <div className={`loyalty-level-input-wrapper ${error ? "error" : ""}`}>
          {adornment && (
            <span className="loyalty-level-input-start-adornment">%</span>
          )}
          <input
            type="text"
            readOnly={readOnly}
            value={value}
            onChange={onChange}
            className="loyalty-level-input"
          />
        </div>
        {error && error !== true && (
          <div className="input-error-container">
            <img
              className="loyalty-level-info-icon"
              src={infoIconEmpty}
              alt="info:"
            />
            <div className="error-info-tooltip">{error}</div>
          </div>
        )}
      </div>
    );
  }
);
