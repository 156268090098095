import {
    differenceInDays, endOfDay, endOfToday, formatISO,
    startOfDay, startOfMonth,
    startOfToday,
} from 'date-fns';

export class DateRange {
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }

    static fromFormatted(formattedDateRange) {
        const splitted = formattedDateRange.split('|')
        const start = startOfDay(new Date(splitted[0]));
        const end = endOfDay(new Date(splitted[1]));
        return new DateRange(start, end);
    }

    static defaultDateRange() {
        const start = startOfMonth(startOfToday());
        const end = endOfToday();
        return new DateRange(start, end);
    }

    getFormatted(){
        return formatISO(this.start, {representation: 'date'})
            + '|'
            + formatISO(this.end, {representation: 'date'});
    }

    getFormattedStart() {
        return formatISO(this.start);
    }

    getFormattedEnd() {
        return formatISO(this.end);
    }

    differenceInDays() {
        return differenceInDays(this.end, this.start);
    }
}