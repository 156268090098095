import {imagesUrl} from "../../../../config";
import {format, fromUnixTime} from "date-fns";
import React from "react";
import AnalysisTable from "../../../tables/analysisTable";
import Pagination from "../../../tables/Pagination";
import ItemsPerPageButton from "../../../button/itemsPerPageButton";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

const ChallengeDetails = ({
                              locale,
                              tableData,
                              totalPage,
                              currentPage,
                              setCurrentPage,
                              setItemPerPage,
                       }) => {
    const { t} = useTranslation("analysis");
    const { search } = useLocation();
    const navigate = useNavigate();

    const tableColumns = [
        { title: t('challenge_id'), field: 0, className: "analysis-table-clickable-id" },
        { title: t('challenge_logo'), field: 1,
            render: (row) => {
                const logo_id = row[1];
                const image_src = imagesUrl + "/" + logo_id;
                return (<img src={image_src} alt="Logo"/>);
            }
        },
        { title: t('challenge_name'), field: 2 },
        { title: t('challenge_description'), field: 3 },
        { title: t('start_date'), field: 4,
            render: (row) => {
                const date = row[4];
                return format(fromUnixTime(date), "PPpp", {locale: locale});
            }
        },
        { title: t('end_date'), field: 5,
            render: (row) => {
                const date = row[5];
                return format(fromUnixTime(date), "PPpp", {locale: locale});
            }
        },
        { title: t('soty_coin'), field: 6 },
    ];

    return (
        <div className="analysis-sotier-container">
            <AnalysisTable
                columns={tableColumns}
                data={tableData}
                tableName={t('sotierList')}
                isRowClickable={() => true}
                onRowClick={(row) => navigate(row[0] + search)}
            />
            <div className="table-bottom-control-container" style={{ marginBottom: '300px' }}>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={(e) => { setCurrentPage(e) }}
                />
                <ItemsPerPageButton
                    onSelectItem={(e) => { setCurrentPage(1); setItemPerPage(e); }}
                    title={t("sotierPerPage")} />
            </div>
        </div>
    )
}

export default ChallengeDetails;