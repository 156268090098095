import React from "react";
import '../../assets/css/SortFilterSearch.css';
import { useTranslation } from "react-i18next";

const StaticDateRangeDialog = ({ options, onSelect }) => {
    const { t, i18n } = useTranslation("analysis");

    const handleClick = (value) => {
        onSelect(value);
    };

    return (
        <div className="static-date-range-dialog-container">
            {options.map(option => (
                <div key={option.value} className="static-date-range-dialog-item" onClick={() => handleClick(option)}>
                    {t(option.label)}
                </div>
            ))}
        </div>
    );
};

export default StaticDateRangeDialog;
