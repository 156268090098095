import qs from "qs";
import {apiUrl} from "../../config";
import {getCookie} from "../pages/auth/tokenUtils";
import axios from "axios";
import {toast} from "react-toastify";
import {fromUnixTime} from "date-fns";


async function fetchData(endpoint, params) {
    try {
        const queryString = qs.stringify(params);
        const URL = `${apiUrl}${endpoint}?${queryString}`;
        const accessToken = getCookie("access_token");
        const response = await axios.get(
            URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error.response.data.metadata.message);
    }
}

async function fetchChallengeKPIs(endpoint, brandUuid, dateRange){
    const params = {
        brand_uuid: brandUuid,
        start_date: dateRange.getFormattedStart(),
        end_date: dateRange.getFormattedEnd(),
    };
    return fetchData(endpoint, params).then((data) => {
        const kpiValues = data.responseData[0];
        return {
            total_soty_coin_earned: kpiValues[0],
            challenge_participation_count: kpiValues[1],
            challenge_completion_count: kpiValues[2],
            challenge_success_rate: kpiValues[3],
        };
    });
}

async function fetchChallengeKPIsByID(endpoint, brandUuid){
    const params = {
        brand_uuid: brandUuid
    };
    return fetchData(endpoint, params).then((data) => {
        const kpiValues = data.responseData[0];
        return {
            challenge_id: kpiValues[0],
            challenge_name: kpiValues[1],
            challenge_start_date: kpiValues[2],
            challenge_end_date: kpiValues[3],
            challenge_logo: kpiValues[4],
            total_soty_coin_earned: kpiValues[5],
            challenge_participation_count: kpiValues[6],
            challenge_completion_count: kpiValues[7],
            challenge_success_rate: kpiValues[8],
        };
    });
}

async function fetchSurveyKPIsByID(endpoint, brandUuid){
    const params = {
        brand_uuid: brandUuid
    };
    return fetchData(endpoint, params).then((data) => {
        const kpiValues = data.responseData[0];
        return {
            challenge_id: kpiValues[0],
            challenge_name: kpiValues[1],
            challenge_start_date: kpiValues[2],
            challenge_end_date: kpiValues[3],
            challenge_logo: kpiValues[4],
            total_soty_coin_earned: kpiValues[5],
            challenge_participation_count: kpiValues[6],
            challenge_completion_count: kpiValues[7],
            challenge_success_rate: kpiValues[8],
            survey_question_count: kpiValues[9],
        };
    });
}

async function fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage){
    const params = {
        brand_uuid: brandUuid,
        start_date: dateRange.getFormattedStart(),
        end_date: dateRange.getFormattedEnd(),
        page: currentPage - 1,
        size: itemPerPage,
    };
    return fetchData(endpoint, params);
}

async function fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage){
    const params = {
        brand_uuid: brandUuid,
        page: currentPage - 1,
        size: itemPerPage,
    };
    return fetchData(endpoint, params);
}

export const AnalysisApi = {
    sotyCoinEarningsByDateGroupedByCategory: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/coin/by-date/grouped-by-categories';
        const params = {
            brand_uuid: brandUuid,
            start_date: dateRange.getFormattedStart(),
            end_date: dateRange.getFormattedEnd(),
        }
        return fetchData(endpoint, params).then((data) => {
            const kpiValues = data.responseData[0];
            return {
                from_challenge: kpiValues[0],
                from_challenge_count: kpiValues[1],
                from_challenge_sotier: kpiValues[2],
                from_offer: kpiValues[3],
                from_offer_count: kpiValues[4],
                from_offer_sotier: kpiValues[5],
                from_gift: kpiValues[6],
                from_gift_count: kpiValues[7],
                from_gift_sotier: kpiValues[8],
                from_loyalty: kpiValues[9],
                from_loyalty_count: kpiValues[10],
                from_loyalty_sotier: kpiValues[11],
                total: kpiValues[12],
                total_count: kpiValues[13],
                total_sotier: kpiValues[14],
            };
        });
    },
    
    orderAmount: async function (brandUuid, dateRange, groupInterval) {
        const endpoint = '/api/v1/analysis/order/amount';
        const params = {
            brand_uuid: brandUuid,
            start_date: dateRange.getFormattedStart(),
            end_date: dateRange.getFormattedEnd(),
            group_interval: groupInterval,
            iana_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        return fetchData(endpoint, params).then((data) =>
            data.responseData.points.map((point) => {
                point[0] = fromUnixTime(point[0]);
                return point;
            })
        );
    },

    generalKPIs: async  function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/general/kpis';
        const params = {
            brand_uuid: brandUuid,
            start_date: dateRange.getFormattedStart(),
            end_date: dateRange.getFormattedEnd(),
        };
        return fetchData(endpoint, params).then((data) => {
            const kpiValues = data.responseData[0];
            return {
                total_sotier_count: kpiValues[0],
                total_soty_coin_earned: kpiValues[1],
                total_soty_coin_spent: kpiValues[2],
                total_soty_coin_net: kpiValues[3],
                total_order_count: kpiValues[4],
                total_order_refund_count: kpiValues[5],
                total_order_net_count: kpiValues[6],
                total_order_amount: kpiValues[7],
                total_order_refund_amount: kpiValues[8],
                total_order_net_amount: kpiValues[9],
                total_order_product_count: kpiValues[10],
                total_order_product_refund_count: kpiValues[11],
                total_order_product_net_count: kpiValues[12],
                average_basket_size: kpiValues[13],
                average_basket_amount: kpiValues[14],
                challenge_participation_count: kpiValues[15],
                challenge_completion_count: kpiValues[16],
                challenge_success_rate: kpiValues[17],
                offer_count: kpiValues[18],
                offer_accept_count: kpiValues[19],
                offer_success_rate: kpiValues[20],
            };
        });
    },

    contentCreationKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/content/${challengeId}/kpis`;
        return fetchChallengeKPIsByID(endpoint, brandUuid);
    },

    interactionKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/interaction/${challengeId}/kpis`;
        return fetchChallengeKPIsByID(endpoint, brandUuid);
    },

    surveyLikertAgreementKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/likert/agreement/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    surveyLikertFrequencyKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/likert/frequency/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    surveyLikertImportanceKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/likert/importance/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    surveyLikertInterestKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/likert/interest/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    surveySingleChoiceKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/single-choice/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    surveyMultipleChoiceKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/multiple-choice/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    surveyScoreKPIsByID: async function (brandUuid, challengeId) {
        const endpoint = `/api/v1/analysis/survey/score/${challengeId}/kpis`;
        return fetchSurveyKPIsByID(endpoint, brandUuid);
    },

    contentCreationKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/content/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    interactionKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/interaction/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveyLikertAgreementKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/likert/agreement/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveyLikertFrequencyKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/likert/frequency/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveyLikertImportanceKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/likert/importance/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveyLikertInterestKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/likert/interest/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveyMultipleChoiceKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/multiple-choice/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveySingleChoiceKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/single-choice/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    surveyScoreKPIs: async function (brandUuid, dateRange) {
        const endpoint = '/api/v1/analysis/survey/score/kpis';
        return fetchChallengeKPIs(endpoint, brandUuid, dateRange);
    },

    contentCreationDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/content/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    interactionDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/interaction/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveyLikertAgreementDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/likert/agreement/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveyLikertFrequencyDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/likert/frequency/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveyLikertImportanceDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/likert/importance/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveyLikertInterestDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/likert/interest/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveyScoreDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/score/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveySingleChoiceDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/single-choice/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    surveyMultipleChoiceDetails : async function (brandUuid, dateRange, currentPage, itemPerPage) {
        const endpoint = '/api/v1/analysis/survey/multiple-choice/details';
        return fetchChallengeDetails(endpoint, brandUuid, dateRange, currentPage, itemPerPage);
    },

    contentCreationDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/content/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    interactionDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/interaction/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveyLikertAgreementDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/likert/agreement/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveyLikertFrequencyDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/likert/frequency/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveyLikertImportanceDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/likert/importance/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveyLikertInterestDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/likert/interest/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveyScoreDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/score/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveySingleChoiceDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/single-choice/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },

    surveyMultipleChoiceDetailsByID : async function (brandUuid, challengeId, currentPage, itemPerPage) {
        const endpoint = `/api/v1/analysis/survey/multiple-choice/${challengeId}/details`;
        return fetchChallengeDetailsByID(endpoint, brandUuid, currentPage, itemPerPage);
    },
}