import React, { useState } from "react";
import InputContainer from "../../../../utils/inputContainer";
import { useTranslation } from "react-i18next";
import { LoyaltyLevelInput } from "./LoyaltyLevelInput";

const DefaultSotyCoinCampaign = ({
  firstLogin,
  DiscountPercentage,
  setDiscountPercentage,
}) => {
  const { t } = useTranslation("brandAdminProfile");
  const [value, setValue] = useState(DiscountPercentage || 10);
  const hasError = (value) => {
    if (value === "" || value === undefined || value < 10) {
      return t("valueCannotBeLessThan10"); // Error message if value is invalid
    } else {
      setDiscountPercentage(value);
    }
    return false; // No error
  };

  const submit = () => {};
  return (
    <InputContainer
      title={t("firstCampaign")}
      titleDescription={t("firstCampaignDescription")}
      isMandatory={true}
    >
      <LoyaltyLevelInput
        title={t("sotyCoin")}
        adornment="%"
        value={value}
        readOnly={!firstLogin}
        onChange={(e) => setValue(e.target.value)}
        error={hasError(value)}
      />
      {firstLogin && (
        <div className="form-button-container">
          <button
            className="brand-admin-input-area invoice-button"
            onClick={submit}
          >
            {t("save")}
          </button>
        </div>
      )}
    </InputContainer>
  );
};

export default DefaultSotyCoinCampaign;
