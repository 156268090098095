import React from "react";
import loyaltyBadge from "../../../../../assets/media/BsAwardFill.svg";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg";

const LoyaltyLevelBadge = ({ badgeCount }) => {
  return (
    <div className="loyalty-level-badge">
      <div className="loyalty-level-badge-count">{badgeCount}</div>
      <img className="loyalty-level-badge-icon" src={loyaltyBadge} />
    </div>
  );
};

export const LoyaltyLevelInfo = ({
  title,
  updateNotice,
  levelRetentionNotice,
}) => {
  return (
    <div className="loyalty-level-info-container">
      <img
        className="loyalty-level-info-icon"
        src={infoIconEmpty}
        alt="info:"
      />
      <div className="loyalty-level-info">
        {title}
        <br />
        <br />
        {updateNotice}
        <br />
        <br />
        {levelRetentionNotice}
      </div>
    </div>
  );
};
const LoyaltyLevelColumn = ({ children, type }) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="loyalty-level-column">
      {childrenArray.map((child, index) => (
        <React.Fragment key={index}>
          <div className="loyalty-level-column-part-long loyalty-level-column-part">
            {child}
          </div>
          {index < childrenArray.length - 1 && (
            <div
              className={`loyalty-level-column-part-short loyalty-level-column-part ${
                type === "line" ? "loyalty-level-column-part-short-color" : ""
              }`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export const LoyaltyLevelGradient = () => {
  return (
    <>
      <LoyaltyLevelColumn>
        <LoyaltyLevelBadge badgeCount="IV" />
        <LoyaltyLevelBadge badgeCount="III" />
        <LoyaltyLevelBadge badgeCount="II" />
        <LoyaltyLevelBadge badgeCount="I" />
      </LoyaltyLevelColumn>
      <LoyaltyLevelColumn type="line">
        <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-1" />
        <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-2" />
        <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-3" />
        <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-4" />
      </LoyaltyLevelColumn>
    </>
  );
};
