import React, { useState } from "react";
import { imagesUrl } from "../../../../../../config";
import "../../../../../../assets/css/InteractionType.css"; // Make sure to update the CSS file
import { useTranslation } from "react-i18next";
import ApproveNeededActionButton from "../../../../../button/approveNeededActionButton";

const InteractionType = ({
  apiData,
  onClick,
  onEditChallenge,
  isApproverApproves,
}) => {
  const { t } = useTranslation("challengeCreation");

  const formatDateString = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false, // 24-hour format
    };

    const inputDate = new Date(dateString);

    // Add 3 hours to the date
    const updatedDate = new Date(inputDate.getTime() + 3 * 60 * 60 * 1000);

    // Format the updated date and return the result
    return updatedDate.toLocaleDateString("tr-TR", options);
  };

  const editChallenge = () => {
    onEditChallenge();
  };

  return (
    <div className="interaction-type-preview-container">
      <div className="interaction-type-preview-logo-container">
        <div className="interaction-type-preview-brand-container">
          <img
            className="interaction-type-preview-brand-logo"
            src={imagesUrl + "/" + apiData.responseData.brand.logo}
            alt="image"
          />
          <div className="interaction-type-preview-challenge-Id">
            Challenge ID: {apiData.responseData.id}
          </div>
        </div>
        <div className="interaction-type-preview-challenge-logo-container">
          <img
            className="interaction-type-preview-challenge-logo"
            src={imagesUrl + "/" + apiData.responseData.logo}
            alt="image"
          />
        </div>
      </div>

      <div className="interaction-type-preview-challenge-data-container">
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("challengeName")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.name}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("challengeDescription")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.description}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("challengeType")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {t("Etkileşim Alma")}
          </div>
        </div>
        <hr />
        {apiData.responseData.segment && (
          <>
            <div className="interaction-type-preview-challenge-data-wrapper">
              <div className="interaction-type-preview-challenge-data-title">
                {t("segment")}:
              </div>
              <div className="interaction-type-preview-challenge-data">
                {apiData.responseData.segment}
              </div>
            </div>
            <hr />
          </>
        )}
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("contentTasks")}:
          </div>
        </div>
        {apiData.responseData.interactionChallengeRules.map((rule, index) => (
          <>
            <hr className="interaction-type-preview-rule-hr" />
            <div
              key={index}
              className="interaction-type-preview-challenge-data-wrapper"
            >
              <div className="interaction-type-preview-challenge-data-title">
                {rule.socialProviderCommandId === 2 ||
                rule.socialProviderCommandId === 4
                  ? "Yorum Yaz"
                  : rule.socialProviderCommandId === 1 ||
                    rule.socialProviderCommandId === 5
                  ? "Beğen"
                  : "Takip Et"}
              </div>
              <div className="interaction-type-preview-challenge-data">
                {rule.instruction && (
                  <div className="interaction-type-preview-challenge-data-rule-instruction">
                    {rule.instruction}
                  </div>
                )}
                {rule.info && (
                  <div className="interaction-type-preview-challenge-data-rule-info">
                    {rule.info}
                  </div>
                )}
              </div>
            </div>
          </>
        ))}

        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("startDate")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {formatDateString(apiData.responseData.startDate)}
          </div>
        </div>
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("endDate")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {formatDateString(apiData.responseData.endDate)}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("socialMediaChannel")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.socialProvider}
          </div>
        </div>
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("url")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            <a
              href={apiData.responseData.target}
              target="_blank"
              rel="noopener noreferrer"
            >
              {apiData.responseData.target}
            </a>
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("sotyCoin")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.prize +
              " " +
              t("sotyCoin") +
              " " +
              t("equalsTo") +
              " " +
              apiData.responseData.prize / 10 +
              " TL"}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-release-button-wrapper">
          <ApproveNeededActionButton
            title={t("publish")}
            onClick={onClick}
            isApproverApproves={isApproverApproves}
          />
        </div>
        <div className="interaction-type-preview-challenge-release-button-wrapper">
          <button
            className="interaction-type-preview-challenge-edit-button"
            onClick={() => {
              editChallenge();
            }}
          >
            {t("edit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InteractionType;
