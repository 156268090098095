import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/navbar.css";
import { useNavigate } from "react-router-dom";
import sotyLogo from "../../assets/media/sotylogo.svg";
import { useTranslation } from "react-i18next";
import settings from "../../assets/media/Icon ionic-ios-settings.svg";
import svgFileNames from "../utils/svgImporter";
import { isMyTokenExpired } from "../pages/auth/tokenUtils";
import { getCookie } from "../pages/auth/tokenUtils";
import { apiUrl, imagesUrl } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../popup/loader.js";
import SideBarNew from "../sidebar/SidebarNew.js";

const Navbar = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation("challengeCreation");
  const [showBox, setShowBox] = useState(false);
  const [hamburgerClicked, setHamburgerClicked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem("selectedCountry") || "browser-language");
  const [userSigned, setUserSigned] = useState(false);
  const [userType, setUserType] = useState(false);
  const [navbarHeight, setNavbarHeight] = useState("-1100px"); // Initially set to "auto"
  const clickHandled = useRef(false);
  const [nameSurname, setNameSurname] = useState();
  const [logo, setLogo] = useState();
  const [loaderState, setLoaderState] = useState(false);
  const [subBrands, setSubBrands] = useState([]);
  const jwtToken = getCookie("access_token");

  const handleImgClick = () => {
    setShowBox(!showBox);
  };

  useEffect(() => {
    if (!jwtToken) {
      console.log("JWT token is empty or undefined");
      setUserSigned(false);
      return;
    }
    if (isMyTokenExpired(jwtToken)) {
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }, [jwtToken]);

  const fetchUUID = async () => {
    const token = getCookie("access_token");
    const endpoint = apiUrl + "/api/v1/brand-admin/brand";
    try {
      setLoaderState(true);
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
    
      setSubBrands(response.data.responseData.subBrands);
      localStorage.setItem("subBrands", JSON.stringify(response.data.responseData.subBrands));

      setLoaderState(false);
      const newUUID = response.data.responseData.uuid;
      localStorage.setItem("UUID", newUUID);
      localStorage.setItem("brandLogo", response.data.responseData.logo);
      localStorage.setItem(
        "nameSurname",
        response.data.responseData.firstName +
          " " +
          response.data.responseData.lastName
      );
      setLogo(response.data.responseData.logo);
      setNameSurname(
        response.data.responseData.firstName +
          " " +
          response.data.responseData.lastName
      );
    } catch (error) {
      setLoaderState(false);
      toast.error(error.message);
      console.error("Error fetching data:", error);
    }
  };

  const handleLanguageChange = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
  };

  const extractCountryNameWithoutSpace = (filePath) => {
    return extractCountryName(filePath)
        .toLowerCase()
        .replaceAll(" ", "");
  }

  const extractCountryName = (filePath) => {
    const pathParts = filePath.split("/");
    const fileName = pathParts[pathParts.length - 1]; // Get the last part after the last slash
    const countryName = fileName.split(".")[0]; // Get the part before the dot

    // Use a regular expression to insert a space before each uppercase letter
    const formattedCountryName = countryName.replace(/([A-Z])/g, " $1").trim();
    return formattedCountryName;
  };

  const getLanguageCode = (countryNameWithoutSpace) => {
    switch (countryNameWithoutSpace) {
      case "türkçe":
        return "tr-TR";
      case "english":
        return "en-US";
      case "spain":
        return "es-ES";
      // Add more cases for other countries as needed
      default:
        return "tr-TR"; // Default to Turkish if no match
    }
  };

  const handleCountrySelect = (svgFileName) => {
    // Extract the country name from the SVG file
    const countryNameWithoutSpace = extractCountryNameWithoutSpace(svgFileName);

    localStorage.setItem("selectedCountry", countryNameWithoutSpace);
    setSelectedCountry(countryNameWithoutSpace);

    // Get the language code based on the country name
    const languageCode = getLanguageCode(countryNameWithoutSpace);

    // Update the language based on the selected country
    handleLanguageChange(languageCode);
    setShowBox(!showBox);
    window.location.reload();
  };

  useEffect(() => {
    if (!jwtToken) {
      setUserSigned(false);
      return;
    }

    const tokenParts = jwtToken.split(".");
    if (tokenParts.length !== 3) {
      return;
    }

    const decodedPayload = JSON.parse(atob(tokenParts[1]));

    if (decodedPayload.azp === "brand-client") {
      setUserType(true);
      setUserSigned(true);
      fetchUUID();
    } else if (decodedPayload.azp === "admin-client") {
      setUserType(false);
      setUserSigned(true);
    } else {
      console.log('User has an unknown "azp" value');
    }
  }, [jwtToken]);

  const handleHamburgerClick = () => {
    if (!clickHandled.current) {
      setHamburgerClicked((prevClicked) => !prevClicked);
      setNavbarHeight((prevHeight) =>
        prevHeight === "-1100px" ? "0" : "-1100px"
      );
      clickHandled.current = true;

      setTimeout(() => {
        clickHandled.current = false;
      }, 500);
    }
  };

  const handleBrowserLanguageSelect = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const split = browserLanguage.split("-");
    const language = split[0].toLowerCase();
    const country = split.length > 1 ? split[1].toUpperCase() : "";
    const formattedLanguage = country === "" ? language : language + '-' + country;
    handleLanguageChange(formattedLanguage);
    localStorage.setItem("selectedCountry", "browser-language");
    setSelectedCountry("browser-language");
    window.location.reload();
  };

  return userSigned ? (
    <>
      {loaderState && <Loader />}
      <nav className="navbar">
        <div className="Navbar-Left Navbar-Logo-Container">
          <img src={sotyLogo} alt="soty" />
        </div>
        <div className="Navbar-Right Navbar-Right-Items">
          <div className="Navbar-Icons">
            <div className="Navbar-Settings">
              <img
                src={settings}
                alt="Settings"
                onClick={handleImgClick}
                style={{ cursor: "pointer" }}
              />
              {showBox && (
                <div className="positioned-box">
                  {svgFileNames.map((svgFileName, index) => {
                    const countryName = extractCountryNameWithoutSpace(svgFileName);
                    console.log(countryName);
                    console.log(selectedCountry);
                    const isSelected= countryName === selectedCountry;
                    return countryName === "türkçe" ||
                      countryName === "english" ? (
                      <div
                        key={index}
                        className={`settings-language-row ${
                          isSelected ? "selected-country" : ""
                        }`}
                        onClick={() => handleCountrySelect(svgFileName)}
                      >
                        <img
                          src={svgFileName}
                          alt={`SVG ${index + 1}`}
                          loading="lazy"
                        />
                        <div>{extractCountryName(svgFileName)}</div>
                      </div>
                    ) : null;
                  })}
                  <div
                    key="browser-language"
                    className={`settings-language-row ${
                        selectedCountry === "browser-language"
                        ? "selected-country"
                        : ""
                    }`}
                    onClick={() => handleBrowserLanguageSelect()}
                  >
                    <div>{t("browserLanguage")}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="Navbar-Profile">
            <div className="Navbar-Profile-User">
              <div className="Navbar-Profile-Credentials">
                <div className="Navbar-Profile-Username">
                  {nameSurname && nameSurname}
                </div>
                <div className="Navbar-Profile-Details" />
              </div>
              {userType && (
                <>
                  {subBrands &&
                    Array.isArray(subBrands) &&
                    subBrands.map((brand, index) => (
                      <img
                        className="Navbar-Profile-Picture-sub"
                        src={brand && imagesUrl + "/" + brand.logo}
                        alt="Profile"
                        key={index}
                      />
                    ))}
                  <img
                    className="Navbar-Profile-Picture"
                    src={logo && imagesUrl + "/" + logo}
                    alt="Profile"
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="navbar-mobile navbar-mobile-right"
          onClick={(e) => handleHamburgerClick(e)}
        >
          <label className="hamburger">
            <input type="checkbox" />
            <svg viewBox="0 0 32 32">
              <path
                className="line line-top-bottom"
                d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
              ></path>
              <path className="line" d="M7 16 27 16"></path>
            </svg>
          </label>
        </div>
      </nav>
      <div className="mobile-navbar-curtain" style={{ top: navbarHeight }}>
        <div className="mobile-navbar-container">
          <SideBarNew mobile={true} />
        </div>
      </div>
    </>
  ) : null;
};

export default Navbar;
