import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../assets/css/DynamicTable.css";
import downArrow from "../../assets/media/down-arrow.png";
import FilterButton from "../button/filterButton";
import CreateChallengeButton from "../button/createChallengeButton";
import Pagination from "../tables/Pagination";
import ItemsPerPageButton from "../button/itemsPerPageButton";
import FormatDate from "../utils/formatDate";

const parseSuccessRate = (successRateString) => {
  if (!successRateString) return 0;
  return parseFloat(successRateString.replace("%", ""));
};

const DynamicTableWithCustomInners = ({
  columns,
  paginationDisabled,
  customButtonDisabled,
  data,
  tableName,
  children,
  customButtonText,
  isRowClickable,
  onRowClick,
  corporateCampaign,
  itemPage,
  itemTotalPage,
  setItemPage,
  handleInputChange,
  itemPerPageTitle,
  setItemPerPage,
  defaultSort,
  sortDisabled,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: defaultSort?.key || null,
    direction: defaultSort?.direction || "asc",
    customSortKey: null,
  });

  useEffect(() => {
    // Varsayılan sıralama ayarını uygulama
    if (defaultSort) {
      setSortConfig((prevConfig) => ({
        ...prevConfig,
        key: defaultSort.key,
        direction: defaultSort.direction,
      }));
    }
  }, [defaultSort]);

  const handleSort = (key, customSortKey = null) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction, customSortKey });
  };

  const isISODateFormat = (dateString) => {
    const isoDateRegex =
      /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([+-]\d{2}:\d{2}|Z)?)$/;
    return isoDateRegex.test(dateString);
  };

  const parseDate = (dateString) => {
    if (!dateString) return 0;

    if (isISODateFormat(dateString)) {
      return new Date(dateString).getTime();
    }

    if (typeof dateString === "string") {
      const dateParts = dateString.split(" ");
      const [day, month, year] = dateParts[0].split(".").map(Number);
      const [hour, minute, second] = dateParts[1]
        ? dateParts[1].split(":").map(Number)
        : [0, 0, 0];
      return new Date(year, month - 1, day, hour, minute, second).getTime();
    }

    return 0;
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.customSortKey) {
      if (sortConfig.customSortKey === "user") {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return sortConfig.direction === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }
      // Add other custom sort keys if needed
    } else if (sortConfig.key) {
      if (sortConfig.key === "successRate") {
        const rateA = parseSuccessRate(a[sortConfig.key]);
        const rateB = parseSuccessRate(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? rateA - rateB : rateB - rateA;
      } else if (
        sortConfig.key === "creationDate" ||
        sortConfig.key === "startDate" ||
        sortConfig.key === "endDate"
      ) {
        const dateA = parseDate(a[sortConfig.key]);
        const dateB = parseDate(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      } else if (sortConfig.key === "id") {
        const valueA = parseInt(a[sortConfig.key], 10);
        const valueB = parseInt(b[sortConfig.key], 10);
        return sortConfig.direction === "asc"
          ? valueA - valueB
          : valueB - valueA;
      } else {
        const valueA =
          sortConfig.key === "Status" && a[sortConfig.key]?.props?.children
            ? a[sortConfig.key].props.children
            : a[sortConfig.key];
        const valueB =
          sortConfig.key === "Status" && b[sortConfig.key]?.props?.children
            ? b[sortConfig.key].props.children
            : b[sortConfig.key];

        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortConfig.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          return sortConfig.direction === "asc"
            ? valueA - valueB
            : valueB - valueA;
        }
      }
    }
    return 0;
  });

  const dataWithPercentSymbol = sortedData.map((item) => ({
    ...item,
    successRate: item.successRate ? item.successRate + "%" : "",
  }));

  return (
    <div className="dynamic-table-container table-container-min-height">
      <div className="dynamic-table-inner-element-container">
        <div className="dynamic-table-inner-element-container-left">
          <h2 className="dynamic-table-name">{tableName}</h2>
        </div>
        <div className="dynamic-table-inner-element-container-right">
          {!paginationDisabled && (
            <>
              <Pagination
                currentPage={itemPage + 1 || null}
                totalPages={itemTotalPage}
                onPageChange={(page) => setItemPage(page - 1)}
              />
              <ItemsPerPageButton
                onSelectItem={(e) => handleInputChange(e, setItemPerPage)}
                title={itemPerPageTitle}
              />
            </>
          )}
          {children}
          {!customButtonDisabled && (
            <CreateChallengeButton
              pushNotification={true}
              customText={customButtonText}
            />
          )}
        </div>
      </div>

      <table className="dynamic-table">
        <thead>
          <tr className="complaint-row">
            {columns.map((column, index) => (
              <th key={index} className={`dynamic-th ${column.className}`}>
                <div className="dynamic-th-wrapper">
                  {column.title}
                  {!(column.field === "unscrollable" || sortDisabled) && (
                    <div className="sort-buttons">
                      <button
                        onClick={() =>
                          handleSort(column.field, column.customSortKey)
                        }
                      >
                        <img
                          className="dyanmic-sort-asc"
                          src={downArrow}
                          alt="Sort ascending"
                        />
                      </button>
                      <button
                        onClick={() =>
                          handleSort(column.field, column.customSortKey)
                        }
                      >
                        <img
                          className="dyanmic-sort-desc"
                          src={downArrow}
                          alt="Sort descending"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataWithPercentSymbol.map((item, index) => (
            <tr
              key={index}
              className={`dynamic-table-row ${
                typeof isRowClickable === "function" && isRowClickable(item)
                  ? "clickable-row"
                  : ""
              }`}
              onClick={isRowClickable ? () => onRowClick(item) : undefined}
            >
              {columns.map((column, index) => (
                <td key={index} className={`dynamic-td ${column.className}`}>
                  {column.render ? (
                    column.render(item)
                  ) : typeof item[column.field] === "string" &&
                    item[column.field].includes(" link ") ? (
                    item[column.field].split(" link ")[1].startsWith("http") ? (
                      <span>
                        {item[column.field].split(" link ")[0]}{" "}
                        <a
                          href={
                            "https://" + item[column.field].split(" link ")[1]
                          }
                        >
                          {"(" +
                            item[column.field].split(" link ")[1].substring(8) +
                            ")"}
                        </a>
                      </span>
                    ) : (
                      item[column.field]
                    )
                  ) : isISODateFormat(item[column.field]) ? (
                    FormatDate(item[column.field])
                  ) : (
                    item[column.field]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DynamicTableWithCustomInners.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  children: PropTypes.node,
  isRowClickable: PropTypes.func,
  onRowClick: PropTypes.func,
  itemPage: PropTypes.number,
  itemTotalPage: PropTypes.number,
  setItemPage: PropTypes.func,
  handleInputChange: PropTypes.func,
  itemPerPageTitle: PropTypes.string,
  setItemPerPage: PropTypes.func,
  defaultSort: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["asc", "desc"]),
  }),
};

export default DynamicTableWithCustomInners;
