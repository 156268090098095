import React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import ContentCreationByIDKPIs from "../../../../../fragments/challenge/kpis/byID/contentCreationByIDKPIs";
import ContentCreationByIDDetails from "../../../../../fragments/challenge/details/byID/contentCreationByIDDetails";

const ContentCreationByIDAnalysis = () => {

    const { challengeId } = useParams();
    const { language,dateRange } = useOutletContext();

    return (
        <div className='challenge-analysis-charts-container'>
            <ContentCreationByIDKPIs
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                dateRange={dateRange}
                challengeId={challengeId}
            />
            <hr/>
            <ContentCreationByIDDetails
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                challengeId={challengeId}
            />
        </div>
    )
};

export default ContentCreationByIDAnalysis;