import React, { useState, useEffect } from "react";
import infoIconEmpty from "../../../../../../assets/media/infoIconEmpty.svg";
import ChallengeImageDropzone from "../../../../../dropzone/ChallengeImageDropzone";
import "../../../../../../assets/css/InteractionCreation.css";
import { getCookie } from "../../../../auth/tokenUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { isMyTokenExpired } from "../../../../auth/tokenUtils";
import tick from "../../../../../../assets/media/tickInteraction.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl, imagesUrl } from "../../../../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import noImageToShow from "../../../../../../assets/media/placeholder.png";
import PhoneViewJoin from "../../../../../phone/phoneViewJoin";
import Loader from "../../../../../popup/loader";
import CallAPI from "../../../../../api/callApi";
import InputContainer from "../../../../../utils/inputContainer";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import ApprovalPopup from "../../../../../popup/approvalPopup";
import { CampaignNotificationTimeSelector } from "../../components/CampaignNotificationTimeSelector";
import BuyXPayY from "../../components/CampaignBuyXPayY";
import CampaignChannelType from "../../components/CampaignChannelType";
import CampaignType from "../../components/CampaignType";
import BuyMorePayLess from "../../components/CampaignBuyMorePayLess";
import DiscountPercentage from "../../components/CampaignDiscountPercentage";
import CouponPercentage from "../../components/CampaignCouponPercentage";
import SpendAndEarn from "../../components/CampaignSpendAndEarn";
import ParticipationAmount from "../../components/CampaignParticipationAmount";
import NotificationPreferences from "../../components/CampaignNotificationPreferences";
import CoinFolding from "../../components/CampaignCoinFolding";
import SegmentList from "../../components/SegmentList";
const CampaignCreation = () => {
  const [loaderState, setLoaderState] = useState(false);
  const { t } = useTranslation("challengeCreation");

  const location = useLocation();
  const { returnedCampaign } = location.state || {};
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [popupSegment, setPopupSegment] = useState(false);

  const [challengeImage, setChallengeImage] = useState(null);

  const coinFoldingOptions = [2, 3, 4, 5];
  const couponOptions = [5, 10, 15, 20, 30];
  const discountOptions = [5, 10, 15, 20, 30];
  const buyXPayYOptions = [
    { buy: 2, pay: 1 },
    { buy: 3, pay: 2 },
    { buy: 4, pay: 3 },
  ];

  const [segments, setSegments] = useState(false);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentTotalPage, setSegmentTotalPage] = useState(false);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  //validations

  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);

  //datas to send
  const [imageResponseString, setImageResponseString] = useState("");
  const [challengeName, setChallengeName] = useState("");
  const [challengeDescription, setChallengeDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTimeOption, setSelectedTimeOption] = useState("NOT_NOTIFY");
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [online, setOnline] = useState(false);
  const [shop, setShop] = useState(false);
  const [sendInApp, setSendInApp] = useState(true);
  const [sendSms, setSendSms] = useState(false);
  const [sendMail, setSendMail] = useState(false);

  const [campaignType, setCampaignType] = useState("COIN_FOLDING");
  const [discounts, setDiscounts] = useState([0]);
  const [buyCount, setBuyCount] = useState(false);
  const [payCount, setPayCount] = useState(false);
  const [percentDiscount, setPercentDiscount] = useState(false);
  const [percentCoupon, setPercentCoupon] = useState(false);
  const [selectedCoinFolding, setSelectedCoinFolding] = useState(false);
  const [xPrice, setXPrice] = useState(false);
  const [yCoin, setYCoin] = useState(false);
  const [minimumSpending, setMinimumSpending] = useState(0);
  const [singleParticipate, setSingleParticipate] = useState(false);

  const campaignOptions = [
    {
      type: "COIN_FOLDING",
      title: t("sotyCoinFolding"),
      onClick: () => {
        !returnedCampaign?.campaignType && setCampaignType("COIN_FOLDING");
      },
    },
    {
      type: "DISCOUNT_PERCENTAGE",
      title: t("givePercentageDiscount"),
      onClick: () => {
        !returnedCampaign?.campaignType &&
          setCampaignType("DISCOUNT_PERCENTAGE");
      },
    },
    {
      type: "FREE_SHIPMENT",
      title: t("freeShipping"),
      onClick: () => {
        !returnedCampaign?.campaignType &&
          online &&
          !shop &&
          setCampaignType("FREE_SHIPMENT");
      },
      disabled: !(online && !shop),
    },
    {
      type: "SPEND_AND_EARN",
      title: t("ySotyCoinForEveryXTlPurchase"),
      onClick: () => setCampaignType("SPEND_AND_EARN"),
    },
    {
      type: "COUPON_PERCENTAGE",
      title: t("percentageCoupon"),
      onClick: () => {
        !returnedCampaign?.campaignType && setCampaignType("COUPON_PERCENTAGE");
      },
    },
    {
      type: "BUY_MORE_PAY_LESS",
      title: t("percentageDiscountOnXItems"),
      onClick: () => {
        !returnedCampaign?.campaignType && setCampaignType("BUY_MORE_PAY_LESS");
      },
    },
    {
      type: "BUY_X_PAY_Y",
      title: t("buyXPayNow"),
      onClick: () => {
        !returnedCampaign?.campaignType && setCampaignType("BUY_X_PAY_Y");
      },
    },
  ];

  useEffect(() => {
    setPercentCoupon(false);
    setPercentDiscount(false);
    setSelectedCoinFolding(false);
    setXPrice(false);
    setYCoin(false);
    setDiscounts([0]);
    setBuyCount(false);
    setPayCount(false);
  }, [campaignType]);

  useEffect(() => {
    handleFetchSegments();
  }, []);
  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);

  useEffect(() => {
    if (returnedCampaign) {
      const responseData = returnedCampaign.responseData || {};
      setSingleParticipate(responseData.singleParticipate || "");
      setImageResponseString(responseData.image || "");
      setSendSms(responseData.smsNotificationEnabled);
      setSendMail(responseData.emailNotificationEnabled);
      setSendInApp(responseData.inAppNotificationEnabled);
      setChallengeName(responseData.name || "");
      setChallengeImage(imagesUrl + "/" + responseData.image);
      setChallengeDescription(responseData.description || "");
      setSelectedTimeOption(responseData.notificationTime);

      setStartDate(
        moment.utc(responseData.startDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setEndDate(
        moment.utc(responseData.endDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setCampaignType(responseData.campaignType);

      if (responseData.campaignType === "BUY_X_PAY_Y") {
        setBuyCount(responseData.buyCount);
        setPayCount(responseData.payCount);
      } else if (responseData.campaignType === "COIN_FOLDING") {
        setSelectedCoinFolding(responseData.foldingFactor);
      } else if (responseData.campaignType === "BUY_MORE_PAY_LESS") {
        setDiscounts(responseData.discounts);
      } else if (responseData.campaignType === "DISCOUNT_PERCENTAGE") {
        setPercentDiscount(responseData.discountPercentage);
      } else if (responseData.campaignType === "COUPON_PERCENTAGE") {
        setPercentCoupon(responseData.couponRatio);
      } else if (responseData.campaignType === "SPEND_AND_EARN") {
        setXPrice(responseData.spend);
        setYCoin(responseData.earn);
      }

      //store ve online ikisi ayni anda secilebiliyo
      if (responseData.shoppingChannel === "ALL") {
        setShop(!shop);
        setOnline(!online);
      } else if (responseData.shoppingChannel === "STORE") {
        setShop(!shop);
      } else if (responseData.shoppingChannel === "ONLINE") {
        setOnline(!online);
      }
      if (responseData.minimumSpending) {
        setMinimumSpending(responseData.minimumSpending);
      }
    }
  }, [returnedCampaign]);

  const challengeNameChange = (e) => {
    setChallengeName(e.target.value);
  };

  const handleInputChange = (value, setter) => {
    setter(value);
  };

  const handleImageUpload = async (file, isChallengeImage) => {
    setLoaderState(true);

    try {
      // Get the access token from the cookie
      const authToken = getCookie("access_token");

      // Create a FormData object to send the file
      const formData = new FormData();

      // Convert Blob URL to Blob data
      const response = await fetch(file);
      const blobData = await response.blob();

      // Append Blob data to FormData
      formData.append("file", blobData);

      // Determine the URL based on whether it's a challenge or a question image
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo"; // Replace with the actual URL

      // Determine the state and toast message based on whether it's a challenge or a question image
      const stateToUpdate = isChallengeImage && setImageResponseString;
      const successMessage =
        isChallengeImage && t("success.uploadChallengeImage");

      // Use Axios to send the POST request to upload the file
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      stateToUpdate(uploadResponse.data.responseData);

      // İşlem başarıyla tamamlandığında state'i güncelleyebilirsiniz
      setChallengeImage(file);
      setLoaderState(false);

      toast.success(successMessage);
    } catch (error) {
      setLoaderState(false);

      console.error("Dosya yüklenirken hata oluştu:", error);
      setChallengeImage("");
      toast.error(
        t("error.fileUpload") +
          "(" +
          error.response?.data?.metadata?.message +
          ")"
      );
    }
  };

  const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET", // HTTP isteği türü
      endPoint:
        apiUrl +
        `/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${segmentPerPage}`, // API URL'si
      body: undefined, // İstek gövdesi
      successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    setLoaderState(false);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);

    // allSotiers'ı en başa ekleyelim
    const updatedSegments = [
      {
        id: 0,
        name: t("allSotiers"),
        description: t("segmentForAllUsers"),
        expressionText: `=${t("allSotiers")}`,
      },
      ...segmentResponse.data.responseData,
    ];

    setSegments(updatedSegments);
  };

  const startDateChange = (e) => {
    const startDate = e.target.value;
    if (startDate === "") {
      toast.error(t("error.missingStartDate"));
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedStartDate = new Date(startDate).toISOString().slice(0, 19);
    const selectedStartDate = new Date(startDate);
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    if (selectedStartDate < now) {
      toast.error(t("error.invalidStartDate"));
      e.target.value = "";
      handleInputChange("", setStartDate, "startDate");
      return;
    } else {
      setStartDate(formattedStartDate);
      handleInputChange(formattedStartDate, setStartDate, "startDate");
    }
  };

  const endDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate === "") {
      toast.error(t("error.missingEndDate"));
      handleInputChange("", setEndDate, "endDate");
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedEndDate = new Date(newEndDate).toISOString().slice(0, 19);
    if (new Date(formattedEndDate) < new Date(startDate)) {
      toast.error(t("error.invalidEndDate"));
      e.target.value = "";
      handleInputChange("", setEndDate, "endDate");
    } else {
      setEndDate(formattedEndDate);
      handleInputChange(formattedEndDate, setEndDate, "endDate");
    }
  };

  const handleCampaignUpload = async (previewBool) => {
    if (sendSms === false && sendMail === false && sendInApp === false) {
      toast.error(t("error.anySendingTypeSelected"));
      return;
    }
    if (selectedSegment === undefined) {
      toast.error(t("error.anySegmentSelected"));
      return;
    }
    if (!online && !shop) {
      toast.error(t("error.anyShoppingChannelSelected"));
      return;
    }
    let shoppingChannel;
    if (shop && online) {
      shoppingChannel = "ALL";
    } else if (online) {
      shoppingChannel = "ONLINE";
    } else if (shop) {
      shoppingChannel = "STORE";
    }

    if (!campaignType) {
      toast.error(t("error.anyCampaignSelected"));
      return;
    }

    if (campaignType === "FREE_SHIPMENT") {
      if (
        minimumSpending === undefined ||
        minimumSpending === null ||
        minimumSpending === false
      ) {
        setMinimumSpending(0);
      }
    }

    let discountsArray;
    if (campaignType === "BUY_MORE_PAY_LESS") {
      // Update discounts to replace empty strings with 0
      const updatedDiscounts = discounts.map((discount) =>
        discount === "" ? 0 : discount
      );

      if (
        updatedDiscounts.every(
          (discount) =>
            discount === 0 || discount === "0" || discount === undefined
        )
      ) {
        toast.error(t("please_enter_at_least_one_discount"));
        return;
      }

      discountsArray = updatedDiscounts;
    }

    if (campaignType === "BUY_X_PAY_Y") {
      if (!buyCount) {
        toast.error(t("PaymentItemCountError"));
        return;
      }
      if (!payCount) {
        toast.error(t("ItemPurchaseQuantityError"));
        return;
      }
    }

    if (campaignType === "COIN_FOLDING") {
      if (!selectedCoinFolding) {
        toast.error(t("error.anyFoldingOptionSelected"));
        return;
      }
    }
    if (campaignType === "DISCOUNT_PERCENTAGE") {
      if (!percentDiscount) {
        toast.error(t("error.anyPercentOptionSelected"));
        return;
      }
    }
    if (campaignType === "COUPON_PERCENTAGE") {
      if (!percentCoupon) {
        toast.error(t("error.anyPercentOptionSelected"));
        return;
      }
    }

    let spend;
    let earn;
    if (campaignType === "BUY_X_PAY_Y") {
      if ((!buyCount || buyCount === "") && (!payCount || payCount === "")) {
        toast.error(t("xPriceYCoinMissing")); //eksik kodla dili degistir localization
        return;
      }
      spend = buyCount;
      earn = payCount;
    }

    if (!startDate) {
      setIsStartDateValid(false);
      toast.error(t("error.missingStartDate"));
      return;
    }
    setIsStartDateValid(true);

    if (!endDate) {
      setIsEndDateValid(false);
      toast.error(t("error.missingEndDate"));
      return;
    }
    setIsEndDateValid(true);

    const startTime = new Date(startDate);
    const endTime = new Date(endDate);
    const timeDifference = endTime - startTime;

    if (timeDifference < 60000) {
      toast.error(t("error.invalidTimeRange"));
      return;
    }

    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(authToken)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    const rawCampaignData = {
      image: imageResponseString,
      name: challengeName,
      description: challengeDescription,
      startDate: startDate,
      endDate: endDate,
      foldingFactor: selectedCoinFolding,
      campaignType: campaignType,
      shoppingChannel: shoppingChannel,
      minimumSpending:
        campaignType === "FREE_SHIPMENT"
          ? Number(minimumSpending || 0)
          : Number(minimumSpending),
      discountPercentage: percentDiscount,
      segment: selectedSegment === 0 ? null : selectedSegment,
      couponRatio: percentCoupon,
      earn: earn,
      spend: spend,
      payCount: payCount,
      buyCount: buyCount,
      freeShipping: campaignType === "FREE_SHIPMENT",
      discounts: discountsArray,
      combinable: false,
      inAppNotificationEnabled: sendInApp,
      emailNotificationEnabled: sendMail,
      smsNotificationEnabled: sendSms,
      notificationTime: selectedTimeOption,
    };

    // Yalnızca dolu değerleri tutan bir nesne oluşturun
    const campaignData = Object.fromEntries(
      Object.entries(rawCampaignData).filter(
        ([_, value]) =>
          value !== undefined &&
          value !== null &&
          value !== false &&
          value !== ""
      )
    );
    if (returnedCampaign) {
      setLoaderState(true);

      const endPoint =
        apiUrl +
        "/api/v1/brand-admin/brand/campaign/" +
        returnedCampaign.responseData.id;
      axios
        .put(endPoint, campaignData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.updateCampaign")); // Başarı mesajı göster

            if (previewBool) {
              navigate("/brand/srm/campaign/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/srm/campaign");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          toast.error(
            t("error.updateCampaign") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    } else {
      setLoaderState(true);
      const endPoint = apiUrl + "/api/v1/brand-admin/brand/campaign";
      axios
        .post(endPoint, campaignData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.CreateCampaign")); // Başarı mesajı göster

            if (previewBool) {
              navigate("/brand/srm/campaign/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/challenges");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          toast.error(
            t("error.CreateCampaign") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    }
  };

  // Handler function to update discounts
  const handleDiscountChange = (index, value) => {
    const newDiscounts = [...discounts];
    newDiscounts[index] = value;
    setDiscounts(newDiscounts);
  };

  // Handler function to add a new discount input
  const handleAddDiscount = () => {
    setDiscounts([...discounts, 0]); // Yeni bir discount ekleyerek array'i genişlet
  };
  // Handler function to delete a discount
  const handleDeleteDiscount = (indexToRemove) => {
    setDiscounts(discounts.filter((_, index) => index !== indexToRemove));
  };

  const handleSegmentPopup = (segment) => {
    setPopupSegment(segment);
    setPopup(true);
  };

  const locationArray = [
    [t("campaigns"), "/brand/srm/campaign"],
    [t("createCampaign"), "/brand/srm/campaign/create"],
  ];

  return (
    <div className="dashboard-container">
      {popup && (
        <ApprovalPopup
          questionText={t("areYouSureToDeleteSegment")}
          cancelText={t("cancel")}
          approveText={t("delete")}
          onClose={() => {
            setPopup(false);
          }}
          isDeleteApprove={false}
          onCancelButtonClick={() => setPopup(false)}
          approvalObject={popupSegment}
        />
      )}

      <NavigationBar locationArray={locationArray} />

      {loaderState && <Loader />}
      <div className="dashboard-row">
        <div className="challenge-creation-content-container">
          {/* KAMPANYA GORSELI */}
          <div className="challenge-creation-challenge-img-container">
            <div className="challenge-creation-challenge-segment-title">
              {t("CampaignImage")}
            </div>

            <ChallengeImageDropzone
              onImageUpload={(file) => handleImageUpload(file, true)}
              defaultImageUrl={
                returnedCampaign
                  ? imagesUrl + "/" + returnedCampaign.responseData.image
                  : ""
              }
            />
            <label>{t("allowedFileTypes")} png, jpg</label>
          </div>

          {/* KAMPANYA ADI */}
          <InputContainer
            title={t("CampaignName")}
            isMandatory={true}
            titleDescription={t("enterChallengeTitle")}
          >
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-title-container">
                <input
                  className="challenge-title"
                  placeholder={t("enterCampaignTitlePlaceHolder")}
                  defaultValue={challengeName}
                  maxLength="60"
                  value={challengeName}
                  onBlur={challengeNameChange}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      setChallengeName,
                      "challengeName"
                    )
                  }
                />
                <div className="challenge-title-info-container">
                  <img
                    className="challenge-title-info-icon"
                    src={infoIconEmpty}
                    alt="info:"
                  />
                  <div className="challenge-title-info-text">
                    {t("min")} 10, {t("max")} 60 {t("character")}
                  </div>
                </div>
              </div>
            </div>
          </InputContainer>

          {/* KAMPANYA ACIKLAMA */}
          <InputContainer
            title={t("campaignDescription")}
            isMandatory={true}
            titleDescription={t("enterCampaignDescription")}
          >
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-title-container">
                <textarea
                  className="challenge-title"
                  placeholder={t("enterCampaignDescriptionPlaceholder")}
                  maxLength="511"
                  defaultValue={challengeDescription}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      setChallengeDescription,
                      "campaignDescription"
                    )
                  }
                />
                <div className="challenge-title-info-container">
                  <img
                    className="challenge-title-info-icon"
                    src={infoIconEmpty}
                    alt="info:"
                  />
                  <div className="challenge-title-info-text">
                    {t("min")} 20, {t("max")} 512 {t("character")}
                  </div>
                </div>
              </div>
            </div>
          </InputContainer>

          {/* SEGMENT LISTESI */}
          <InputContainer
            title={t("SegmentList")}
            isMandatory={true}
            titleDescription={t("SegmentListSubtitle")}
          >
            <SegmentList
              segments={segments}
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
              segmentPage={segmentPage}
              setSegmentPage={setSegmentPage}
              segmentTotalPage={segmentTotalPage}
              segmentPerPage={segmentPerPage}
              setSegmentPerPage={setSegmentPerPage}
              handleSegmentPopup={handleSegmentPopup}
              handleInputChange={handleInputChange}
            />
          </InputContainer>

          {/* KANAL TURU */}
          <InputContainer
            title={t("ChannelType")}
            isMandatory={true}
            titleDescription={t("ChannelTypeDesc")}
          >
            <CampaignChannelType
              online={online}
              setOnline={setOnline}
              shop={shop}
              setShop={setShop}
            />
          </InputContainer>

          {/* KAMPANYA TURU */}
          <InputContainer
            title={t("campaignType")}
            isMandatory={true}
            titleDescription={t("campaignTypeSubtitle")}
          >
            <CampaignType
              campaignOptions={campaignOptions}
              campaignType={campaignType}
            />
          </InputContainer>

          {/* FREE SHIPPING */}
          {campaignType === "FREE_SHIPMENT" && (
            <InputContainer title={t("freeShipping")} isMandatory={false}>
              <div className="coin-folding-container">
                <div className="coin-folding-input-container">
                  <div className="coin-folding-input-wrapper">
                    <div
                      className={`coin-folding-input coin-folding-input-active`}
                    >
                      {t("freeShipping")}
                      <img src={tick} alt="tick" />
                    </div>
                  </div>
                </div>
              </div>
            </InputContainer>
          )}
          {/* COIN FOLDING */}
          {campaignType === "COIN_FOLDING" && (
            <InputContainer
              title={t("coinFolding")}
              isMandatory={true}
              titleDescription={t("coinFoldingSubtitle")}
            >
              <CoinFolding
                t={t}
                coinFoldingOptions={coinFoldingOptions}
                selectedCoinFolding={selectedCoinFolding}
                setSelectedCoinFolding={setSelectedCoinFolding}
              />
            </InputContainer>
          )}
          {/* % DISCOUNT TO X PRODUCT */}
          {campaignType === "BUY_MORE_PAY_LESS" && (
            <InputContainer
              title={t("productDiscountPercentage")}
              isMandatory={true}
            >
              <BuyMorePayLess
                discounts={discounts}
                handleDiscountChange={handleDiscountChange}
                handleDeleteDiscount={handleDeleteDiscount}
                handleAddDiscount={handleAddDiscount}
                t={t} // Bu örnekte çeviri fonksiyonunu basitleştirdim, kendi çeviri fonksiyonunuzu kullanmalısınız.
              />
            </InputContainer>
          )}
          {/* PERCENTAGE DISCOUNT */}
          {campaignType === "DISCOUNT_PERCENTAGE" && (
            <InputContainer
              title={t("PercentageDiscount")}
              isMandatory={true}
              titleDescription={t("PercentageDiscountSubtitle")}
            >
              <DiscountPercentage
                discountOptions={discountOptions}
                percentDiscount={percentDiscount}
                setPercentDiscount={setPercentDiscount}
                t={t} // Bu örnekte çeviri fonksiyonunu basitleştirdim, kendi çeviri fonksiyonunuzu kullanmalısınız.
              />
            </InputContainer>
          )}

          {/* PERCENTAGE COUPON */}
          {campaignType === "COUPON_PERCENTAGE" && (
            <InputContainer
              title={t("percentageCoupon")}
              isMandatory={true}
              titleDescription={t("PercentageDiscountSubtitle")}
            >
              <CouponPercentage
                couponOptions={couponOptions}
                percentCoupon={percentCoupon}
                setPercentCoupon={setPercentCoupon}
                t={t}
              />
            </InputContainer>
          )}

          {/* X PRICE TO Y */}
          {campaignType === "SPEND_AND_EARN" && (
            <InputContainer
              title={t("XPriceToY")}
              isMandatory={true}
              titleDescription={t("XPriceToYSubtitle")}
            >
              <SpendAndEarn
                xPrice={xPrice}
                yCoin={yCoin}
                setXPrice={setXPrice}
                setYCoin={setYCoin}
                t={t}
                returnedCampaign={returnedCampaign}
              />
            </InputContainer>
          )}
          {/* X AL Y ODE */}
          {campaignType === "BUY_X_PAY_Y" && (
            <InputContainer title={t("buyXPayNow")} isMandatory={true}>
              <BuyXPayY
                buyXPayYOptions={buyXPayYOptions}
                buyCount={buyCount}
                payCount={payCount}
                setBuyCount={setBuyCount}
                setPayCount={setPayCount}
                returnedCampaign={returnedCampaign}
                t={t}
              />
            </InputContainer>
          )}

          {/* ALISVERIS ALT LIMIT */}
          {campaignType !== "SPEND_AND_EARN" &&
            !campaignType != "BUY_X_PAY_Y" && (
              <InputContainer
                title={`${t("minShoppingLimit")} ${
                  campaignType !== "FREE_SHIPMENT" ? ` (${t("optional")})` : ""
                }`}
                isMandatory={campaignType === "FREE_SHIPMENT"}
                titleDescription={t("setSotyCoinAmountCampaign")}
              >
                <div className="challenge-creation-input-container-content-wrapper shopping-min-limit-row">
                  <input
                    type="number"
                    min="0"
                    className="challenge-creation-soty-coin-input"
                    value={minimumSpending}
                    onChange={(e) => {
                      setMinimumSpending(e.target.value);
                    }}
                  />
                </div>
              </InputContainer>
            )}

          {/* ANKET TARIHI */}

          <InputContainer
            title={t("campaignDate")}
            isMandatory={true}
            titleDescription={t("campaignDateTimeSet")}
          >
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-creation-date-column-container">
                <div className="challenge-creation-date-column">
                  <div className="challenge-creation-date-title">
                    {t("startDate")}
                    <span>*</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="challenge-creation-date-picker"
                    defaultValue={
                      returnedCampaign
                        ? moment
                            .utc(returnedCampaign.responseData.startDate)
                            .local()
                            .format("YYYY-MM-DDTHH:mm:ss")
                        : startDate
                    }
                    onBlur={startDateChange}
                    style={{ border: isStartDateValid ? "" : "2px solid red" }}
                  />
                </div>

                <div className="challenge-creation-date-column">
                  <div className="challenge-creation-date-title">
                    {t("endDate")}
                    <span>*</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="challenge-creation-date-picker"
                    defaultValue={
                      returnedCampaign
                        ? moment
                            .utc(returnedCampaign.responseData.endDate)
                            .local()
                            .format("YYYY-MM-DDTHH:mm:ss")
                        : endDate
                    }
                    onBlur={endDateChange}
                    style={{ border: isEndDateValid ? "" : "2px solid red" }}
                  />
                </div>
              </div>

              <div className="challenge-creation-date-description-row">
                <img
                  className="challenge-creation-date-description-icon"
                  src={infoIconEmpty}
                  alt="icon"
                />
                <div className="challenge-creation-date-description">
                  {t("setCampaignStartDate")}
                </div>
              </div>
            </div>
          </InputContainer>
          {/* KAMPANYA KATILABILME SAYISI */}

          <InputContainer
            title={t("campaignParticipationCount")}
            isMandatory={true}
            titleDescription={t("campaignParticipationCountSubtitle")}
          >
            <ParticipationAmount
              singleParticipate={singleParticipate}
              setSingleParticipate={setSingleParticipate}
              t={t}
            />
          </InputContainer>

          {/* BILGILENDIRME */}

          <InputContainer
            title={t("campaignMessageTypeTitle")}
            isMandatory={true}
            titleDescription={t("campaignMessageTypeSubtitle")}
            error={!(sendSms || sendMail || sendInApp)}
          >
            <NotificationPreferences
              t={t} // Çeviri fonksiyonunu burada basitleştirdim, kendi fonksiyonunuzu kullanın.
              sendInApp={sendInApp}
              sendSms={sendSms}
              sendMail={sendMail}
              handleInputChange={handleInputChange}
              setSendInApp={setSendInApp}
              setSendSms={setSendSms}
              setSendMail={setSendMail}
            />
          </InputContainer>
          {/* KAMPANYA BELIRLI SURE ONCE BILDIRIM */}
          <InputContainer
            title={t("campaignNotificationTitle")}
            isMandatory={true}
            titleDescription={t("campaignNotificationSubtitle")}
          >
            <CampaignNotificationTimeSelector
              selectedTimeOption={selectedTimeOption}
              setSelectedTimeOption={setSelectedTimeOption}
              t={t}
              tickIcon={tick}
              infoIconEmpty={infoIconEmpty}
            />
          </InputContainer>

          {/* KAMPANYA ONAY DUGMELERI */}
          <div className="challenge-creation-submit-button-container">
            <button
              className="draft"
              onClick={() => {
                handleCampaignUpload(false);
              }}
            >
              {t("saveAsDraft")}
            </button>
            <button
              className="preview"
              onClick={() => {
                handleCampaignUpload(true);
              }}
            >
              {t("preview")}
            </button>
          </div>
        </div>

        <PhoneViewJoin
          name={challengeName}
          description={challengeDescription}
          endDate={endDate || ""}
          logo={imageResponseString}
          imagesUrl={imagesUrl}
          challengeType={t("Campaign")}
          foldingString={
            selectedCoinFolding &&
            "X" + selectedCoinFolding + " " + t("sotyCoin")
          }
          percentString={percentDiscount}
          startDate={startDate}
          percentCouponString={percentCoupon}
          spendToEarnSpend={xPrice}
          spendToEarnEarn={yCoin}
          payCount={payCount}
          buyCount={buyCount}
          freeShipping={campaignType === "FREE_SHIPMENT"}
          sticky={true}
          discounts={campaignType === "BUY_MORE_PAY_LESS" && discounts}
        />
      </div>
    </div>
  );
};

export default CampaignCreation;
