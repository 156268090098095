let apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri,
  metabaseBrandAdminTotalEarningDaily = "55daac61-2e9a-441c-b923-56eadb9347ab",
  metabaseBrandAdminTotalEarningWeekly = "4663b248-81ee-4627-a4f8-a548f76636a6",
  metabaseBrandAdminTotalEarningDetails = "ca48bbd1-0f57-4374-90da-e8556a67b092",
  metabaseBrandAdminTotalSpendingDaily = "e1b9d9b7-b221-49d3-90b8-5598edbc7736",
  metabaseBrandAdminTotalSpendingWeekly = "23b99877-dc81-4f8e-b582-fdf46abc2128",
  metabaseBrandAdminTotalSpendingDetails = "0eac24b6-9bee-4134-a556-b2cc06c13d0d",
  metabaseBrandAdminTotalOrderDaily = "cfbb008f-5655-43de-8387-e61e3b6b932e",
  metabaseBrandAdminTotalOrderWeekly = "9af7a249-71bd-48cf-91b1-c16e5ad52574",
  metabaseBrandAdminTotalOrderDetails = "e9cef5ff-c5b7-4211-ae54-afa2eed0d8af",
  metabaseBrandAdminCountOrderDaily = "6a0a87b5-8840-47a4-a058-b18fbe367d83",
  metabaseBrandAdminCountOrderWeekly = "1696e23a-d1e0-4b82-9c2d-aae610b55d44",
  metabaseBrandAdminCountOrderDetails = metabaseBrandAdminTotalOrderDetails,
  metabaseBrandAdminTotalRefundDaily = "568be5f2-a5f4-4acf-a997-55bb5dd6ec14",
  metabaseBrandAdminTotalRefundWeekly = "10ea12f2-4caf-4115-8425-2d402187dfe2",
  metabaseBrandAdminTotalRefundDetails = "00e1bf74-9e88-4b06-b7ad-e2bebdf97cfa",
  metabaseBrandAdminCountRefundDaily = "a9395b1f-46c4-4645-9fc5-3d030b02f8ce",
  metabaseBrandAdminCountRefundWeekly = "7703e8c3-8ff5-4074-a092-c5851e22f2b1",
  metabaseBrandAdminCountRefundDetails = metabaseBrandAdminTotalRefundDetails,

  metabaseBrandAdminTotalContentCreationDaily = "e81e3f38-6ebd-4b3d-ae25-2e921999f62c",
  metabaseBrandAdminTotalContentCreationWeekly = "5626a2c9-6d32-4c8f-ae5d-cac6cf09333f",
  metabaseBrandAdminTotalContentCreationList = "e4aba48d-aa87-4f2a-a2b3-5ed65ab09316",
  metabaseBrandAdminTotalContentCreationByProvider = "401d8890-7311-46d8-a742-4b1f77fbead7",
  metabaseBrandAdminTotalContentCreationByDaysOfTheWeek = "68409fee-145f-4193-acf6-d80e17ac7db0",
  metabaseBrandAdminTotalContentCreationTop5Tag = "9e3a581b-78c6-48a2-8832-51c26fa1c07f",
  metabaseBrandAdminTotalContentCreationTop5HashTag = "e3e83cbb-19cb-4e67-b6c4-5f990d5ac6f1",

  metabaseBrandAdminTotalInteractionDaily = "6dc3a019-2041-492f-9d75-fa0fb46d0178",
  metabaseBrandAdminTotalInteractionWeekly = "bedb55ab-7785-4785-9939-54efa9875671",
  metabaseBrandAdminTotalInteractionList = "9124e6f3-1f12-4f6c-8b03-01b0a5beb985",
  metabaseBrandAdminTotalInteractionByProvider = "65bccacc-bd4d-45f6-a60a-bf2186d0b302",
  metabaseBrandAdminTotalInteractionByDaysOfTheWeek = "79d7afb9-ddaf-4dc7-8b0c-48bbe98d34d3",

  metabaseBrandAdminTotalChallengeCompletionDaily = "0f8b1cc3-98b3-411e-a13b-41cbbdb1842b",
  metabaseBrandAdminTotalChallengeCompletionWeekly = "674e3a1c-6bdd-48aa-897b-7a1c3aa3d376",
  metabaseBrandAdminTotalChallengeCompletionList = "039e5a81-1a9e-4242-9fab-2e5c8b97e1b4",
  metabaseBrandAdminTotalChallengeCompletionByProvider = "71c2dd0a-3c8f-4c9e-8b7a-3691450d2c31",
  metabaseBrandAdminTotalChallengeCompletionByDaysOfTheWeek = "93860e61-808c-441c-89ff-978240c244fc",
  metabaseBrandAdminTotalChallengeCompletionTop5Tag = metabaseBrandAdminTotalContentCreationTop5Tag,
  metabaseBrandAdminTotalChallengeCompletionTop5HashTag = metabaseBrandAdminTotalContentCreationTop5HashTag,

  metabaseBrandAdminTotalSurveyCompletionDaily = "e076e5fb-a118-445c-a9f1-542b66509abe",
  metabaseBrandAdminTotalSurveyCompletionWeekly = "2cf76449-985e-4539-a119-0b5c99651b95",
  metabaseBrandAdminTotalSurveyCompletionList = "3628454e-6d41-40d5-8690-8759fbb8633f",
  metabaseBrandAdminTotalSurveyCompletionByType = "28348a1a-509c-4434-a23f-102a1b69bc53",
  metabaseBrandAdminTotalSurveyCompletionByDaysOfTheWeek = "91103d77-d7b0-4c37-b6b5-ef999659d914",

  metabaseBrandAdminTotalRatingScaleSurveyCompletionDaily = "53d96c2a-576f-4017-8358-30c018f34eab",
  metabaseBrandAdminTotalRatingScaleSurveyCompletionWeekly = "d6b41a8f-3015-4a7d-a1c9-c87ad434e3b5",
  metabaseBrandAdminTotalRatingScaleSurveyCompletionList = "d3cbffd8-d48a-491d-b7e4-880ceafcf750",
  metabaseBrandAdminTotalRatingScaleSurveyCompletionByDaysOfTheWeek = "98d98a7f-8a74-40cd-b23b-7a321923f483",

  metabaseBrandAdminTotalLikertSurveyCompletionDaily = "ade0fc0c-6ea6-4916-889c-f72601bf6aee",
  metabaseBrandAdminTotalLikertSurveyCompletionWeekly = "8c7253d8-1c32-4b55-b33b-63729ffedef0",
  metabaseBrandAdminTotalLikertSurveyCompletionList = "5bb088e0-1d9e-47fd-9c9d-95d5849f8fce",
  metabaseBrandAdminTotalLikertSurveyCompletionByDaysOfTheWeek = "bc7febf5-a7bb-491a-b945-5b9c116e8152",

  metabaseBrandAdminTotalLikertAgreementSurveyCompletionDaily = "955d7199-c4ed-4279-9b9f-a3a0fefd9f20",
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionWeekly = "7c5e1581-99eb-4a9a-b570-d8fd4ff00440",
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionList = "963caafa-96e4-4863-b5aa-0640d1d37e4d",
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionByDaysOfTheWeek = "7390b45f-c238-4753-a02e-9088240a1f81",

  metabaseBrandAdminTotalLikertFrequencySurveyCompletionDaily = "f11d35bf-36fe-4ef0-8646-cf11a8809f8c",
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionWeekly = "b5e78512-8871-430f-afca-26d774cabb6d",
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionList = "a57ed791-0b1c-4297-8d19-4f212ae25b58",
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionByDaysOfTheWeek = "a7e51ca3-b505-400c-a589-a3163205ab72",

  metabaseBrandAdminTotalLikertImportanceSurveyCompletionDaily = "52ddc63d-0f23-4dbb-be0f-9eee4bab6c09",
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionWeekly = "edc3c563-91d7-4117-8cbf-a1c9533a2f6b",
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionList = "67188ae4-e0c9-4673-95af-effca6755457",
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionByDaysOfTheWeek = "e8ba31e2-2f9f-4bc1-a44d-9881fe0b7588",

  metabaseBrandAdminTotalLikertInterestSurveyCompletionDaily = "b94b0d1e-6de9-4cef-ac3b-0bfb52be9565",
  metabaseBrandAdminTotalLikertInterestSurveyCompletionWeekly = "3d4d958d-704e-4187-a5e2-00a04c329f3b",
  metabaseBrandAdminTotalLikertInterestSurveyCompletionList = "1a7c3ecb-11fe-444e-ae6b-1145b73a05dc",
  metabaseBrandAdminTotalLikertInterestSurveyCompletionByDaysOfTheWeek = "f735c0a9-c140-42cb-8ec7-5b4247bdee2b",

  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionDaily = "122bf034-c4cf-4507-ab78-d580f147c250",
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionWeekly = "e35e1b24-489b-4f35-94e9-2b79a69914c1",
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionList = "0fe8c454-d3a9-4e1d-b1ba-c60a4bf8cc91",
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionByDaysOfTheWeek = "6407ea95-9555-43a7-9101-3b6fccf96f36",

  metabaseBrandAdminTotalScoreSurveyCompletionDaily = "d00a779d-0e54-49f5-9bf0-f291a3f5f910",
  metabaseBrandAdminTotalScoreSurveyCompletionWeekly = "d20253c9-02be-40f7-a99f-9e6a106aef6f",
  metabaseBrandAdminTotalScoreSurveyCompletionList = "cd60ef3c-dcc2-4ef9-9ac8-00f6008f2ccc",
  metabaseBrandAdminTotalScoreSurveyCompletionByDaysOfTheWeek = "d3da8b85-c106-4321-a4dc-bb9aaa4981f9",

  metabaseBrandAdminTotalSingleChoiceSurveyCompletionDaily = "047bcdd6-a135-478c-9549-b8216affe50e",
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionWeekly = "c7e99b53-6da5-40e3-b3dd-c4dcac00dfe6",
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionList = "9bdfe4cb-6628-4588-80f7-81b195514e88",
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionByDaysOfTheWeek = "c8c2f619-2083-4aa7-8248-15a750789ebc",

  metabaseBrandAdminSotierEarningDaily = "af00b51e-a7af-43cc-a6cd-9b208ada9d76",
  metabaseBrandAdminSotierEarningWeekly = "a8b7268e-6cb9-4892-8c7f-f26175072494",
  metabaseBrandAdminSotierSpendingDaily = "63b144b9-de3d-436d-9ae3-9e710840c6c3",
  metabaseBrandAdminSotierSpendingWeekly = "d7c22bda-0835-4979-bebc-3bce36f569a2",
  metabaseBrandAdminCampaignCorporateDashboard = "86606a4d-e283-4b38-ab52-c152a0291dcc",
  metabaseBrandAdminCampaignCorporateDashboardList = "55cb0a35-35d5-4f61-a697-685df36416a5",
  metabaseBrandAdminCampaignCorporateDashboardSummary = "76155d1c-e236-4d70-bf36-85835806e05d",
  metabaseBrandAdminCampaignCorporateSotierList = "0b12f326-852c-47a4-bbb5-82161e934f14",

  metabaseBrandAdminCampaignDashboard = "688d70c2-e98b-4b5f-9b3f-6a0f84b81598",
  metabaseBrandAdminCampaignDashboardList = "e3c48572-12f5-4eaf-867f-ebeb5b6851c5",
  metabaseBrandAdminCampaignDashboardSummary = "",
  metabaseBrandAdminCampaignSotierList = "",

  metabaseBrandAdminSotierByProviderList = "8a0dbd1d-3aa7-471b-a072-65371e408d6e",

  metabaseRealmAdminSotierEarningDaily = "6fa31a53-f47e-414c-a0ce-49a21212114f",
  metabaseRealmAdminSotierEarningWeekly = "b04f8b09-0a12-47b2-8ee1-06df8e022982",
  metabaseRealmAdminSotierSpendingDaily = "e487986d-9985-49a9-8266-5efeb3173dec",
  metabaseRealmAdminSotierSpendingWeekly = "fcb906f2-3f76-4914-8292-04b2d18b9f4c",
  metabaseRealmAdminTotalOrderDaily = "7a196506-2b4e-44d7-bde0-7e203be3ec64",
  metabaseRealmAdminTotalOrderWeekly = "d2c7a59f-168f-4432-85fa-d452fa01d619",
  metabaseRealmAdminTotalOrderDetails = "d14570ec-34c7-4bae-a03f-bff707919736",
  metabaseRealmAdminCountOrderDaily = "90bf9a6e-ec51-4c30-a76e-737d3a95631f",
  metabaseRealmAdminCountOrderWeekly = "8e46d5af-25ec-4f11-9498-71ca9eabafd9",
  metabaseRealmAdminCountOrderDetails = metabaseRealmAdminTotalOrderDetails,
  metabaseRealmAdminTotalRefundDaily = "9016c61a-26be-47d1-9f54-605217240063",
  metabaseRealmAdminTotalRefundWeekly = "b3f6b290-d5e2-48de-ac36-df0058d47d0f",
  metabaseRealmAdminTotalRefundDetails = "ebfb77c3-5722-4e61-ad90-7a40cb9fdd7f",
  metabaseRealmAdminCountRefundDaily = "60e8bb6d-9eca-4a76-bd39-73240da3e453",
  metabaseRealmAdminCountRefundWeekly = "7ea51eb7-6b34-43fd-924c-988d2278ee76",
  metabaseRealmAdminCountRefundDetails = metabaseRealmAdminTotalRefundDetails,

  metabaseRealmAdminTotalContentCreationDaily = "a8c259c0-0b47-4987-829d-8b630e60f064",
  metabaseRealmAdminTotalContentCreationWeekly = "3a2b8c20-a691-4b0f-bbae-1a6042450a92",
  metabaseRealmAdminTotalContentCreationList = "4de41b68-5c8e-47ff-914e-d1ff26c296dc",
  metabaseRealmAdminTotalContentCreationByProvider = "f3aeccfc-b0e4-4ffd-98f1-a5981eb1bb08",
  metabaseRealmAdminTotalContentCreationByDaysOfTheWeek = "1ee5cdef-00d8-42a9-94f0-b26c4f4a9fa2",
  metabaseRealmAdminTotalContentCreationTop5Tag = "3b5674e0-9a38-45dd-ac0a-a1bfddb81a5b",
  metabaseRealmAdminTotalContentCreationTop5HashTag = "94d5788c-9e81-41d0-b52d-75e3e54b0e5b",

  metabaseRealmAdminTotalInteractionDaily = "d7f37431-812a-4762-afd4-27a0f66e7b2d",
  metabaseRealmAdminTotalInteractionWeekly = "61788ad7-d821-4787-b3b8-1b463ab1d249",
  metabaseRealmAdminTotalInteractionList = "8eead415-bceb-4c57-b8ba-370f3650d209",
  metabaseRealmAdminTotalInteractionByProvider = "7879e163-f944-4e38-8796-a2b2c7ebb9c9",
  metabaseRealmAdminTotalInteractionByDaysOfTheWeek = "ab448b84-5d71-4e9a-aa5b-629bf411cd48",

  metabaseRealmAdminTotalChallengeCompletionDaily = "ec02838a-06d9-49ec-a612-6b8ac0aaa662",
  metabaseRealmAdminTotalChallengeCompletionWeekly = "d233ddce-e153-4365-aeed-7ece1d2500da",
  metabaseRealmAdminTotalChallengeCompletionList = "ebc01738-73a5-4e10-a4b9-c97ef7cd67d4",
  metabaseRealmAdminTotalChallengeCompletionByProvider = "8080fa6e-3f50-4e68-86dc-52291e9399f3",
  metabaseRealmAdminTotalChallengeCompletionByDaysOfTheWeek = "bca15b2b-ab80-487b-8d8e-d0bd07c22819",
  metabaseRealmAdminTotalChallengeCompletionTop5Tag = metabaseRealmAdminTotalContentCreationTop5Tag,
  metabaseRealmAdminTotalChallengeCompletionTop5HashTag = metabaseRealmAdminTotalContentCreationTop5HashTag,

  metabaseRealmAdminTotalSurveyCompletionDaily = "6b2794c5-9b0a-4f2d-a97d-71649da8d318",
  metabaseRealmAdminTotalSurveyCompletionWeekly = "7f1cd3c7-2406-45e7-9a02-e964008160c6",
  metabaseRealmAdminTotalSurveyCompletionList = "6a123d8b-ec99-4ad6-b071-a8b1fafa92ae",
  metabaseRealmAdminTotalSurveyCompletionByType = "7db6b8bc-6f23-416a-913e-e62cf7e2dd7f",
  metabaseRealmAdminTotalSurveyCompletionByDaysOfTheWeek = "511e0bff-ad94-4e71-8991-3655a5505906",

  metabaseRealmAdminTotalRatingScaleSurveyCompletionDaily = "284aff34-94ed-4c01-8ca4-f5459f0eb608",
  metabaseRealmAdminTotalRatingScaleSurveyCompletionWeekly = "eded9fae-a27b-4581-b372-ecf1cf1848f4",
  metabaseRealmAdminTotalRatingScaleSurveyCompletionList = "7ecfc546-3f89-4fc9-b318-f158bd1b2cea",
  metabaseRealmAdminTotalRatingScaleSurveyCompletionByDaysOfTheWeek = "f2917720-ecb2-4d03-85b4-c5368d2286c3",

  metabaseRealmAdminTotalLikertSurveyCompletionDaily = "aa7ff066-704c-47f2-834e-919da7486266",
  metabaseRealmAdminTotalLikertSurveyCompletionWeekly = "53c562b9-a2a2-4f91-98e5-4532fa58aee4",
  metabaseRealmAdminTotalLikertSurveyCompletionList = "3aebf40d-d638-4990-9e15-eda1187a2c88",
  metabaseRealmAdminTotalLikertSurveyCompletionByDaysOfTheWeek = "f3fe87c2-8a2e-4433-8839-d72c2eb1d35f",

  metabaseRealmAdminTotalLikertAgreementSurveyCompletionDaily = "5613c106-a3e4-473b-8b6e-bb354b435f2e",
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionWeekly = "d74076dc-4519-494c-a3a5-91b4fad6307b",
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionList = "e3674179-eb8e-4200-a050-4a82054b0330",
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionByDaysOfTheWeek = "22398d55-ccc0-47f0-b087-49fc387188d4",

  metabaseRealmAdminTotalLikertFrequencySurveyCompletionDaily = "0430b51f-4f21-4841-a4f3-9e69bb9c24a0",
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionWeekly = "adaecae6-0c7a-42fb-ba31-4a1c930c440c",
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionList = "a354206f-faca-4fdb-9267-8e29ff471740",
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionByDaysOfTheWeek = "acb3d434-57f0-4def-89cd-66431f52a419",

  metabaseRealmAdminTotalLikertImportanceSurveyCompletionDaily = "b98c48a1-321e-4b98-9eba-686350f7ea59",
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionWeekly = "1340c14c-279d-4af0-b0be-083a93794b3d",
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionList = "baa5e4fa-f2a9-41c5-91af-bb961baab6a8",
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionByDaysOfTheWeek = "36323b3f-5b2b-4ab2-9eea-1e35e9fde711",

  metabaseRealmAdminTotalLikertInterestSurveyCompletionDaily = "4374f2fe-b1b2-4d3b-9cd9-835003cec0ff",
  metabaseRealmAdminTotalLikertInterestSurveyCompletionWeekly = "a3312da2-4a05-493b-b8b8-9c6fd5eb18f2",
  metabaseRealmAdminTotalLikertInterestSurveyCompletionList = "ad37805b-8555-444d-a775-6960c4a7a5a8",
  metabaseRealmAdminTotalLikertInterestSurveyCompletionByDaysOfTheWeek = "0259d1b1-b20b-4c24-8dc3-b12ca4d032d1",

  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionDaily = "6813e10b-0d38-4906-9c44-df75f46df0d1",
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionWeekly = "cad191b4-f87d-430d-82ce-6773f64f47f7",
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionList = "32920472-5551-4bf3-8513-f86254e0a88d",
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionByDaysOfTheWeek = "69110f1f-a280-4967-9849-85c4c04166da",

  metabaseRealmAdminTotalScoreSurveyCompletionDaily = "2a23c601-aa12-4e3e-a51f-bd837df52bcd",
  metabaseRealmAdminTotalScoreSurveyCompletionWeekly = "229f356e-1eb2-4b3d-914e-440ae292116b",
  metabaseRealmAdminTotalScoreSurveyCompletionList = "03faffa9-dcfc-492e-8f6f-ee5522277181",
  metabaseRealmAdminTotalScoreSurveyCompletionByDaysOfTheWeek = "bfef6b0b-0bca-479d-907b-10b928e61afe",

  metabaseRealmAdminTotalSingleChoiceSurveyCompletionDaily = "482299e2-0db7-4df5-985a-536d141a1494",
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionWeekly = "d5b329aa-e809-4a18-8cf5-afd76d760a4a",
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionList = "7e1ec911-d199-4b36-8bb9-8dba65c71fe4",
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionByDaysOfTheWeek = "41ded4d4-9f65-4b29-945c-8a32c08895f9",

  metabaseRealmAdminTotalEarningDaily = "e3401f44-ee61-4d16-adf4-4e9c421101ea",
  metabaseRealmAdminTotalEarningWeekly = "49a94501-0e04-4391-88f1-07ccda2cef90",
  metabaseRealmAdminTotalEarningDetails = "783c4010-4302-4632-923a-3e21cf33b27d",
  metabaseRealmAdminTotalSpendingDaily = "8bccdced-5501-44f8-9fd1-a3240aa3eecc",
  metabaseRealmAdminTotalSpendingWeekly = "050639f0-ef88-410d-a6a3-be364923a2e3",
  metabaseRealmAdminTotalSpendingDetails = "f15c0981-0f62-4608-8bb9-f81c4b051d25";

// Geliştirme ortamı için statik veriler
if (process.env.NODE_ENV === "development") {
  apiUrl = "https://api.dev.soty.io";
  authUrl = "https://auth.dev.soty.io";
  imagesUrl = "https://images.dev.soty.io";
  metabaseUrl = "https://metabase.dev.soty.io";
  authenticationGrantType = "password";
  adminClientId = "admin-client";
  adminClientSecret = "Vkkjk2yPSI1c5C631wa9mWdO5cqUdF8p";
  brandClientId = "brand-client";
  brandClientSecret = "WWM6AhMUIUUyqOSET6jXAOSDGxGUq01W";
  brandTiktokClientKey = "dev-tiktok-key";
  brandTiktokRedirectUri = "/brand/settings/profile";
} else {
  // Üretim ortamı için environment değişkenlerinden okuma
  apiUrl = "https://api.dev.soty.io";
  authUrl = "https://auth.dev.soty.io";
  imagesUrl = "https://images.dev.soty.io";
  metabaseUrl = "https://metabase.dev.soty.io";
  authenticationGrantType = "password";
  adminClientId = "admin-client";
  adminClientSecret = "Vkkjk2yPSI1c5C631wa9mWdO5cqUdF8p";
  brandClientId = "brand-client";
  brandClientSecret = "WWM6AhMUIUUyqOSET6jXAOSDGxGUq01W";
  brandTiktokClientKey = "sbaw5pl25vvg8nveg7";
  brandTiktokRedirectUri = "/brand/settings/profile";
}

export {
  apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri,
  metabaseBrandAdminTotalEarningDaily,
  metabaseBrandAdminTotalEarningWeekly,
  metabaseBrandAdminTotalEarningDetails,
  metabaseBrandAdminTotalSpendingDaily,
  metabaseBrandAdminTotalSpendingWeekly,
  metabaseBrandAdminTotalSpendingDetails,
  metabaseBrandAdminTotalOrderDaily,
  metabaseBrandAdminTotalOrderWeekly,
  metabaseBrandAdminTotalOrderDetails,
  metabaseBrandAdminCountOrderDaily,
  metabaseBrandAdminCountOrderWeekly,
  metabaseBrandAdminCountOrderDetails,
  metabaseBrandAdminTotalRefundDaily,
  metabaseBrandAdminTotalRefundWeekly,
  metabaseBrandAdminTotalRefundDetails,
  metabaseBrandAdminCountRefundDaily,
  metabaseBrandAdminCountRefundWeekly,
  metabaseBrandAdminCountRefundDetails,
  metabaseBrandAdminTotalContentCreationDaily,
  metabaseBrandAdminTotalContentCreationWeekly,
  metabaseBrandAdminTotalContentCreationList,
  metabaseBrandAdminTotalContentCreationByProvider,
  metabaseBrandAdminTotalContentCreationByDaysOfTheWeek,
  metabaseBrandAdminTotalContentCreationTop5Tag,
  metabaseBrandAdminTotalContentCreationTop5HashTag,
  metabaseBrandAdminTotalInteractionDaily,
  metabaseBrandAdminTotalInteractionWeekly,
  metabaseBrandAdminTotalInteractionList,
  metabaseBrandAdminTotalInteractionByProvider,
  metabaseBrandAdminTotalInteractionByDaysOfTheWeek,
  metabaseBrandAdminTotalChallengeCompletionDaily,
  metabaseBrandAdminTotalChallengeCompletionWeekly,
  metabaseBrandAdminTotalChallengeCompletionList,
  metabaseBrandAdminTotalChallengeCompletionByProvider,
  metabaseBrandAdminTotalChallengeCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalChallengeCompletionTop5Tag,
  metabaseBrandAdminTotalChallengeCompletionTop5HashTag,
  metabaseBrandAdminTotalSurveyCompletionDaily,
  metabaseBrandAdminTotalSurveyCompletionWeekly,
  metabaseBrandAdminTotalSurveyCompletionList,
  metabaseBrandAdminTotalSurveyCompletionByType,
  metabaseBrandAdminTotalSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalRatingScaleSurveyCompletionDaily,
  metabaseBrandAdminTotalRatingScaleSurveyCompletionWeekly,
  metabaseBrandAdminTotalRatingScaleSurveyCompletionList,
  metabaseBrandAdminTotalRatingScaleSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalLikertSurveyCompletionDaily,
  metabaseBrandAdminTotalLikertSurveyCompletionWeekly,
  metabaseBrandAdminTotalLikertSurveyCompletionList,
  metabaseBrandAdminTotalLikertSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionDaily,
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionWeekly,
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionList,
  metabaseBrandAdminTotalLikertAgreementSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionDaily,
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionWeekly,
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionList,
  metabaseBrandAdminTotalLikertFrequencySurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionDaily,
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionWeekly,
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionList,
  metabaseBrandAdminTotalLikertImportanceSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalLikertInterestSurveyCompletionDaily,
  metabaseBrandAdminTotalLikertInterestSurveyCompletionWeekly,
  metabaseBrandAdminTotalLikertInterestSurveyCompletionList,
  metabaseBrandAdminTotalLikertInterestSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionDaily,
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionWeekly,
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionList,
  metabaseBrandAdminTotalMultipleChoiceSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalScoreSurveyCompletionDaily,
  metabaseBrandAdminTotalScoreSurveyCompletionWeekly,
  metabaseBrandAdminTotalScoreSurveyCompletionList,
  metabaseBrandAdminTotalScoreSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionDaily,
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionWeekly,
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionList,
  metabaseBrandAdminTotalSingleChoiceSurveyCompletionByDaysOfTheWeek,
  metabaseBrandAdminSotierEarningDaily,
  metabaseBrandAdminSotierEarningWeekly,
  metabaseBrandAdminSotierSpendingDaily,
  metabaseBrandAdminSotierSpendingWeekly,
  metabaseBrandAdminCampaignCorporateDashboard,
  metabaseBrandAdminCampaignCorporateDashboardList,
  metabaseBrandAdminCampaignCorporateDashboardSummary,
  metabaseBrandAdminCampaignCorporateSotierList,
  metabaseBrandAdminCampaignDashboard,
  metabaseBrandAdminCampaignDashboardList,
  metabaseBrandAdminCampaignDashboardSummary,
  metabaseBrandAdminCampaignSotierList,
  metabaseBrandAdminSotierByProviderList,
  metabaseRealmAdminSotierEarningDaily,
  metabaseRealmAdminSotierEarningWeekly,
  metabaseRealmAdminSotierSpendingDaily,
  metabaseRealmAdminSotierSpendingWeekly,
  metabaseRealmAdminTotalOrderDaily,
  metabaseRealmAdminTotalOrderWeekly,
  metabaseRealmAdminTotalOrderDetails,
  metabaseRealmAdminCountOrderDaily,
  metabaseRealmAdminCountOrderWeekly,
  metabaseRealmAdminCountOrderDetails,
  metabaseRealmAdminTotalRefundDaily,
  metabaseRealmAdminTotalRefundWeekly,
  metabaseRealmAdminTotalRefundDetails,
  metabaseRealmAdminCountRefundDaily,
  metabaseRealmAdminCountRefundWeekly,
  metabaseRealmAdminCountRefundDetails,
  metabaseRealmAdminTotalContentCreationDaily,
  metabaseRealmAdminTotalContentCreationWeekly,
  metabaseRealmAdminTotalContentCreationList,
  metabaseRealmAdminTotalContentCreationByProvider,
  metabaseRealmAdminTotalContentCreationByDaysOfTheWeek,
  metabaseRealmAdminTotalContentCreationTop5Tag,
  metabaseRealmAdminTotalContentCreationTop5HashTag,
  metabaseRealmAdminTotalInteractionDaily,
  metabaseRealmAdminTotalInteractionWeekly,
  metabaseRealmAdminTotalInteractionList,
  metabaseRealmAdminTotalInteractionByProvider,
  metabaseRealmAdminTotalInteractionByDaysOfTheWeek,
  metabaseRealmAdminTotalChallengeCompletionDaily,
  metabaseRealmAdminTotalChallengeCompletionWeekly,
  metabaseRealmAdminTotalChallengeCompletionList,
  metabaseRealmAdminTotalChallengeCompletionByProvider,
  metabaseRealmAdminTotalChallengeCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalChallengeCompletionTop5Tag,
  metabaseRealmAdminTotalChallengeCompletionTop5HashTag,
  metabaseRealmAdminTotalSurveyCompletionDaily,
  metabaseRealmAdminTotalSurveyCompletionWeekly,
  metabaseRealmAdminTotalSurveyCompletionList,
  metabaseRealmAdminTotalSurveyCompletionByType,
  metabaseRealmAdminTotalSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalRatingScaleSurveyCompletionDaily,
  metabaseRealmAdminTotalRatingScaleSurveyCompletionWeekly,
  metabaseRealmAdminTotalRatingScaleSurveyCompletionList,
  metabaseRealmAdminTotalRatingScaleSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalLikertSurveyCompletionDaily,
  metabaseRealmAdminTotalLikertSurveyCompletionWeekly,
  metabaseRealmAdminTotalLikertSurveyCompletionList,
  metabaseRealmAdminTotalLikertSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionDaily,
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionWeekly,
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionList,
  metabaseRealmAdminTotalLikertAgreementSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionDaily,
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionWeekly,
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionList,
  metabaseRealmAdminTotalLikertFrequencySurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionDaily,
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionWeekly,
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionList,
  metabaseRealmAdminTotalLikertImportanceSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalLikertInterestSurveyCompletionDaily,
  metabaseRealmAdminTotalLikertInterestSurveyCompletionWeekly,
  metabaseRealmAdminTotalLikertInterestSurveyCompletionList,
  metabaseRealmAdminTotalLikertInterestSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionDaily,
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionWeekly,
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionList,
  metabaseRealmAdminTotalMultipleChoiceSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalScoreSurveyCompletionDaily,
  metabaseRealmAdminTotalScoreSurveyCompletionWeekly,
  metabaseRealmAdminTotalScoreSurveyCompletionList,
  metabaseRealmAdminTotalScoreSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionDaily,
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionWeekly,
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionList,
  metabaseRealmAdminTotalSingleChoiceSurveyCompletionByDaysOfTheWeek,
  metabaseRealmAdminTotalEarningDaily,
  metabaseRealmAdminTotalEarningWeekly,
  metabaseRealmAdminTotalEarningDetails,
  metabaseRealmAdminTotalSpendingDaily,
  metabaseRealmAdminTotalSpendingWeekly,
  metabaseRealmAdminTotalSpendingDetails,
};
