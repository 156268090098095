import React, { useState, useEffect, useRef } from "react";
import '../../assets/css/SortFilterSearch.css';
import downArrow from '../../assets/media/down-arrow.png'
import { useTranslation } from "react-i18next";
import StaticDateRangeDialog from "../dialogs/staticDateRangeDialog";

const StaticDateRangeButton = ({ options, selectedOption, onDateRangeChange }) => {
    const [isDialogVisible, setDialogVisibility] = useState(false);
  
    const { t, i18n } = useTranslation("analysis");

    const dialogRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setDialogVisibility(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    const toggleDialogVisibility = () => {
        setDialogVisibility(!isDialogVisible);
    };

    const onOptionSelect = (option) => {
        setDialogVisibility(false);
        onDateRangeChange(option.getDateRange());
    }

    return (
        <div className="static-date-range-button-container" ref={dialogRef}>
            <div className="static-date-range-button-wrapper" onClick={toggleDialogVisibility}>
                <div className="static-date-range-button-title">
                {t(selectedOption?.label || "custom")}
                </div>
                <img className={`static-date-range-button-img ${isDialogVisible ? "deg180" : ""}`} src={downArrow} alt="V" />
            </div>
            {isDialogVisible && <StaticDateRangeDialog options={options} onSelect={onOptionSelect} />}
        </div>
    )
}
export default StaticDateRangeButton;