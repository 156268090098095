import React, {useEffect, useState} from "react";
import firstIcon from "../../assets/media/AnalysisPieChartFirst.svg";
import secondIcon from "../../assets/media/AnalysisPieChartSecond.svg";
import thirdIcon from "../../assets/media/AnalysisPieChartThird.svg";
import fourthIcon from "../../assets/media/AnalysisPieChartFourth.svg";
import '../../assets/css/AnalysisPieChartCard.css';
import Chart from "react-google-charts";
import Loader from "../popup/loader";
import {useTranslation} from "react-i18next";
import {AnalysisApi} from "../fragments/AnalysisApi";
import {Formatter} from "../fragments/Formatter";


const AnalysisSotyCoinPieChartCard = ({
                                          locale,
                                          brandUuid,
                                          dateRange
                           }) => {

    const {t, i18n} = useTranslation("analysis");

    const [showLoader, setShowLoader] = useState(true);
    const [data, setData] = useState({});

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        let resizeTo;
        const handleResize = () => {
            if(resizeTo) {
                clearTimeout(resizeTo);
            }
            resizeTo = setTimeout(() => {
                setWindowSize(window.innerWidth);
            }, 500);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const formatter = new Formatter(locale);

    useEffect(() => {
        setShowLoader(true);

        AnalysisApi.sotyCoinEarningsByDateGroupedByCategory(brandUuid, dateRange)
            .then(setData)
            .finally(() => {
                setShowLoader(false);
            });
    }, [brandUuid, dateRange]);

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <div className="analysis-pie-chart-card">
                    <h1 className="analysis-pie-chart-card-title">{t("soty_coin_pie_chart_card_title")}</h1>
                    <div className="analysis-pie-chart-card-container">
                        <div className="analysis-pie-chart-card-legend">
                            <div className="analysis-pie-chart-card-legend-row">
                                <div className="analysis-pie-chart-card-legend-row-icon">
                                    <img
                                        src={firstIcon}
                                        alt="Icon"
                                    />
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-details">
                                    <h2>{t("soty_coin_pie_chart_card_challenges")}</h2>
                                    <span><b>{formatter.numberFormat(data.from_challenge_count)}</b> {t("soty_coin_pie_chart_card_challenges_count")} - <b>{formatter.numberFormat(data.from_challenge_sotier)}</b> {t("soty_coin_pie_chart_card_sotier")}</span>
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-value">
                                    {formatter.numberFormat(data.from_challenge)}
                                </div>
                            </div>
                            <div className="analysis-pie-chart-card-legend-row">
                                <div className="analysis-pie-chart-card-legend-row-icon">
                                    <img
                                        src={secondIcon}
                                        alt="Icon"
                                    />
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-details">
                                    <h2>{t("soty_coin_pie_chart_card_loyalty")}</h2>
                                    <span><b>{formatter.numberFormat(data.from_loyalty_count)}</b> {t("soty_coin_pie_chart_card_loyalty_count")} - <b>{formatter.numberFormat(data.from_loyalty_sotier)}</b> {t("soty_coin_pie_chart_card_sotier")}</span>
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-value">
                                    {formatter.numberFormat(data.from_loyalty)}
                                </div>
                            </div>
                            <div className="analysis-pie-chart-card-legend-row">
                                <div className="analysis-pie-chart-card-legend-row-icon">
                                    <img
                                        src={thirdIcon}
                                        alt="Icon"
                                    />
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-details">
                                    <h2>{t("soty_coin_pie_chart_card_offers")}</h2>
                                    <span><b>{formatter.numberFormat(data.from_offer_count)}</b> {t("soty_coin_pie_chart_card_offers_count")} - <b>{formatter.numberFormat(data.from_offer_sotier)}</b> {t("soty_coin_pie_chart_card_sotier")}</span>
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-value">
                                    {formatter.numberFormat(data.from_offer)}
                                </div>
                            </div>
                            <div className="analysis-pie-chart-card-legend-row">
                                <div className="analysis-pie-chart-card-legend-row-icon">
                                    <img
                                        src={fourthIcon}
                                        alt="Icon"
                                    />
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-details">
                                    <h2>{t("soty_coin_pie_chart_card_gift")}</h2>
                                    <span><b>{formatter.numberFormat(data.from_gift_count)}</b> {t("soty_coin_pie_chart_card_gift_count")} - <b>{formatter.numberFormat(data.from_gift_sotier)}</b> {t("soty_coin_pie_chart_card_sotier")}</span>
                                </div>
                                <div className="analysis-pie-chart-card-legend-row-value">
                                    {formatter.numberFormat(data.from_gift)}
                                </div>
                            </div>
                        </div>
                        <div className="analysis-pie-chart-card-pie-chart">
                            <Chart
                                width={"100%"}
                                height={"100%"}
                                chartType="PieChart"
                                data={[
                                    ["", ""],
                                    [t("soty_coin_pie_chart_card_challenges"), data.from_challenge],
                                    [t("soty_coin_pie_chart_card_loyalty"), data.from_loyalty],
                                    [t("soty_coin_pie_chart_card_offers"), data.from_offer],
                                    [t("soty_coin_pie_chart_card_gift"), data.from_gift],
                                ]}
                                options={{
                                    pieHole: 0.4,
                                    slices: ["#1E85FF", "#E63535", "#1CB454", "#E2A907"].map(color => ({ color })),
                                    legend: 'none',
                                    chartArea: {
                                        width:'100%',
                                        height:'100%'
                                    },
                                    tooltip: {
                                        text: 'value',
                                        trigger: 'selection',
                                    },
                                    enableInteractivity: false,
                                }}
                                key={windowSize}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AnalysisSotyCoinPieChartCard;

