import React, { useState, useEffect } from "react";
import InputContainer from "../../../../utils/inputContainer";
import { useTranslation } from "react-i18next";
import { LoyaltyLevelInput } from "./LoyaltyLevelInput";
import { LoyaltyLevelGradient, LoyaltyLevelInfo } from "./LoyaltyLevelDesign";
import CallAPI from "../../../../api/callApi";
import { apiUrl } from "../../../../../config";
import Loader from "../../../../popup/loader";

const LoyaltyLevelForm = () => {
  const { t } = useTranslation("brandAdminProfile");
  const [loading, setLoading] = useState(false);
  // Tüm seviyeleri tek bir state objesinde topladık.
  const [loyaltyLevels, setLoyaltyLevels] = useState({
    level1: { coinPercentage: 5, shoppingAmount: 0 },
    level2: { coinPercentage: 5, shoppingAmount: 5 },
    level3: { coinPercentage: 5, shoppingAmount: 5 },
    level4: { coinPercentage: 5, shoppingAmount: 5 },
  });

  // API'den verileri çek.
  const fetchBrand = async () => {
    setLoading(true);
    try {
      const response = await CallAPI({
        method: "GET",
        endPoint: `${apiUrl}/api/v1/brand-admin/brand`,
      });
      const data = response.data.responseData;

      // Gelen veriyi state'e aktarıyoruz.
      setLoyaltyLevels({
        level1: {
          coinPercentage: data.loyaltyLevel1CoinPercentage,
          shoppingAmount: 0,
        },
        level2: {
          coinPercentage: data.loyaltyLevel2CoinPercentage,
          shoppingAmount: data.loyaltyLevel2ShoppingAmount,
        },
        level3: {
          coinPercentage: data.loyaltyLevel3CoinPercentage,
          shoppingAmount: data.loyaltyLevel3ShoppingAmount,
        },
        level4: {
          coinPercentage: data.loyaltyLevel4CoinPercentage,
          shoppingAmount: data.loyaltyLevel4ShoppingAmount,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching loyalty levels:", error);
      setLoading(false);
    }
  };

  // Verileri kaydet.
  const handleSave = async () => {
    setLoading(true);
    try {
      await CallAPI({
        method: "PUT",
        endPoint: `${apiUrl}/api/v1/brand-admin/brand/loyalty-level`,
        successMessage: t("success.sotierLevelsUpdated"),
        errorMessage: t("error.sotierLevelsUpdated"),
        body: {
          loyaltyLevel1CoinPercentage: loyaltyLevels.level1.coinPercentage,
          loyaltyLevel2CoinPercentage: loyaltyLevels.level2.coinPercentage,
          loyaltyLevel3CoinPercentage: loyaltyLevels.level3.coinPercentage,
          loyaltyLevel4CoinPercentage: loyaltyLevels.level4.coinPercentage,
          loyaltyLevel2ShoppingAmount: loyaltyLevels.level2.shoppingAmount,
          loyaltyLevel3ShoppingAmount: loyaltyLevels.level3.shoppingAmount,
          loyaltyLevel4ShoppingAmount: loyaltyLevels.level4.shoppingAmount,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error("Error saving loyalty levels:", error);
      setLoading(false);
    }
  };

  // Sayfa yüklendiğinde fetchBrand çağrılır.
  useEffect(() => {
    fetchBrand();
  }, []);

  // Değişiklikleri işleyici.
  const handleInputChange = (level, field, value) => {
    setLoyaltyLevels((prev) => ({
      ...prev,
      [level]: { ...prev[level], [field]: value },
    }));
  };

  // Error kontrol fonksiyonu
  const hasError = (value) => {
    if (value === "" || value === undefined || value < 0) {
      return t("valueCannotBeLessThan0");
    }
    return false;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <InputContainer
      title={t("sotierLevels")}
      isMandatory={true}
      titleDescription={t("sotierLevelsDescription")}
    >
      <LoyaltyLevelInfo
        title={t("loyaltyLevelInfo.title")}
        updateNotice={t("loyaltyLevelInfo.updateNotice")}
        levelRetentionNotice={t("loyaltyLevelInfo.levelRetentionNotice")}
      />

      <div className="loyalty-level-form">
        <div className="loyalty-level-form-part loyalty-level-form-part-left">
          <LoyaltyLevelGradient />
          <div className="loyalty-level-column">
            <div className="loyalty-level-column-part-long loyalty-level-column-part" />
            {["level4", "level3", "level2"].map((level, index) => (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <div className="loyalty-level-column-part-input-gap loyalty-level-column-part" />
                )}
                <LoyaltyLevelInput
                  value={loyaltyLevels[level].shoppingAmount}
                  error={hasError(loyaltyLevels[level].shoppingAmount)} // Error kontrolü
                  onChange={(e) =>
                    handleInputChange(level, "shoppingAmount", e.target.value)
                  }
                />
              </React.Fragment>
            ))}
            <div className="loyalty-level-column-part-long loyalty-level-column-part" />
          </div>
        </div>
        <div className="loyalty-level-form-part">
          {["level4", "level3", "level2", "level1"].map((level, index) => (
            <LoyaltyLevelInput
              key={index}
              adornment={true}
              title={`Seviye ${4 - index}`}
              value={loyaltyLevels[level].coinPercentage}
              error={hasError(loyaltyLevels[level].coinPercentage, level)} // Error kontrolü
              onChange={(e) =>
                handleInputChange(level, "coinPercentage", e.target.value)
              }
            />
          ))}
        </div>
      </div>
      <div className="form-button-container">
        <button
          className="brand-admin-input-area invoice-button"
          onClick={handleSave}
        >
          {t("save")}
        </button>
      </div>
    </InputContainer>
  );
};

export default LoyaltyLevelForm;
