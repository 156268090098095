export class Formatter {
    constructor(locale) {
        this.numberFormatter = Intl.NumberFormat(locale,
            {
                maximumFractionDigits: 2,
            }
        );

        this.percentageFormatter = Intl.NumberFormat(locale,
            {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );

        this.currencyFormatter = Intl.NumberFormat(locale,
            {
                style: 'currency',
                currency: 'TRY',
                currencyDisplay: 'narrowSymbol',
            }
        );
    }

    numberFormat(number) {
        return this.numberFormatter.format(number);
    }

    percentageFormat(number) {
        return this.percentageFormatter.format(number);
    }

    currencyFormat(number) {
        return this.currencyFormatter.format(number);
    }
}