import React from "react";
import '../../assets/css/SortFilterSearch.css'
import {useTranslation} from "react-i18next";
import {
    endOfToday,
    format, isFirstDayOfMonth, isMonday, isSameDay,
    isThisISOWeek, isThisMonth,
    isToday, startOfISOWeek, startOfMonth,
    startOfToday,
    startOfYear,
    subYears
} from "date-fns";
import StaticDateRangeButton from "../button/staticDateRangeButton";
import {DateRange} from "../utils/dateRange";

const StaticDateRange = ({locale, dateRange, onDateRangeChange}) => {
    const {t, i18n} = useTranslation("analysis");

    const dateOptions = [
        {
            value: "last5year",
            label: "last5year",
            test: (dateRange) => isToday(dateRange.end) && isSameDay(dateRange.start, startOfYear(subYears(startOfToday(), 5))),
            getDateRange: () => new DateRange(subYears(startOfYear(startOfToday()), 5), endOfToday()),
        },
        {
            value: "year",
            label: "thisYear",
            test: (dateRange) => isToday(dateRange.end) && isSameDay(dateRange.start, startOfYear(startOfToday())),
            getDateRange: () => new DateRange(startOfYear(startOfToday()), endOfToday()),
        },
        {
            value: "today",
            label: "today",
            test: (dateRange) => isToday(dateRange.end) && isToday(dateRange.start),
            getDateRange: () => new DateRange(startOfToday(), endOfToday()),
        },
        {
            value: "month",
            label: "thisMonth",
            test: (dateRange) => isToday(dateRange.end) && isThisMonth(dateRange.start) && isFirstDayOfMonth(dateRange.start),
            getDateRange: () => new DateRange(startOfMonth(startOfToday()), endOfToday()),
        },
        {
            value: "week",
            label: "thisWeek",
            test: (dateRange) => isToday(dateRange.end) && isThisISOWeek(dateRange.start) && isMonday(dateRange.start),
            getDateRange: () => new DateRange(startOfISOWeek(startOfToday()), endOfToday()),
        },
    ]

    function getSelectedOption() {
        return dateOptions.find((option) => option.test(dateRange));
    }

    function formatAsHumanReadable() {
        const chosenOption = getSelectedOption(dateOptions, dateRange);
        if (chosenOption) {
            return t(chosenOption.label);
        } else {
            const formatStr = 'd MMMM yyyy EEEE';
            const formattedStart = format(dateRange.start, formatStr, {locale: locale});
            const formattedEnd = format(dateRange.end, formatStr, {locale: locale});
            return formattedStart + "  -  " + formattedEnd;
        }
    }

    return (
        <div className='challenge-analysis-top-hr-container'>
            <hr/>
            {formatAsHumanReadable()}
            <hr/>
            <StaticDateRangeButton
                options={dateOptions}
                selectedOption={getSelectedOption()}
                onDateRangeChange={onDateRangeChange}
            />
        </div>
    )
}
export default StaticDateRange;