import React, { useEffect, useState } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import locationIcon from "../../../../../../assets/media/locationIcon.svg";
import mailIcon from "../../../../../../assets/media/mail.svg";
import { apiUrl, imagesUrl } from "../../../../../../config";
import CallAPI from "../../../../../api/callApi";
import InputContainer from "../../../../../utils/inputContainer";
import { toast } from "react-toastify";
import Loader from "../../../../../popup/loader";
import ApproveNeededActionButton from "../../../../../button/approveNeededActionButton";
import { hasApproverRole } from "../../../../auth/tokenUtils";

const GiftSotyCoin = () => {
  const { t } = useTranslation("sotierList");
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [sotier, setSotier] = useState(null);

  const [loaderState, setLoaderState] = useState(false);
  // State for storing the input values
  const [coinAmount, setCoinAmount] = useState("");
  const [coinDescription, setCoinDescription] = useState("");

  // State for tracking input errors
  const [coinAmountError, setCoinAmountError] = useState(false);
  const [coinDescriptionError, setCoinDescriptionError] = useState(false);
  const [requestId, setRequestId] = useState();
  const [isApproverApproves, setIsApproverApproves] = useState(false);

  useEffect(() => {
    getSotier(id); // Fetch sotier data based on id
  }, [id]);

  useEffect(() => {
    if (location.state) {
      setCoinAmount(location.state.returnedApiData.coinAmount.value);
      setCoinDescription(location.state.returnedApiData.message);
      getSotier(location.state.returnedApiData.sotiers[0].id);
      setRequestId(location.state.requestId);
      if (location.state.returnedApiData.status === "REQUESTED") {
        setIsApproverApproves(hasApproverRole());
      }
    }
  }, [location.state]);

  const getSotier = async (sotierId) => {
    const response = await CallAPI({
      method: "get",
      endPoint: apiUrl + "/api/v1/brand-admin/sotier/" + sotierId + "/profile",
    });
    setSotier(response.data.responseData);
  };

  const locationArray = [
    [t("sotierList"), "/brand/srm/sotier/list"],
    [
      sotier?.userName ? sotier?.userName : t("profile"),
      `/brand/srm/sotier/profile/${id}`,
    ],
    [t("giftSotyCoin"), `/brand/srm/sotier/profile/${id}/gift`],
  ];

  const onSubmit = async ({ textareaValue = "", actionType = "" }) => {
    if (coinAmount < 1 || coinAmount > 100000) {
      setCoinAmountError(true);
      toast.error(t("coinAmountMustBeBetween1And100000"));

      return;
    } else {
      setCoinAmountError(false);
    }

    // Validation for coinDescription
    if (coinDescription.trim().length < 1) {
      setCoinDescriptionError(true);
      toast.error(t("descriptionCannotBeEmpty"));

      return;
    } else {
      setCoinDescriptionError(false);
    }

    if (isApproverApproves) {
      try {
        setLoaderState(true); // Start loader to indicate the process is ongoing

        // Determine success or error messages based on the action type (APPROVE/REJECT)
        const successMessage =
          actionType === "APPROVE"
            ? t("ApprovedSuccessfully")
            : t("RejectedSuccessfully");

        const errorMessage =
          actionType === "APPROVE"
            ? t("anErrorAcurredWhileApproving")
            : t("anErrorAcurredWhileRejecting");

        // Call API with the appropriate method, endpoint, body, and messages
        await CallAPI({
          method: "put",
          endPoint: `${apiUrl}/api/v1/brand-admin/publish/request/${requestId}`,
          body: { publishRequestAction: actionType, note: textareaValue },
          successMessage: successMessage,
          errorMessage: errorMessage,
        });

        // Reset the input fields and stop the loader on successful completion
        setCoinDescription("");
        setCoinAmount("");
        navigate("/brand/approval");
      } catch (error) {
        // If an error occurs, stop the loader and log the error
        console.error("Error during API call:", error);

        // Optionally, show an error message to the user (you can display this in the UI)
        // Example: setErrorMessage(t("Something went wrong, please try again."));
      } finally {
        // Ensure that loader is stopped regardless of success or failure
        setLoaderState(false);
      }
    } else {
      setLoaderState(true);
      await CallAPI({
        method: "post",
        endPoint: apiUrl + "/api/v1/brand-admin/sotier/gift/request",
        body: {
          sotiers: [id],
          coin: coinAmount,
          message: coinDescription,
        },
        successMessage: hasApproverRole()
          ? t("coinSent")
          : t("sentForApproval"),
        errorMessage: hasApproverRole()
          ? t("anErrorAcurredWhileSendingCoin")
          : t("anErrorAcurredWhileSendingForApproval"),
      });
      setCoinDescription("");
      setCoinAmount("");
      setLoaderState(false);
      navigate("/brand/srm/sotier/list");
    }
  };

  return (
    <div className="dashboard-container profile-dashboard-background">
      <NavigationBar locationArray={locationArray} />
      {loaderState && <Loader />}
      <div className="profile-dashboard">
        {/* Sotier Profile Section */}
        <div className="sotier-profile-row-container profile-card">
          <div className="sotier-profile-row-top">
            <div className="sotier-profile-row-left">
              <div className="sotier-profile-credentials-container">
                <img
                  className="sotier-profile-picture"
                  src={
                    sotier?.logo
                      ? imagesUrl + "/" + sotier?.logo
                      : placeholderImg
                  }
                  alt={t("profilePictureAlt")}
                />
                <div className="sotier-profile-credentials">
                  <div className="sotier-profile-name-container">
                    <div>{sotier?.userName}</div>
                    <div>{sotier?.firstName}</div>
                    <div>{sotier?.lastName}</div>
                  </div>
                  <div className="sotier-profile-contact-container">
                    <div>
                      {t("sotierId")}: {sotier?.sotierId}
                    </div>
                    <div>
                      <img src={locationIcon} alt={t("locationAlt")} />
                      {sotier?.city || t("notAvailable")}
                    </div>
                    <div>
                      <img src={mailIcon} alt={t("mailAlt")} />
                      {sotier?.email || ""}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("birthDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.birthDate
                        ? new Date(sotier.birthDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("membershipDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.membershipDate
                        ? new Date(sotier.membershipDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gift Soty Coin Amount Input */}
        <InputContainer
          title={t("giftSotyCoin")}
          isMandatory={true}
          titleDescription={t("giftSotyCoinSubtitle")}
        >
          <input
            id="coinAmount"
            type="number"
            min="1"
            max="100000"
            value={coinAmount}
            onChange={(e) => {
              setCoinAmount(e.target.value);
            }}
            className={`gift-coin-input-field ${
              coinAmountError ? "error" : ""
            }`}
          />
        </InputContainer>

        {/* Gift Soty Coin Description Textarea */}
        <InputContainer
          title={t("giftSotyCoinMessage")}
          isMandatory={true}
          titleDescription={t("giftSotyCoinMessageDescription")}
        >
          <textarea
            id="coinDescription"
            className={`gift-coin-textarea ${
              coinDescriptionError ? "error" : ""
            }`}
            value={coinDescription}
            onChange={(e) => {
              setCoinDescription(e.target.value);
            }}
            maxLength="511"
          />
        </InputContainer>

        <div className="challenge-creation-submit-button-container">
          <ApproveNeededActionButton
            onClick={({ textareaValue = "", actionType = "" }) => {
              onSubmit({
                textareaValue,
                actionType,
              });
            }}
            isApproverApproves={isApproverApproves}
            title={t("sendCoin")}
          />
        </div>
      </div>
    </div>
  );
};

export default GiftSotyCoin;
