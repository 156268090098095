import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { RoutesConfig } from "./RoutesConfig.js";
import VerticalWrapper from "./components/wrapper/VerticalWrapper";
import Navbar from "./components/navbar/navbar";
import HorizontalWrapper from "./components/wrapper/HorizontalWrapper";
import SideBarNew from "./components/sidebar/SidebarNew.js";
import ScrollToTopButton from "./components/utils/ScrollToTop.js";
import CrispChat from "./components/utils/crispChat.js";
import AutoScrollToTop from "./components/utils/autoScrollToTop.js";
import { ToastContainer } from "react-toastify";
import useDisableNumberScroll from "./components/utils/useDisableNumberScroll.js";
import "../src/assets/css/toastify.css";
import "react-toastify/dist/ReactToastify.css";
import "../src/assets/css/App.css";

const EXCLUDED_PATHS = ["/register", "/", "/sign-in-realm", "/sign-in-brand"];

function AppContent() {
  const location = useLocation();
  const shouldHideNavAndSidebar = EXCLUDED_PATHS.includes(location.pathname);

  function createRoutes(routesConfig) {
    return routesConfig.map((route, index) => (
      <Route
        key={index}
        {...(route.index ? { index: true } : { path: route.path })}
        element={route.element}
      >
        {route.routes && createRoutes(route.routes)}
      </Route>
    ));
  }

  return (
    <>
      <AutoScrollToTop />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="light"
      />
      <VerticalWrapper>
        {!shouldHideNavAndSidebar && <Navbar />}
        <HorizontalWrapper>
          {!shouldHideNavAndSidebar && <SideBarNew />}
          <Routes>
            {createRoutes(RoutesConfig)}
          </Routes>
        </HorizontalWrapper>
      </VerticalWrapper>
      <ScrollToTopButton />
      <CrispChat />
    </>
  );
}

function App() {
  useDisableNumberScroll();
  useEffect(() => {
    document.title = "SOTY";
    console.log(process.env.REACT_APP_API_URL);
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
