import {imagesUrl} from "../../../../../config";
import React from "react";
import AnalysisTable from "../../../../tables/analysisTable";
import Pagination from "../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {format, fromUnixTime} from "date-fns";

const ChallengeByIDDetails = ({
                              locale,
                              tableData,
                              totalPage,
                              currentPage,
                              setCurrentPage,
                              setItemPerPage,
                       }) => {
    const { t} = useTranslation("analysis");
    const { search } = useLocation();
    const navigate = useNavigate();

    const tableColumns = [
        { title: t('start_date'), field: 1,
            render: (row) => {
                const date = row[1];
                return format(fromUnixTime(date), "PPpp", {locale: locale});
            }
        },
        { title: t('sotier_profile_logo'), field: 2,
            render: (row) => {
                const logo_id = row[2];
                const image_src = imagesUrl + "/" + logo_id;
                return (<img src={image_src} alt="Logo"/>);
            }
        },
        { title: t('sotier_name_lastname'), field: 3,
            render: (row) => row[3] + " " + row[4]
        },
        { title: t('sotier_participation_status'), field: 5,
            render: (row) => t(row[5])
        },
    ];

    return (
        <div className="analysis-sotier-container">
            <AnalysisTable
                columns={tableColumns}
                data={tableData}
                tableName={t('sotierList')}
                isRowClickable={() => false}
                onRowClick={(row) => false}
            />
            <div className="table-bottom-control-container" style={{ marginBottom: '300px' }}>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={(e) => { setCurrentPage(e) }}
                />
                <ItemsPerPageButton
                    onSelectItem={(e) => { setCurrentPage(1); setItemPerPage(e); }}
                    title={t("sotierPerPage")} />
            </div>
        </div>
    )
}

export default ChallengeByIDDetails;