import React from "react";
import {useOutletContext} from "react-router-dom";
import InteractionKPIs from "../../../../../fragments/challenge/kpis/interactionKPIs";
import InteractionDetails from "../../../../../fragments/challenge/details/interactionDetails";

const InteractionAnalysis = () => {

    const { language,dateRange } = useOutletContext();

    return (
        <div className='challenge-analysis-charts-container'>
            <InteractionKPIs
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                dateRange={dateRange}
            />
            <hr/>
            <InteractionDetails
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                dateRange={dateRange}
            />
        </div>
    )
};

export default InteractionAnalysis;