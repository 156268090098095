import React, { useState, useEffect } from "react";
import Loader from "../../../../popup/loader";
import {AnalysisApi} from "../../../AnalysisApi";
import ChallengeByIDDetails from "./challengeByIDDetails";

const SurveyLikertImportanceByIDDetails = ({
                                    locale,
                                    brandUuid,
                                    challengeId,
}) => {
    const [loaderState, setLoaderState] = useState(false);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setLoaderState(true);

        AnalysisApi.surveyLikertImportanceDetailsByID(brandUuid, challengeId, currentPage, totalPage)
            .then((data) => {
                setTotalPage(data.pageMetadata.totalPage);
                setTableData(data.responseData);
            })
            .finally(() => {
                setLoaderState(false);
            });
    }, [itemPerPage, currentPage]);

    return (
        <>
            {loaderState ? (
                <Loader />
            ) :
            (
                <ChallengeByIDDetails
                    locale={locale}
                    tableData={tableData}
                    currentPage={currentPage}
                    totalPage={totalPage}
                    setCurrentPage={setCurrentPage}
                    setItemPerPage={setItemPerPage}
                />
            )}
        </>
    )
}

export default SurveyLikertImportanceByIDDetails;