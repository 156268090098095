// src/i18n/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enBrandLoginForm from "./locales/en/brandLoginForm.json";
import trBrandLoginForm from "./locales/tr/brandLoginForm.json";
import esBrandLoginForm from "./locales/es/brandLoginForm.json";
import trRealmLoginForm from "./locales/tr/realmLoginForm.json";
import enRealmLoginForm from "./locales/en/realmLoginForm.json";
import esRealmLoginForm from "./locales/es/realmLoginForm.json";
import trBrandAdminProfile from "./locales/tr/brandAdminProfile.json";
import enBrandAdminProfile from "./locales/en/brandAdminProfile.json";
import esBrandAdminProfile from "./locales/es/brandAdminProfile.json";
import enSidebar from "./locales/en/sideBar.json";
import trSidebar from "./locales/tr/sideBar.json";
import esSidebar from "./locales/es/sideBar.json";
import enContentList from "./locales/en/contentList.json";
import trContentList from "./locales/tr/contentList.json";
import trChallengeList from "./locales/tr/challengeList.json";
import enChallengeList from "./locales/en/challengeList.json";
import esChallengeList from "./locales/es/challengeList.json";
import trChallengeCreation from "./locales/tr/challengeCreation.json";
import enChallengeCreation from "./locales/en/challengeCreation.json";
import esChallengeCreation from "./locales/es/challengeCreation.json";
import trBrandAdminRoleList from "./locales/tr/brandAdminRoleList.json";
import enBrandAdminRoleList from "./locales/en/brandAdminRoleList.json";
import esBrandAdminRoleList from "./locales/en/brandAdminRoleList.json";
import trBrandApplicationList from "./locales/tr/brandApplicationList.json";
import enBrandApplicationList from "./locales/en/brandApplicationList.json";
import esBrandApplicationList from "./locales/en/brandApplicationList.json";
import trChallengeDashboard from "./locales/tr/challengeDashboard.json";
import enChallengeDashboard from "./locales/en/challengeDashboard.json";
import trAnalysis from "./locales/tr/analysis.json";
import enAnalysis from "./locales/en/analysis.json";
import trSegmentation from "./locales/tr/segmentation.json";
import enSegmentation from "./locales/en/segmentation.json";
import trApi from "./locales/tr/api.json";
import enApi from "./locales/en/api.json";
import trSotierList from "./locales/tr/sotierList.json";
import enSotierList from "./locales/en/sotierList.json";
import trReports from "./locales/tr/reports.json";
import enReports from "./locales/en/reports.json";
import trInbox from "./locales/tr/inbox.json";
import enInbox from "./locales/en/inbox.json";
import trUserList from "./locales/tr/userList.json";
import enUserList from "./locales/en/userList.json";
import trFaq from "./locales/tr/faq.json";
import enFaq from "./locales/en/faq.json";
import trLogs from "./locales/tr/logs.json";
import enLogs from "./locales/en/logs.json";
import trLanding from "./locales/tr/landing.json";
import enLanding from "./locales/en/landing.json";
import trApproval from "./locales/tr/approval.json";
import enApproval from "./locales/en/approval.json";

const resources = {
  en: {
    brandLoginForm: enBrandLoginForm,
    realmLoginForm: enRealmLoginForm,
    sideBar: enSidebar,
    brandAdminProfile: enBrandAdminProfile,
    challengeList: enChallengeList,
    challengeCreation: enChallengeCreation,
    brandAdminRoleList: enBrandAdminRoleList,
    brandApplicationList: enBrandApplicationList,
    contentList: enContentList,
    challengeDashboard: enChallengeDashboard,
    analysis: enAnalysis,
    segmentation: enSegmentation,
    api: enApi,
    sotierList: enSotierList,
    reports: enReports,
    inbox: enInbox,
    userList: enUserList,
    faq: enFaq,
    logs: enLogs,
    landing: enLanding,
    approval: enApproval,
  },
  tr: {
    brandLoginForm: trBrandLoginForm,
    realmLoginForm: trRealmLoginForm,
    sideBar: trSidebar,
    brandAdminProfile: trBrandAdminProfile,
    challengeList: trChallengeList,
    challengeCreation: trChallengeCreation,
    brandAdminRoleList: trBrandAdminRoleList,
    brandApplicationList: trBrandApplicationList,
    contentList: trContentList,
    challengeDashboard: trChallengeDashboard,
    analysis: trAnalysis,
    segmentation: trSegmentation,
    api: trApi,
    sotierList: trSotierList,
    reports: trReports,
    inbox: trInbox,
    userList: trUserList,
    faq: trFaq,
    logs: trLogs,
    landing: trLanding,
    approval: trApproval,
  },
};
const storedLanguage = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage || "tr",
  interpolation: {
    escapeValue: false,
  },
});
i18n.on("languageChanged", (newLang) => {
  localStorage.setItem("language", newLang);
});
export default i18n;
