import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DownArrow from '../../assets/media/down-arrow.png';
import '../../assets/css/DateRangePicker.css';
import { useTranslation } from "react-i18next";
import {DateRange} from "../utils/dateRange";
import {addDays, differenceInDays, endOfDay, endOfISOWeek, endOfMonth, endOfYear, format, min, subDays} from "date-fns";

const DateRangePickerNew = ({locale, dateRange, maxDate, onDateRangeChange, onResetClick }) => {
    const { t } = useTranslation("challengeDashboard");

    const [startDate, setStartDate] = useState(dateRange.start);
    const [endDate, setEndDate] = useState(dateRange.end);
    const [term, setTerm] = useState('day');

    const resetDateRange = () => {
        setTerm('day');
        const defaultDateRange = DateRange.defaultDateRange();
        setStartDate(defaultDateRange.start);
        setEndDate(defaultDateRange.end);
        onDateRangeChange(defaultDateRange);
        onResetClick(true);
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(end != null) {
            const endDateByTerm = {
                day: (date) => min([endOfDay(end), maxDate]),
                week: (date) => min([endOfISOWeek(end), maxDate]),
                month: (date) => min([endOfMonth(end), maxDate]),
                year: (date) => min([endOfYear(end), maxDate]),
            }

            const newDateRange = new DateRange(start, endDateByTerm[term](end));
            onDateRangeChange(newDateRange);
        }
    };

    const handleNextDay = () => {
        if(differenceInDays(maxDate, endDate) > 0) {
            const newStartDate = addDays(startDate, 1);
            const newEndDate = addDays(endDate, 1);
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onDateRangeChange(new DateRange(newStartDate, newEndDate));
        }
    };

    const handlePrevDay = () => {
        const newStartDate = subDays(startDate, 1);
        const newEndDate = subDays(endDate, 1);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        onDateRangeChange(new DateRange(newStartDate, newEndDate));
    };

    function handleTermChoice(event) {
        setTerm(event.target.value);
    }

    return (
        <div className='date-range-picker-dialog'>
            <div className='date-range-picker-header'>
                <div className='date-range-picker-header-title'>
                    {t("date")}
                </div>
                <div className='date-range-picker-header-date-row'>
                    <div className='date-range-picker-button' onClick={handlePrevDay}>
                        <img className='dec' src={DownArrow} alt='<' />
                    </div>
                    <div className='date-ranger-picker-header-row-center'>
                        <div className='date-range-picker-header-calendar-img-container'>
                            <div className='data-range-picker-header-calendar-img-content-wrapper'>
                                <div className='date-range-picker-calendar-img-date-title'>
                                    {format(dateRange.start, 'EEE', {locale: locale})}
                                </div>
                                <div className='date-range-picker-calendar-img-date'>{dateRange.start.getDate()}</div>
                            </div>
                        </div>

                        <div className='date-range-picker-header-date'>
                            <div>{format(dateRange.start, 'P', {locale: locale})}</div>
                            {dateRange.differenceInDays() > 0 && (
                                <div>{format(dateRange.end, 'P', {locale: locale})}</div>
                            )}
                        </div>
                        <div className='date-range-picker-header-calendar-img-container'>
                            <div className='data-range-picker-header-calendar-img-content-wrapper'>
                                <div className='date-range-picker-calendar-img-date-title'>
                                    {format(dateRange.end, 'EEE', {locale: locale})}
                                </div>
                                <div className='date-range-picker-calendar-img-date'>{dateRange.end.getDate()}</div>
                            </div>
                        </div>
                    </div>
                    <div className='date-range-picker-button' onClick={handleNextDay}>
                        <img className='inc' src={DownArrow} alt='<' />
                    </div>
                </div>
                <div className="date-range-picker-radio-input">
                    <label>
                        <input
                            value="day"
                            name="term"
                            type="radio"
                            checked={term === 'day'}
                            onChange={handleTermChoice}
                        />
                        <span>{t("day")}</span>
                    </label>
                    <label>
                        <input
                            value="week"
                            name="term"
                            type="radio"
                            checked={term === 'week'}
                            onChange={handleTermChoice}
                        />
                        <span>{t("week")}</span>
                    </label>
                    <label>
                        <input
                            value="month"
                            name="term"
                            type="radio"
                            checked={term === 'term'}
                            onChange={handleTermChoice}
                        />
                        <span>{t("month")}</span>
                    </label>
                    <label>
                        <input
                            value="year"
                            name="term"
                            type="radio"
                            checked={term === 'year'}
                            onChange={handleTermChoice}
                        />
                        <span>{t("year")}</span>
                    </label>
                    <span className="selection"></span>
                </div>
            </div>
            <div className='date-range-picker-separator' />
            <div className='date-range-picker'>
                <DatePicker
                    locale={locale}
                    selected={startDate}
                    onChange={onChange}
                    isClearable={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={maxDate}
                    selectsRange={true}
                    showDisabledMonthNavigation
                    inline
                    showWeekPicker={term === 'week'}
                    showMonthYearPicker={term === 'month'}
                    showYearPicker={term === 'year'}
                    disabledKeyboardNavigation
                />
            </div>
            <div className='filter-dialog-button-container'>
                <div className='filter-dialog-reset-button' onClick={resetDateRange}>
                    {t("reset")}
                </div>
            </div>
        </div>
    );
};

export default DateRangePickerNew;
