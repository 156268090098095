import React from "react";
import {useTranslation} from "react-i18next";
import AnalysisTotalCard from "../../../cards/AnalysisTotalCard";
import '../../../../assets/css/AnalysisTotalCard.css';
import {Formatter} from "../../Formatter";

const ChallengeKPIs = ({
    locale,
    kpis,
                     }) => {
    const {t, i18n} = useTranslation("analysis");

    const formatter = new Formatter(locale);

    return (
        <div className='analysis-total-card-wrapper'>
            <AnalysisTotalCard
                cardTitle={t("total_sotier_count_title")}
                mainTitle={t("challenge_soty_coin_earned")}
                mainValue={formatter.numberFormat(kpis.total_soty_coin_earned)}
            />
            <AnalysisTotalCard
                cardTitle={t("challenge_participation_count_title")}
                mainTitle={t("challenge_participation_count")}
                mainValue={formatter.numberFormat(kpis.challenge_participation_count)}
            />
            <AnalysisTotalCard
                cardTitle={t("challenge_completion_count_title")}
                mainTitle={t("challenge_completion_count")}
                mainValue={formatter.numberFormat(kpis.challenge_completion_count)}
            />
            <AnalysisTotalCard
                cardTitle={t("challenge_success_rate_title")}
                mainTitle={t("challenge_success_rate")}
                mainValue={formatter.percentageFormat(kpis.challenge_success_rate)}
            />
        </div>
    )
}
export default ChallengeKPIs;