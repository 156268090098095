import GeneralKPIs from "../../../../../fragments/generalKPIs";
import AnalysisNetOrderLineChartCard from "../../../../../cards/AnalysisNetOrderLineChartCard";
import AnalysisSotyCoinPieChartCard from "../../../../../cards/AnalysisSotyCoinPieChartCard";
import React from "react";
import {useOutletContext} from "react-router-dom";

const General = () => {

    const { language,dateRange } = useOutletContext();

    return (
        <div className='challenge-analysis-charts-container'>
            <GeneralKPIs
                locale={language}
                brandUuid={localStorage.getItem("UUID")}
                dateRange={dateRange}
            />
            <hr/>
            <div className='challenge-analysis-details-container'>
                <AnalysisNetOrderLineChartCard
                    locale={language}
                    brandUuid={localStorage.getItem("UUID")}
                    dateRange={dateRange}
                />
                <AnalysisSotyCoinPieChartCard
                    locale={language}
                    brandUuid={localStorage.getItem("UUID")}
                    dateRange={dateRange}
                    startDate={dateRange.getFormattedStart()}
                    endDate={dateRange.getFormattedEnd()}
                />
            </div>
        </div>
    )
};

export default General;