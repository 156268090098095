import ChallengeKPIs from "./challengeKPIs";
import React, {useEffect, useState} from "react";
import {AnalysisApi} from "../../AnalysisApi";
import Loader from "../../../popup/loader";

const SurveyMultipleChoiceKPIs = ({
    locale,
    brandUuid,
    dateRange,
})=> {

    const [showLoader, setShowLoader] = useState(true);
    const [kpis, setKpis] = useState({});

    useEffect(() => {
        setShowLoader(true);
        AnalysisApi.surveyMultipleChoiceKPIs(brandUuid, dateRange)
            .then(setKpis)
            .finally(() => setShowLoader(false));
    }, [brandUuid, dateRange]);

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <ChallengeKPIs
                    locale={locale}
                    kpis={kpis}
                />
            )}
        </>
    );

}

export default SurveyMultipleChoiceKPIs;