import React, {useEffect, useState} from "react";
import {AnalysisApi} from "../../../AnalysisApi";
import Loader from "../../../../popup/loader";
import ChallengeKPIs from "../challengeKPIs";

const SurveySingleChoiceByIDKPIs = ({
    locale,
    brandUuid,
    challengeId,
})=> {

    const [showLoader, setShowLoader] = useState(true);
    const [kpis, setKpis] = useState({});

    useEffect(() => {
        setShowLoader(true);
        AnalysisApi.surveySingleChoiceKPIsByID(brandUuid, challengeId)
            .then(setKpis)
            .finally(() => setShowLoader(false));
    }, [brandUuid, challengeId]);

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <ChallengeKPIs
                    locale={locale}
                    kpis={kpis}
                />
            )}
        </>
    );

}

export default SurveySingleChoiceByIDKPIs;