import React from "react";
import ImageDropzone from "../../../../dropzone/ImageDropzone";

const BrandInfo = ({
  isForm2Filled,
  checkImage,
  tick,
  url,
  brandName,
  handleImageUpload,
  logoReadOnly,
  t,
}) => {
  return (
    <div className="brand-admin-info-container">
      <div className="brand-admin-info-container-title-container">
        {isForm2Filled && (
          <img
            src={checkImage}
            alt="ico"
            className="brand-admin-info-container-title-img"
          />
        )}

        <div className="brand-admin-info-container-title">{t("brandInfo")}</div>
      </div>
      <div className="brand-admin-info-container-wrapper">
        <div className="brand-admin-info-container-row">
          <div className="brand-admin-input-container">
            <div className="brand-admin-input-title">{t("domainName")}:</div>

            <div className="brand-admin-input-area">
              <input
                id="url"
                className="brand-admin-input"
                type="text"
                value={url}
                readOnly={true}
              />
              <img
                src={tick}
                alt="ico"
                className={`brand-admin-input-img ${
                  url !== "" ? "slide-in" : ""
                }`}
              />
            </div>
          </div>

          <div className="brand-admin-input-container">
            <div className="brand-admin-input-title">{t("brandName")}:</div>
            <div className="brand-admin-input-area">
              <input
                id="brandName"
                className="brand-admin-input"
                type="text"
                value={brandName}
                readOnly={true}
              />
              <img
                src={tick}
                alt="ico"
                className={`brand-admin-input-img ${
                  brandName !== "" ? "slide-in" : ""
                }`}
              />
            </div>
          </div>

          {!logoReadOnly ? (
            <div className="brand-admin-input-container">
              <div className="brand-admin-input-title image-dropzone-container">
                {t("brandLogo")}:
              </div>
              <ImageDropzone
                className="brand-admin-input"
                onImageUpload={handleImageUpload}
              />
            </div>
          ) : (
            <div className="brand-admin-input-container"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandInfo;
