import React, { useState, useEffect } from "react";
import "../../../../../../assets/css/ChallengeDashboard.css";
import SearchIcon from "../../../../../../assets/media/SearchIcon.svg";
import FilterButton from "../../../../../button/filterButton";
import DatePickerButton from "../../../../../button/datePickerButton";
import TotalCards from "../../../../../cards/TotalCards";
import GridListSwitchButton from "../../../../../button/gridListSwitchButton";
import DynamicTable from "../../../../../tables/DynamicTable";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  getCookie,
  getUserTypeFromToken,
  isMyTokenExpired,
} from "../../../../auth/tokenUtils";
import {
  apiUrl,
  metabaseBrandAdminCampaignDashboard,
  metabaseBrandAdminCampaignDashboardList
} from "../../../../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../popup/loader";
import CreateCampaignButton from "../../../../../button/createCampaignButton";
import CampaignCard from "../../../../../cards/campaignCard";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { metabaseUrl } from '../../../../../../config';

const CampaignDashboard = () => {
  const [viewType, setViewType] = useState("GRID");
  const { t } = useTranslation("challengeDashboard");
  const token = getCookie("access_token");
  const [campaigns, setCampaigns] = useState();
  const [campaignFilter, setCampaignFilter] = useState(" ");
  const [campaignsList, setCampaignsList] = useState();
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [loaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();

  const [headerValues, setHeaderValues] = useState(false);

  useEffect(() => {
    localStorage.removeItem("checkboxStates");

    if (!token) {
      // Handle the case where the token is empty or undefined
      console.log("JWT token is empty or undefined");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      toast.error(t("error401"));
      navigate("/sign-in-brand");
    }
    fetchTopData();
    fetchListData();
  }, []);
  const getInitials = (str) => {
    const words = str.split(/(?=[A-Z])/);
    return words.map((word) => word[0]).join("");
  };

  const fetchTopData = async () => {
    setLoaderState(true);

    const url = `${metabaseUrl}/api/public/card/${metabaseBrandAdminCampaignDashboard}/query/json?parameters=[{"type":"category","target":["variable",["template-tag","uuid"]],"value":"${localStorage.getItem(
      "UUID"
    )}"},{"type":"category","target":["variable",["template-tag","start_date"]],"value":"${
      filterStartDate ? filterStartDate : "2020-01-01"
    }"},{"type":"category","target":["variable",["template-tag","end_date"]],"value":"${
      filterEndDate ? filterEndDate : "2100-01-01T23:59:59"
    }"},{"type":"category","target":["variable",["template-tag","campaign_type"]],"value":"${campaignFilter}"}]`;

    try {
      const response = await axios.get(url);
      const dataSets = response.data.map((item) => ({
        headerTitle: t("total"),
        title: t(item.key),
        subtitle: t(getInitials(item.key).toUpperCase()),
        data: item.key === "successRate" ? "%" + (item.value !== null ? item.value : 0) : item.value,
      }));
      setHeaderValues(dataSets);
      setLoaderState(false);

      // Do something with the fetched data here
    } catch (error) {
      setLoaderState(false);

      console.error("Error fetching data:", error);
    }
  };

  const fetchSelectedChallenge = async (campaignId) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        navigate("/sign-in-brand");
      } else {
        navigate("/sign-in-realm");
      }

      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        navigate("/sign-in-brand");
      } else {
        navigate("/sign-in-realm");
      }
      return;
    }

    let endpoint = apiUrl + `/api/v1/brand-admin/brand/campaign/${campaignId}`;

    try {
      setLoaderState(true);
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      setLoaderState(false);
      navigate("/brand/srm/campaign/create", {
        state: { returnedCampaign: data },
      });

      // Do something with the data
    } catch (error) {
      setLoaderState(false);
      toast.error(error.message);
      console.error("Error fetching data:", error);
    }
  };

  // Tarih dizesini "dd.mm.yyyy" formatına dönüştürme fonksiyonu
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split(".").map(Number);
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };

  const fetchListData = async () => {
    setLoaderState(true);

    const url = `${metabaseUrl}/api/public/card/${metabaseBrandAdminCampaignDashboardList}/query/json?parameters=[{"type":"category","target":["variable",["template-tag","uuid"]],"value":"${localStorage.getItem(
      "UUID"
    )}"},{"type":"category","target":["variable",["template-tag","start_date"]],"value":"${
      filterStartDate ? filterStartDate : "1970-01-01"
    }"},{"type":"category","target":["variable",["template-tag","end_date"]],"value":"${
      filterEndDate ? filterEndDate : "2970-01-01T23:59:59"
    }"},{"type":"category","target":["variable",["template-tag","campaign_type"]],"value":"${campaignFilter}"}]`;

    try {
      const response = await axios.get(url, {
        headers: {
          Cookie: "metabase.DEVICE=0dcb6af1-6821-421d-9c99-9aec82771229",
        },
      });
      setCampaigns(response.data);
      const transformedData = response.data.map((item) => {
        const campaign = item;

        const controlButton = () => {
          if (
            !getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "DRAFTED"
          ) {
            return null;
          } else if (
            !getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "RELEASED"
          ) {
            return (
              <button
                className="dynamic-table-button dashboard-table-button"
                onClick={null}
              >
                {t("showDetails")}
              </button>
            );
          } else if (
            getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "DRAFTED"
          ) {
            return (
              <button
                className="dynamic-table-button dashboard-table-button"
                onClick={() => {
                  fetchSelectedChallenge(campaign.id);
                }}
              >
                {t("edit")}
              </button>
            );
          } else if (
            getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "RELEASED"
          ) {
            return (
              <button
                className="dynamic-table-button dashboard-table-button"
                onClick={null}
              >
                {t("showDetails")}
              </button>
            );
          }
        };


        return {
          control: controlButton(),
          id: campaign.id,
          campaignImg: (
            <img
              src={campaign.logo ? campaign.logo : placeholderImg}
              alt={`Logo Bulunamadı`}
            />
          ),
          campaignType: t(campaign.campaignType),
          campaignName: campaign.name,
          sotierCount: campaign.sotiercount,
          paymentAmount: campaign.payment_amount,
          refundAmount: campaign.refund_amount,
          creationDate: campaign.creationDate,
          status: t(campaign.status),
        };
      });
      setCampaignsList(transformedData);
      setLoaderState(false);

      // Do something with the fetched data here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoaderState(false);
    }
  };

  // Call the function to fetch the data

  const checkboxData = [
    { title: t("COIN_FOLDING"), campaignType: "COIN_FOLDING" },
    { title: t("DISCOUNT_PERCENTAGE"), campaignType: "DISCOUNT_PERCENTAGE" },
    { title: t("SPEND_AND_EARN"), campaignType: "SPEND_AND_EARN" },
    { title: t("COUPON_PERCENTAGE"), campaignType: "COUPON_PERCENTAGE" },
    { title: t("BUY_X_PAY_Y"), campaignType: "BUY_X_PAY_Y" },
    { title: t("BUY_MORE_PAY_LESS"), campaignType: "BUY_MORE_PAY_LESS" },
    { title: t("FREE_SHIPMENT"), campaignType: "FREE_SHIPMENT" },
  ];

  function formatDateStringLocal(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
      return formattedDate;
    } else {
      return "";
    }
  }

  const campaignColumns = [
    { title: t("control"), field: "control", className: "" },
    { title: t("id"), field: "id", className: "" },
    { title: t("campaignName"), field: "campaignName", className: "" },
    { title: t("campaignType"), field: "campaignType", className: "" },
    { title: t("campaignImage"), field: "campaignImg", className: "" },
    { title: t("sotierCount"), field: "sotierCount", className: "" },
    { title: t("paymentAmount"), field: "paymentAmount", className: "" },
    { title: t("refundAmount"), field: "refundAmount", className: "" },
    { title: t("creationDate"), field: "creationDate", className: "" },
    { title: t("Status"), field: "status", className: "" },
  ];

  const handleViewSelect = (viewType) => {
    if (viewType === "LIST") {
      setViewType("LIST");
      // Burada list görünümüne geçilecek işlemleri yapabilirsiniz.
    } else if (viewType === "GRID") {
      setViewType("GRID");
      // Burada grid görünümüne geçilecek işlemleri yapabilirsiniz.
    }
  };

  const handleFilterChange = (e) => {
    if (!e || e.length === 0 || e.length === 4) {
      setCampaignFilter(" ");
    } else {
      setCampaignFilter(e.map((item) => item.campaignType).join(","));
    }
  };

  useEffect(() => {
    fetchTopData();
    fetchListData();
  }, [campaignFilter, filterEndDate]);

  const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {
    if (selectedEndDate) {
      const originalStartDate = new Date(selectedStartDate);
      const originalEndDate = new Date(selectedEndDate);

      originalStartDate.setHours(0, 0, 0);
      originalEndDate.setHours(23, 59, 59);

      const formattedStartDate = `${originalStartDate.getFullYear()}-${(
        originalStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${originalStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${originalStartDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${originalStartDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${originalStartDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const formattedEndDate = `${originalEndDate.getFullYear()}-${(
        originalEndDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${originalEndDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${originalEndDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${originalEndDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${originalEndDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      setFilterStartDate(formattedStartDate);
      setFilterEndDate(formattedEndDate);
    } else {
      setFilterStartDate();
      setFilterEndDate();
    }
  };

  const locationArray = [[t("campaigns"), "/brand/srm/campaign"]];
  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar dividerDisabled locationArray={locationArray} />
      <div className="dashboard-top-divider campaign-dashboard-divider" />
      <div className="dashboard-controls-container">
        <FilterButton
          checkboxData={checkboxData}
          onCheckboxSelection={handleFilterChange}
          eventType={"campaign"}
        />
        <DatePickerButton onDateChange={handleFilterDatesChanged} />
      </div>

      <div className="dashboard-header">
        <div className="dashboard-header-cards-container">
          {headerValues &&
            headerValues
              .slice(0, 4)
              .map((data, index) => <TotalCards key={index} {...data} />)}
        </div>

        <CreateCampaignButton />
      </div>
      <div className="dashboard-header">
        <div className="dashboard-header-cards-container">
          {headerValues &&
            headerValues
              .slice(4, 8)
              .map((data, index) => <TotalCards key={index} {...data} />)}
        </div>
        <div className="hidden">
          <CreateCampaignButton />
        </div>
      </div>

      <div className="dashboard-divider" />
      <div className="dashboard-challenges-wrapper">
        <div className="dashboard-challenges-controls-container">
          <GridListSwitchButton onSelectView={handleViewSelect} />
          <div className="dashboard-challenges-search-container hidden">
            <img
              className="dashboard-challenges-search-icon"
              src={SearchIcon}
              alt="search"
            />
            <input
              className="dashboard-challenges-search"
              type="text"
              placeholder="search"
            />
          </div>
        </div>
      </div>
      <div className="dashboard-challenges-container ">
        {viewType === "GRID" &&
          (Array.isArray(campaigns) ? (
            <div className="dashboard-challenges-grid-container">
              {campaigns.map((data, index) => (
                <CampaignCard key={index} campaignData={data} />
              ))}
            </div>
          ) : (
            <div>
              <p>Henüz kampanya yok.</p>
            </div>
          ))}
        {viewType === "LIST" && campaignColumns && campaignsList && (
          <div>
            <DynamicTable
              columns={campaignColumns}
              data={campaignsList}
              tableName={t("campaignTable")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default CampaignDashboard;
