import { RoutesConfig } from "../../RoutesConfig";

function containsPath(routes, path) {
  return routes.some((route) =>
      (path === "" && route.index) ||
      route.path === path ||
      (path.startsWith(route.path) && route.routes && containsPath(route.routes, path.slice(route.path.length+1)))
  );
}

export const isValidLink = (path) => {
  return path !== undefined && containsPath(RoutesConfig, path);
};
