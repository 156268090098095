import React, { useEffect, useState } from "react";
import NavigationBar from "../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import "../../../../assets/css/Faq.css";
import FaqItem from "../../realm_admin/faq/FaqItem";
import CallAPI from "../../../api/callApi";
import { apiUrl } from "../../../../config";
import { LoyaltyLevelInput } from "../profile/components/LoyaltyLevelInput";

const BrandFaq = () => {
  const { t } = useTranslation("faq");
  const locationArray = [[t("FAQ"), "/brand/faq"]];
  const [FAQS, setFAQS] = useState([]);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [transitionState, setTransitionState] = useState("");

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setTransitionState("collapsing");
      setTimeout(() => {
        setExpandedIndex(null);
        setTransitionState("");
      }, 300); // Match the animation duration
    } else {
      setExpandedIndex(index);
      setTransitionState("expanding");
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/faq",
    });
    console.log(response);
    setFAQS(response?.data?.responseData || []);
  };
  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />
      <div className="faq-container">
        <div className="faq-header-container">
          <div className="faq-header-title">
            {t("frequentlyAskedQuestions")}
          </div>
        </div>
        <div className="faq-section">
          <div className="faq-header">
            <h1 className="faq-header-part faq-section__title">{t("FAQ")}</h1>
          </div>

          <div className="faq-list">
            {FAQS.map((faq) => (
              <FaqItem key={faq.id} faq={faq} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandFaq;
