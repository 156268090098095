import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AnalysisTotalCard from "../cards/AnalysisTotalCard";
import Loader from "../popup/loader";
import '../../assets/css/AnalysisTotalCard.css';
import {Formatter} from "./Formatter";
import {AnalysisApi} from "./AnalysisApi";

const GeneralKPIs = ({
    locale,
    brandUuid,
    dateRange,
                     }) => {
    const {t, i18n} = useTranslation("analysis");
    const [showLoader, setShowLoader] = useState(true);
    const [kpis, setKpis] = useState({});

    const formatter = new Formatter(locale);

    useEffect(() => {
        setShowLoader(true);
        AnalysisApi.generalKPIs(brandUuid, dateRange)
            .then(setKpis)
            .finally(() => setShowLoader(false));
    }, [brandUuid, dateRange]);

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <div className='analysis-total-card-wrapper'>
                    <AnalysisTotalCard
                        cardTitle={t("total_sotier_count_title")}
                        mainTitle={t("total_sotier_count")}
                        mainValue={formatter.numberFormat(kpis.total_sotier_count)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("total_soty_coin_net_title")}
                        mainTitle={t("total_soty_coin_net")}
                        mainValue={formatter.numberFormat(kpis.total_soty_coin_net)}
                        detailsTopTitle={t("total_soty_coin_earned")}
                        detailsTopValue={formatter.numberFormat(kpis.total_soty_coin_earned)}
                        detailsBottomTitle={t("total_soty_coin_spent")}
                        detailsBottomValue={formatter.numberFormat(kpis.total_soty_coin_spent)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("")}
                        mainTitle={t("total_order_net_amount")}
                        mainValue={formatter.currencyFormat(kpis.total_order_net_amount)}
                        detailsTitle={t("total_order_net_amount_details")}
                        detailsTopTitle={t("total_order_amount")}
                        detailsTopValue={formatter.currencyFormat(kpis.total_order_amount)}
                        detailsBottomTitle={t("total_order_refund_amount")}
                        detailsBottomValue={formatter.currencyFormat(kpis.total_order_refund_amount)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("total_order_product_net_count_title")}
                        mainTitle={t("total_order_product_net_count")}
                        mainValue={formatter.numberFormat(kpis.total_order_product_net_count)}
                        detailsTitle={t("total_order_product_net_count_details")}
                        detailsTopTitle={t("total_order_product_count")}
                        detailsTopValue={formatter.numberFormat(kpis.total_order_product_count)}
                        detailsBottomTitle={t("total_order_product_refund_count")}
                        detailsBottomValue={formatter.numberFormat(kpis.total_order_product_refund_count)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("total_order_net_count_title")}
                        mainTitle={t("total_order_net_count")}
                        mainValue={formatter.numberFormat(kpis.total_order_net_count)}
                        detailsTitle={t("total_order_net_count_details")}
                        detailsTopTitle={t("total_order_count")}
                        detailsTopValue={formatter.numberFormat(kpis.total_order_count)}
                        detailsBottomTitle={t("total_order_refund_count")}
                        detailsBottomValue={formatter.numberFormat(kpis.total_order_refund_count)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("average_basket_size_title")}
                        mainTitle={t("average_basket_size")}
                        mainValue={formatter.numberFormat(kpis.average_basket_size)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("average_basket_amount_title")}
                        mainTitle={t("average_basket_amount")}
                        mainSubtitle={t("average_basket_amount_subtitle")}
                        mainValue={formatter.currencyFormat(kpis.average_basket_amount)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("challenge_success_rate_title")}
                        mainTitle={t("challenge_success_rate")}
                        mainValue={formatter.percentageFormat(kpis.challenge_success_rate)}
                    />
                    <AnalysisTotalCard
                        cardTitle={t("offer_success_rate_title")}
                        mainTitle={t("offer_success_rate")}
                        mainValue={formatter.percentageFormat(kpis.offer_success_rate)}
                    />
                </div>
            )}
        </>
    )
}
export default GeneralKPIs;